<template>
  <div class="menu-wrapper">
    <v-card height="calc(100vh - 48px)" elevation="1">
      <v-navigation-drawer
        permanent
        expand-on-hover
      >
        <v-list>
          <v-list-item link class="px-2 d-flex">
            <v-list-item-icon>
              <v-icon x-large color="primary">mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{userName}}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1 mb-0">{{ getRuRoleName }}</v-list-item-subtitle> 
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          nav
          dense
        >
          <v-list-item link :to="'/'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-image-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Мониторинг</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="'/event-log'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Журнал событий</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="'/charts'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-chart-areaspline </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Архив</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="'/logs'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-table-large </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Логи</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="'/sensor'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-select-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Датчики</v-list-item-title>
          </v-list-item>

          <v-list-item link :to="'/admin/rodos'" v-if="userRole == 'admin'">
            <v-list-item-icon>
              <v-icon color="primary">mdi-tune</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Настройки</v-list-item-title>
          </v-list-item>

          <v-list-item link :href="trafficData" target="_blank" v-if="trafficData !== ''">
            <v-list-item-icon>
              <v-icon color="primary">mdi-link</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-item-title">Traffic data</v-list-item-title>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list
            nav
            dense
          >
            <v-list-item 
            >
              <v-list-item-icon>
                <v-icon :color="(glnetStatus === 'true' || glnetStatus === 'True') ? 'success' : 'error'">mdi-circle-slice-8</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="menu-item-title">Связь с GLnet</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list
            nav
            dense
          >
            <v-list-item 
              @click.stop="dialog = true"
            >
              <v-list-item-icon>
                <v-icon color="primary">mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="menu-item-title">Выйти</v-list-item-title>
              <v-dialog
                v-model="dialog"
                max-width="500"
              >
                <v-card>
                  <v-card-title class="justify-center py-10">
                    Вы уверены что хотите выйти?
                  </v-card-title>

                  <v-card-text>
                    <v-alert
                      border="left"
                      class="white--text"
                      color="error"
                      elevation="0"
                      v-if="loadingCreateReportEventLog"
                    >
                      Сейчас создается отчет из журнала событий. Если вы выйдете, то создание отчета прекратится
                    </v-alert>
                    <v-alert
                      border="left"
                      class="white--text"
                      color="error"
                      elevation="0"
                      v-if="loadingCreateReportArchive"
                    >
                      Сейчас создается отчет из архива. Если вы выйдете, то создание отчета прекратится
                    </v-alert>
                    <v-alert
                      border="left"
                      class="white--text"
                      color="error"
                      elevation="0"
                      v-if="loadingCreateReportLogs"
                    >
                      Сейчас создается отчет логов. Если вы выйдете, то создание отчета прекратится
                    </v-alert>
                  </v-card-text>

                  <v-card-actions class="d-flex justify-space-around pb-10">

                    <v-btn
                      elevation="0"
                      width="160px" 
                      @click="exit" 
                      color="accent"
                      outlined
                    >
                      Да
                    </v-btn>

                    <v-btn
                      elevation="0"
                      width="160px" 
                      @click="dialog = false" 
                      color="primary"
                      outlined
                    >
                      Отмена
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-card>
  </div>  
</template>

<script>
import appStore from "@/store/appStore"

const GLNET_CONFIG_TRAFFIC_DATA = require('@/graphQL/glnet_config_traffic_data.gql')
const GLNET_STATUS = require('@/graphQL/glnet_status.gql')

export default {
  data: () => ({
    logoutLoading: false,
    dialog: false
  }),

  apollo: {
    trafficData: {
      query: GLNET_CONFIG_TRAFFIC_DATA,
      update (data) {
        return data.settings[0].value
      },
      pollInterval: 2000, // ZZZZZ
      // pollInterval: 0,
    },

    glnetStatus: {
      query: GLNET_STATUS,
      pollInterval: 10000,
      update (data) {
        return data.settings[0].value
      }
    }
  },

  computed: {
    userName () {
      return appStore.getters.VALUE('userName')
    },
    userRole () {
      return appStore.getters.VALUE('userRole')
    },
    getRuRoleName() {
      switch (this.userRole) {
        case 'admin': return 'Администратор'
        case 'operator': return 'Оператор'
        case 'engineer': return 'Инженер'
        default: return ''
      }
    },
    loadingCreateReportEventLog () {
      return appStore.getters.VALUE('loadingCreateReportEventLog')
    },
    loadingCreateReportArchive () {
      return appStore.getters.VALUE('loadingCreateReportArchive')
    },
    loadingCreateReportLogs () {
      return appStore.getters.VALUE('loadingCreateReportLogs')
    }
  },

  methods: {
    async exit () {
      if (this.loadingCreateReportEventLog) {
        appStore.dispatch('SET_VALUE', {key: 'breakReportEventLog', value: true})
      }
      if (this.loadingCreateReportArchive) {
        appStore.dispatch('SET_VALUE', {key: 'breakReportArchive', value: true})
      }
      if (this.loadingCreateReportLogs) {
        appStore.dispatch('SET_VALUE', {key: 'breakReportLogs', value: true})
      }
      this.logoutLoading = true
      try {
        await this.logoutMix()
      } catch (error) {
        console.error('fail to logout', error)
      } finally {
        this.logoutLoading = false
      }
    }
  },
}
</script>

<style lang="scss">
.menu-wrapper {
  height: 100%;
  position: fixed;
  z-index: 5;
}
.status__text{
  font-size: 14px;
}
.btn-close{
  height: 10px;
}
.menu-item-title {
  font-size: 12pt !important;
  font-weight: normal !important;
}
</style>
