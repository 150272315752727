import appStore from '../store/appStore'

export default {
  methods: {
    getEventColorMix (event) { // категории
      if (!event || !event.glnet_category) {
        return ''
      }
      switch (event.glnet_category.code) {
        case 0: // Обычное
          return '#e2f5f8'

        case 1: // Внимание
          return '#FFE64D'

        case 2: // Тревога
          return '#FF6161'

        case 3: // Нет связи
          return '#EAEAEA'
      
        default:
          return ''
      }
    },

    getColorByStatusMix (status) {
      if (!status) {
        return '#EAEAEA'
      }
      switch (status) {
        case 1: // нет связи
          return '#EAEAEA'

        case 2: // Тревога
          return '#FF6161'

        case 3: // Внимание
          return '#FFE64D'

        case 4: // Норма
          return '#CAFFCA'

        case 5: // Маcкирован
          return '#80D8FF'
      
        default:
          return '#EAEAEA'
      }
    },

    createCsvMix (resps, filename, reportType) {
      let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF,";

      resps.forEach((resp, respIndex) => {
        if (respIndex !== 0) { // если не начало перейти на новую строку
          csvContent += '\n'
        }
        if (respIndex === 0) { // если начало, то доавить ключи
          csvContent += Object.keys(resp[0]).join(";") + '\n'
        }
        csvContent += [ // добавить строку
          ...resp.map(item => Object.values(item).join(";"))
        ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
      })
  
      // const data = encodeURI(csvContent);
      const data = csvContent;
      const link = document.createElement("a")
      link.setAttribute("href", data)
      link.setAttribute("download", `${filename}.csv`)
      link.click()
      this.$notify({
        group: 'foo',
        title: 'Отчет создан',
        text: 'Его можно найти в папке загрузок',
        type: 'suc' // error warn success common
      })
      appStore.dispatch('SET_VALUE', {key: reportType, value: false})
      console.timeEnd('generate report')
    },

    getDescription(desr){
      if (desr === null) {
        return '-'
      }
      let Arr = desr.split('`')
      Arr[1] = this.getWord(Arr[1])
      Arr[3] = this.getWord(Arr[3])
      return Arr.join('')
     
    },
    getWord(word){
      switch(word) {
        case "NO_CONNECTION": 
          return "НЕТ СВЯЗИ"
        case "ALARM": 
          return "ТРЕВОГА"
        case "ATTENTION": 
          return "ВНИМАНИЕ"
        case "NORM": 
          return "НОРМА"
        case "MARK": 
          return "МАСКИРОВАН"        
      }
    },
  }
}