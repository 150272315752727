<template>
    <div>
      <AdminMenu @check-all="sendRequests($event)" />
      <AdminTableUser v-if="searchRoute == '/admin/user'" />
      <transition name="fade" mode="out-in">
        <AdminTableRodos
          @save-item="saveItem($event)"
          @refetch-rodos="refetchRodos"
          :settings="settings"
          v-if="searchRoute == '/admin/rodos'"
        />
      </transition>
      <transition name="fade" mode="out-in">
      <AdminTableSensor v-if="searchRoute == '/admin/sensor'" />
      </transition>
      <transition name="fade" mode="out-in">
      <AdminTableCanal v-if="searchRoute == '/admin/canals'" />
      </transition>
      <transition name="fade" mode="out-in">
      <AdminSceneVue v-if="searchRoute == '/admin/scenes'" />
      </transition>
      <transition name="fade" mode="out-in">
      <AdminConfig v-if="searchRoute == '/admin/config'" />
      </transition>
    </div>
</template>

<script>
const GLNET_RODOS_TABLE = require("/src/graphQL/rodos_table.gql");
const GLNET_RODOS_ITEM_MUTATION = require("@/graphQL/update_rodos_mutation.gql");

import AdminMenu from "./AdminMenuVue.vue";
import AdminTableUser from "./Tables/AdminTableUserVue.vue";
import AdminTableRodos from "./Tables/AdminTableRodosVue.vue";
import AdminTableSensor from "./Tables/AdminTableSensorVue.vue";
import AdminTableCanal from "./Tables/AdminTableCanalVue.vue";
import AdminSceneVue from "./AdminSceneVue.vue";
import AdminConfig from "./Tables/AdminConfigVue.vue";
import axios from "axios";

export default {
  components: {
    AdminMenu,
    AdminTableUser,
    AdminTableRodos,
    AdminTableSensor,
    AdminTableCanal,
    AdminConfig,
    AdminSceneVue
  },
  data: () => ({
    channels: []
  }),
  apollo: {
    settings: {
      query: GLNET_RODOS_TABLE
    }
  },
  computed: {
    searchRoute() {
      return this.$route.path;
    }
  },
  methods: {
    refetchRodos () {
      setTimeout(() => {
        this.$apollo.queries.settings.refetch()
      }, 500)
    },

    sendRequests() {
      const urls = this.settings.filter(it => it.is_active).map(it => it.value);
      urls.forEach(url => {
        axios
          .get(url)
          .then(() => {
            this.$notify({
              group: "foo",
              title: "Успешно",
              text: "Успешно проверено",
              type: "suc"
            });
          })
          .catch(err => {
            
            this.$notify({
              group: "foo",
              title: "Внутренняя ошибка",
              text: "Не удалось отправить запрос: " + JSON.stringify(err),
              type: "err"
            });
          });
      });
    },
    async saveItem({ id, is_active, key, value, description }) {
      this.$apollo
        .mutate({
          mutation: GLNET_RODOS_ITEM_MUTATION,
          variables: {
            id,
            key,
            is_active,
            value,
            description
          }
        })
        .then(() => {
          let savedItem = this.settings.find(it => it.id === id);
          savedItem.value = value;
          savedItem.is_active = is_active;
          savedItem.description = description;
          this.$notify({
            group: "foo",
            text: "Успешно сохранено",
            type: "suc"
          });
        })
        .catch(err => {
          
          this.$notify({
            group: "foo",
            text: "Ошибка сохранения: " + JSON.stringify(err),
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
