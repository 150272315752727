<template>
  <div style="height: 100%" class="pl-5">
    <!-- сцены -->
    <v-card
      tile
      max-width="250"
      elevation="0"
      class="scenes px-2 overflow-y-auto"
      :class="{ 'edit-scenes': mode === 'edit' }"
    >
      <v-list v-if="loadingScene">
        <v-list-item-group>
          <v-skeleton-loader
            type="image"
            max-height="40"
            v-for="i in 4"
            :key="'loading_' + i"
            link
            class="mb-5"
            color="primary"
          >
            <!-- <v-list-item
            v-for="i in 4"
            :key="'loading_' + i"
            link
            class="scene-list-item"
            color="primary"/> -->
          </v-skeleton-loader>
        </v-list-item-group>
      </v-list>

      <v-list v-else>
        <!-- eslint-disable-next-line -->
        <div
          class="scene-list-item"
          @click="setScene(-1)"
          :class="{ 'scene-list-item-active': activeSceneId == -1 }"
        >
          <v-icon
            class="scene-list-item-status-icon"
            medium
            :color="selectColor(mainScene.status_id)"
          >
            mdi-circle-slice-8
          </v-icon>
          <p class="scene-list-item-title">{{ mainScene.name }}</p>
        </div>
        <div
          class="scene-list-wrap"
          id="sortable"
          v-dragula="sortScenesArr"
          bag="my-bag"
        >
          <div
            class="scene-list-item"
            :class="{ 'scene-list-item-active': activeSceneId == item.id }"
            @click="setScene(item.id)"
            v-for="item in sortScenesArr"
            :key="item.id"
          >
            <v-icon
              class="scene-list-item-status-icon"
              medium
              :color="selectColor(item.status_id)"
            >
              mdi-circle-slice-8
            </v-icon>
            <p class="scene-list-item-title">{{ item.name }}</p>
            <v-icon class="scene-list-item-drag-icon" medium color="black">
              mdi-drag-vertical
            </v-icon>
          </div>
        </div>
      </v-list>
    </v-card>

    <!-- сецена, изображение, точки -->
    <div class="product" :class="{ 'edit-product': mode === 'edit' }">
      <!-- загрузка -->
      <v-fade-transition>
        <div class="loading-scene" v-if="loadingScene">
          <v-progress-circular
            size="40"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-fade-transition>
      <img class="image-full" id="image-full" :src="image" />
      <!-- изображение и точки -->
      <div
        class="image-wrapper"
        id="scene-image-wrap"
        :class="{ 'edit-image': mode === 'edit' }"
      >
        <img
          class="image"
          @load="loadImageCallback()"
          id="scene-image"
          :src="image"
        />
        <div v-if="!loadingScene">
          <template v-for="(hotspot, hotspotIndex) in hotspots">
            <hotspot-scene
              v-if="activeSceneId == -1"
              :mode="mode"
              :hotspot="hotspot"
              :sceneWidth="sceneWidth"
              :sceneHeight="sceneHeight"
              :key="hotspot.id + '_' + hotspotIndex"
            ></hotspot-scene>
            <hotspot-sensor
              v-else
              :mode="mode"
              :sceneId="activeSceneId"
              :hotspot="hotspot"
              :sceneWidth="sceneWidth"
              :sceneHeight="sceneHeight"
              :key="hotspot.inputData.scenesensorId + '_' + hotspotIndex"
              @refreshScenes="refetch"
            ></hotspot-sensor>
          </template>
        </div>
      </div>
      <div class="action-panel" v-if="mode === 'edit'">
        <div>
          <delete-scene
            @refreshScenes="refetch"
            :sceneId="activeSceneId"
            v-if="activeSceneId !== -1"
          ></delete-scene>
          <create-scene
            @refreshScenes="refetch"
            v-if="activeSceneId == -1"
            :mainScene="mainScene"
          ></create-scene>
          <edit-main-scene
            :mainScene="mainScene"
            @refreshScenes="refetch"
            v-if="activeSceneId == -1"
          ></edit-main-scene>
          <add-sensor
            v-if="activeSceneId !== -1"
            :sceneId="activeSceneId"
            @refreshScenes="refetch"
          ></add-sensor>
          <edit-scene
            :scene="activeScene"
            @refreshScenes="refetch"
            v-if="activeSceneId !== -1"
          >
          </edit-scene>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateScene from "./CreateScene.vue";
import DeleteScene from "./DeleteScene.vue";
import EditMainScene from "./EditMainScene.vue";
import EditScene from "./EditScene.vue";
import AddSensor from "./AddSensor.vue";
import HotspotScene from "./HotspotScene.vue";
import HotspotSensor from "./HotspotSensor.vue";
// import { domainUrl } from "../../config";
const GLNET_SCENE_MAIN = require("@/graphQL/scene_main.gql");
const GLNET_SCENE = require("@/graphQL/scene.gql");
export default {
  components: {
    CreateScene,
    AddSensor,
    DeleteScene,
    HotspotScene,
    EditMainScene,
    EditScene,
    HotspotSensor
  },
  name: "scene",
  props: {
    mode: {
      type: String,
      default: "view"
    }
  },
  data() {
    return {
      loadingScenesList: false,
      selectedSensor: null,
      mainScene: {},
      scenes: [],
      sceneDataArr: [],
      sortScenesArr: [],
      activeSceneId: -1,
      activeSceneItem: 0,
      activeScene: {},
      loadingScene: false,
      hotspots: [],
      image: null,
      polling: 2000,
      skipMainScene: false,
      skipScene: true,
      // редактирование
      newSceneName: "",
      sceneWidth: 0,
      sceneHeight: 0,
      sceneCol: null,
      sceneBag: [],
      intervalMain: null,
      interval: null
    };
  },

  watch: {
    sortScenesArr(newVal) {
      let indexArr = newVal.map(obj => obj.id);
      localStorage["scenesQueue"] = JSON.stringify(indexArr);
    }
  },

  async mounted() {
    this.refetch();
  },
  beforeDestroy() {
    this.stopPollingMainSceneData();
    this.stopPollingSceneData();
  },

  methods: {
    startPollingMainSceneData() {
      let self = this;
      this.intervalMain = setInterval(() => {
        self.$apollo.query({
          query: GLNET_SCENE_MAIN,
          fetchPolicy: "no-cache"
        }).then(resp => {
          self.mainScene = self.parceScenes(resp.data.glnet_object[0])
          self.setImage();
            setTimeout(() => {
              self.sceneSetHotspots();
            }, 1000);
        });
      }, self.polling);
    },
    async querySceneData() {
      this.hotspots = []
      this.$apollo
        .query({
          query: GLNET_SCENE,
          fetchPolicy: "no-cache",
          variables: {
            id: this.activeSceneId
          }
        })
        .then(resp => {
          this.activeScene = resp.data.glnet_scene[0];
          this.setImage();
          setTimeout(() => {
            this.sceneSetHotspots();
          }, 200);
        });
    },
    startPollingSceneData() {
      let self = this;
      this.interval = setInterval(() => {
        self.$apollo
          .query({
            query: GLNET_SCENE,
            fetchPolicy: "no-cache",
            variables: {
              id: self.activeSceneId
            }
          })
          .then(resp => {
            self.activeScene = resp.data.glnet_scene[0];
            self.sceneSetHotspots();
          });
      }, self.polling);
    },
    stopPollingMainSceneData() {
      if (this.intervalMain) {
        clearInterval(this.intervalMain);
      }
    },
    stopPollingSceneData() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    sortScenes() {
      let indexArrJSON = localStorage.scenesQueue;
      if (
        indexArrJSON !== null &&
        indexArrJSON !== "[]" &&
        indexArrJSON !== undefined
      ) {
        this.sortScenesArr = [];
        let indexArr = JSON.parse(indexArrJSON);
        let set = new Set(indexArr); // убираем дубликаты id
        indexArr = [...set];
        
        // console.log('indexArr', indexArr)
        let sceneDataArrCopy = [...this.sceneDataArr];
        for (let index of indexArr) {
          // console.log('index', index)
          for (let sceneDataItemIndex in this.sceneDataArr) {
            // console.log('sceneDataItemIndex', sceneDataItemIndex)
            if (index == this.sceneDataArr[sceneDataItemIndex].id) {
              this.sortScenesArr.push(this.sceneDataArr[sceneDataItemIndex]);
              sceneDataArrCopy[sceneDataItemIndex] = null;
            }
          }
        }
        
        for (let i of sceneDataArrCopy) {
          if (i) {
            this.sortScenesArr.push(i);
          }
        }
      } else {
        this.sortScenesArr = [...this.sceneDataArr];
      }
    },
    resetStyleImageWrapper() {
      let sceneWrap = document.getElementById("scene-image-wrap");
      sceneWrap.style.width = ``;
      sceneWrap.style.height = ``;
      sceneWrap.style.marginTop = ``;
      sceneWrap.style.marginBottom = ``;
    },
    resizeImageWrapper() {
      let imageFull = document.getElementById("image-full");
      let imgFullWidth = imageFull.width;
      let imgFullHeight = imageFull.height;
      let sceneImage = document.getElementById("scene-image");
      let domRect = sceneImage.getBoundingClientRect();
      let imgWidth = domRect.width;
      let imgHeight = domRect.height;
      let kOrient = imgFullWidth / imgFullHeight - imgWidth / imgHeight;
      let sceneImagePadding = 0;
      let sceneWrap = document.getElementById("scene-image-wrap");
      if (kOrient == 0) {
        this.sceneHeight = imgHeight;
        this.sceneWidth = imgWidth;
      } else if (kOrient < 0) {
        this.sceneHeight = imgHeight;
        sceneImagePadding =
          imgWidth - (imgFullWidth / imgFullHeight) * imgHeight;
        this.sceneWidth = imgWidth - sceneImagePadding;
      } else {
        this.sceneWidth = imgWidth;
        sceneImagePadding =
          imgHeight - (imgFullHeight / imgFullWidth) * imgWidth;
        // 
        if (sceneImagePadding > 0) {
          this.sceneHeight = imgHeight - sceneImagePadding;
          sceneWrap.style.marginTop = `${sceneImagePadding / 2}px`;
          sceneWrap.style.marginBottom = `${sceneImagePadding / 2}px`;
        } else {
          this.sceneHeight = imgHeight + sceneImagePadding;
          sceneWrap.style.marginTop = `${sceneImagePadding / -2}px`;
          sceneWrap.style.marginBottom = `${sceneImagePadding / -2}px`;
        }
      }
      sceneWrap.style.width = `${this.sceneWidth}px`;
      sceneWrap.style.height = `${this.sceneHeight}px`;
    },
    async refetch(sceneId) {
      if (sceneId) {
        this.activeSceneId = sceneId;
      }
      await this.setScene(this.activeSceneId);
    },
    parceScenes(data) {
      this.sceneDataArr = [];
      data.glnet_scenes.forEach(_scene => {
        this.sceneDataArr.push(_scene);
      });
      this.sortScenes();
      let _mainScene = data;
      return _mainScene;
    },

    selectColor(status) {
      return this.getColorByStatusMix(status);
    },

    async loadImageCallback() {
      this.resetStyleImageWrapper();
      await this.resizeImageWrapper();
      await this.sceneSetHotspots();
    },

    async sceneSetHotspots() {
      this.hotspots = [];
      if (this.activeSceneId === -1) {
        for (let scene of this.sceneDataArr) {
          this.hotspots.push({
            coordinates: {
              top: scene.coordinates ? scene.coordinates[0] : 50,
              left: scene.coordinates ? scene.coordinates[1] : 50
            },
            inputData: {
              sceneName: scene.name,
              isMainScene: true,
              image: scene.image
            },
            position: scene.label_position,
            status: scene.status_id,
            id: scene.id
          });
        }
      } else {
        this.activeScene.glnet_scenesensors.forEach(glnet_scenesensor => {
          this.hotspots.push({
            coordinates: {
              top: glnet_scenesensor.coordinates
                ? glnet_scenesensor.coordinates[0]
                : 50,
              left: glnet_scenesensor.coordinates
                ? glnet_scenesensor.coordinates[1]
                : 50
            },
            inputData: {
              sensor: glnet_scenesensor.glnet_sensor,
              sensorChannels: glnet_scenesensor.glnet_sensor.glnet_channels,
              isMainScene: false,
              scenesensorId: glnet_scenesensor.id
            },
            position: "bottom-right",
            status: glnet_scenesensor.glnet_sensor.status_id
          });
          this.hotspots = this.hotspots.filter(item => {
            // убираем маскированные сцены
            return item && item.status!== 5;
          });
        });
      } // включа
    },
    setImage() {
      if (this.activeSceneId === -1) {
        this.image = this.$config.VUE_APP_API_DOMAIN + this.mainScene.image;
      } else {
        this.image = this.$config.VUE_APP_API_DOMAIN + this.activeScene.image;
      }
    },

    async setScene(id) {
      this.loadingScene = true
      this.sceneDataArr = [];
      let _mainSceneEdit = await this.$apollo.query({
        query: GLNET_SCENE_MAIN,
        fetchPolicy: "no-cache"
      });
      this.mainScene = await this.parceScenes(
        _mainSceneEdit.data.glnet_object[0]
      );
      this.activeSceneId = id;
      if (id !== -1) {
        this.stopPollingSceneData();
        await this.querySceneData();
        this.startPollingSceneData();
      } else {
        this.stopPollingSceneData();
        this.stopPollingMainSceneData();
        await this.setImage();
        this.startPollingMainSceneData();
      }
      setTimeout(()=> {
        this.loadingScene = false
      }, 1000)
    }
  }
};
</script>

<style lang="scss">
.scene-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  // display: inline-block;
  // border: black 1px solid;
  margin-right: 15px;
}
// .status::before {
//   display: none;
// }
.scenes {
  width: 250px;
  top: 64px;
  left: 56px;
  height: calc(100vh - 64px);
  position: absolute;
  z-index: 1;
}
.edit-scenes {
  left: calc(250px + 56px);
}
.product {
  position: absolute;
  right: 20px;
  left: calc(250px + 76px);
  top: 74px;
  bottom: 20px;
  text-align: center;
  border-radius: 4px;
}
.edit-product {
  left: calc(250px + 250px + 76px);
}
.action-panel {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  height: 50px;
}
.image-wrapper {
  position: relative;
  display: inline-block;
  width: calc(100vw - 250px - 96px);
  height: calc(100vh - 104px);
  border-radius: 4px;
  // max-height: 100%;
}
.image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.edit-image {
  width: calc(100vw - 250px - 250px - 96px);
  height: calc(100vh - 104px - 60px);
  border-radius: 4px;
}
.scene-list-item {
  padding-left: 0;
  border-radius: 4px !important;
}
.scene-list-item::before {
  border-radius: 4px !important;
}
.image-full {
  position: absolute;
  z-index: -9;
}
.loading-scene {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.384);
  border-radius: 4px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  z-index: 555;
}
.scene-list-item {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding: 13px;
}
.scene-list-item:hover {
  background: rgba(9, 88, 124, 0.05);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
}
.scene-list-item-active {
  background: rgba(9, 88, 124, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.18);
}
.scene-list-item-active:hover {
  background: rgba(9, 88, 124, 0.1);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  // border: 1px solid rgba(255, 255, 255, 0.18);
}
.scene-list-item-status-icon {
  float: left;
  margin-left: 5px;
}
.scene-list-item-title {
  float: left;
  margin-top: 1px;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.scene-list-item-drag-icon {
  float: right;
  margin-right: 5px;
  cursor: move;
}
.gu-transit {
  opacity: 0.2;
}
</style>
