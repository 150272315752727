<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="add-btn edit-main-scene"
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on"
        ><v-icon
        left
      >mdi-pencil
      </v-icon>Редактировать</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card max-width="400">
        <v-toolbar color="primary" dark>Редактирование главной сцены</v-toolbar>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-text-field
                label="Название"
                v-model="newData.name"
                placeholder="Введите название главной сцены"
              ></v-text-field>
              <v-file-input
                accept="image/*"
                prepend-icon="mdi-image"
                label="Выберите изображение"
                v-model="imageSource"
                @change="onImageChange"
              ></v-file-input>
              <v-img v-if="imageSource" :src="imagePreviewURL"></v-img>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="updateMainScene(dialog)">Сохранить</v-btn>
          <v-btn text @click="dialog.value = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
// import { baseUrl } from "../../config";
import axios from "axios";
export default {
  name: "editMainScene",
  props: {
    mainScene: null,
  },
  data() {
    return {
      dialog: null,
      loading: false,
      imageSource: null,
      newData: {
        name: "",
        image: "",
        id: ""
      },
      imagePreviewURL: ""
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.newData.name = this.mainScene.name
        this.newData.image = this.mainScene.image
        this.newData.id = this.mainScene.id
      }
    }
  },
  methods: {
    onImageChange() {
      if (this.imageSource) {
        this.imagePreviewURL = URL.createObjectURL(this.imageSource);
      }
    },
    async uploadImage() {
      let fd = new FormData();
      if (this.mainScene.image && !this.imageSource) {
        return true
      }
      if (this.imageSource) {
        let url = `${this.$config.VUE_APP_API_BASE_URL}api/v1/glnet/scenes/upload/`;
        fd.append("image", this.imageSource);
        return axios({
          method: "post",
          url: url,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$cookies.get("access_token_glnet")}`
          }
        })
          .then(resp => {
            
            this.newData.image = resp.data.url;
            return true;
          })
          .catch(() => {
            
            return false;
          });
      }
    },
    async updateMainScene(dialog) {
      this.loading = true;
      let imageUploaded = await this.uploadImage();
      if (this.mainScene === null || !imageUploaded) {
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Изменения не сохранены",
          type: "err" // error warn success common
        });
        return;
      }
      this.newData.objectId = this.mainScene.id;
      /* eslint-disable no-unused-vars */
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/update_main_scene.gql"),
          variables: {
            name: this.newData.name,
            id: this.newData.objectId,
            image: this.newData.image
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Изменения сохранены",
            type: "suc" // error warn success common
          });
          this.$emit("refreshScenes");
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Изменения не сохранены",
            type: "err" // error warn success common
          });
        });
      this.loading = false;
      dialog.value = false;
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.edit-main-scene {
  float: left;
  margin-right: 20px;
}
</style>
