<template>
  <v-container fluid>
    <v-row class="align-center">
      <div cols="1" class="logo" @click="testSome">
        <img src="@/assets/img/logo.svg" alt="">
      </div>
      <!-- Заголовок страницы -->
      <v-col>
        <p class="mb-0 pagename">{{ pageName }}</p>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-menu
          max-height="calc(100vh - 50px)"
          :close-on-content-click="false"
          :nudge-bottom="'50px'"
          :nudge-right="'-100px'"
          class="no-shadow"
        >
          <!-- кнопка алертов -->
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :color="getColorEventBadge(countEventUnresolved)"
              :content="getContentBadge(countEventUnresolved)"
              offset-x="22"
              offset-y="22"
            >
              <v-btn
                :loading="loadingUnresolvedEvents"
                :disabled="loadingUnresolvedEvents"
                icon
                v-bind="attrs"  
                v-on="on"
                :class="{'glowing-animation': countEventUnresolved > 0}"
              >
                <v-icon color="white">mdi-alert-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>

          <!-- карточки эвентов -->
          <v-container class="no-shadow ml-5">
            <v-row>
              <v-col v-if="countEventUnresolved > 0">

                <!-- диалог принять -->
                <v-dialog
                  v-model="dialogAccept"
                  width="500"
                  :retain-focus="false"
                  :fullscreen="$vuetify.breakpoint.mdAndDown"
                >
                  <v-card>
                    <v-card-title class="justify-center py-10">Подтвердите выполнение операции</v-card-title>
                    <v-card-subtitle class=" d-flex justify-center">Принять в работу событие {{ dialogEvent.id }}</v-card-subtitle>
                    <v-card-actions class="d-flex justify-space-around pb-10">
                      <v-btn outlined elevation="0" width="160px" @click.stop="acceptEvent(dialogEvent)" :loading="loadingAccept" color="primary">Подтверждаю</v-btn>
                      <v-btn outlined width="160px" elevation="0" @click="dialogAccept = false" >Отменить</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- диалог решить -->
                <v-dialog
                  v-model="dialogResolve"
                  width="500"
                  :retain-focus="false"
                  :fullscreen="$vuetify.breakpoint.mdAndDown"
                >
                  <v-card>
                    <v-card-title class="justify-center py-10">Подтвердите выполнение операции</v-card-title>
                    <v-card-subtitle class=" d-flex justify-center">Решить событие {{ dialogEvent.id }}</v-card-subtitle>
                    <v-card-actions class="d-flex justify-space-around pb-10">
                      <v-btn outlined elevation="0" width="160px" @click.stop="resolveEvent(dialogEvent)" :loading="loadingResolve" color="primary">Подтверждаю</v-btn>
                      <v-btn outlined width="160px" elevation="0" @click="dialogResolve = false" >Отменить</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <transition-group name="list" tag="p">
                  <div v-for="(event) in unresolvedEvents" :key="event.id">
                    <!-- карточка эвента -->
                    <v-card
                      class="mb-2 pl-1"
                      width="400px"
                      min-width="399px"
                      max-width="401px"
                    >
                      <div class="left-border" :style="{backgroundColor: getEventColorMix(event)}"></div>
                      <v-card-title class="pb-2">
                        <span class="event-title">{{ (event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.glnet_sensor && event.glnet_channelvalue.glnet_channel.glnet_sensor.name) ? ('Изменение статуса датчика ' + event.glnet_channelvalue.glnet_channel.glnet_sensor.name) : 'GLnet 4' }}<br></span>
                        <span class="event-title" v-if="!!event && !!event.glnet_category && !!event.glnet_category.name">Категория: {{ event.glnet_category.name }}<br></span>
                      </v-card-title>
                      <v-card-text class="py-0">
                        <span class="alarm-popup-card-text" v-if="event && event.created_at">Время: {{ moment(event.created_at).format('DD MMM YYYY HH:mm:ss') }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event && event.glnet_channelvalue.value">Значение: {{ event.glnet_channelvalue.value | cutDigit }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.id">Номер события: {{ event.id }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.glnet_sensor && event.glnet_channelvalue.glnet_channel.glnet_sensor.name">Датчик: {{ event.glnet_channelvalue.glnet_channel.glnet_sensor.name }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.name">Канал: {{ event.glnet_channelvalue.glnet_channel.name }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.glnet_category && event.glnet_category.name">Категория: {{ event.glnet_category.name }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.description">Описание события: {{ getDescription(event.description) }}<br></span>
                        <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.description">Описание датчика: {{ event.glnet_channelvalue.glnet_channel.description }}</span>
                        <!-- <span v-if="!!event && !!event.created_at">Создано: {{ moment(event.created_at).format('DD MMM YYYY HH:mm:ss') }}<br></span>
                        <span v-if="!!event && !!event.description">Опиcание: {{ getDescription(event.description) }}<br></span>
                        <span v-if="!!event && !!event.description">Номер: {{ event.id }}<br></span> -->
                      </v-card-text>
                      <v-card-actions class="pt-4">
                        <div v-if="event && event.glnet_category && (event.glnet_category.code === 1 || event.glnet_category.code === 2 || event.glnet_category.code === 3) && userRole !== 'operator'">
                          <v-btn
                            elevation="0"
                            color="primary darken-1"
                            v-if="event"
                            :loading="loadingAccept && dialogEvent.id === event.id"
                            :disabled="!!event.accepted_at || !!event.user_id"
                            @click="dialogOpen(event, 'accept')"
                            outlined
                            class="ml-2"
                          >
                            Принять
                          </v-btn>
                        
                          <v-btn
                            elevation="0"
                            class="ml-5"
                            color="primary darken-1"
                            v-if="event"
                            :loading="loadingResolve && dialogEvent.id === event.id"
                            @click="dialogOpen(event, 'resolve')"
                            :disabled="!event.accepted_at"
                            outlined
                          >
                            Решено
                          </v-btn>
                        </div>
                        <!-- если не внимание и тревога и не оператор -->
                        <div v-else-if="userRole !== 'operator'">
                          <!-- @click.stop="deleteAlert(event)" -->
                          <v-btn
                            elevation="0"
                            color="primary darken-1"
                            v-if="event"
                            :loading="loadingReadEvent && dialogEvent.id === event.id"
                            @click.stop="readEvent(event)"
                            outlined
                            class="ml-2"
                          >
                            Прочитано
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </div>
                </transition-group>
              </v-col>
              <v-col v-else>
                <p class="display-5 no-events elevation-1">Нет событий, требующих внимания</p>
              </v-col>
            </v-row>
          </v-container>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const GLNET_EVENT_UNRESOLVED = require('@/graphQL/event_unresolved.gql')
// import gql from 'graphql-tag'
import appStore from '@/store/appStore'
import moment from 'moment'

export default {
  data: () => ({
    moment: moment,
    unresolvedEvents: [],
    loadingUnresolvedEvents: false,
    loadingAccept: false,
    loadingResolve: false,
    loadingReadEvent: false,
    dialogAccept: false,
    dialogResolve: false,
    dialogEvent: {},
    countEventUnresolved: 0
  }),

  computed: {
    // countEventUnresolved () {
    //   let myAndAllEvents = this.unresolvedEvents.filter(event => {
    //     return event.accepted_at === null || (event.accepted_at !== null && event.user_id === this.userId)
    //   })
    //   return myAndAllEvents.length
    //   // return this.unresolvedEvents.length
    // },
    userRole () {
      return appStore.getters.VALUE('userRole')
    },
    pageName () {
      return this.$route.name
    },
    userId () {
      return appStore.getters.VALUE('userId')
    },
    isAuthorized () {
      return appStore.getters.VALUE('isAuthorized')
    }
  },

  async mounted () {
    this.$eventHub.$on('refetch-unresolved-events', this.getUnresolvedEvents)
    this.$eventHub.$on('change-loading-accept-event', this.changeAcceptLoading)
    this.$eventHub.$on('change-loading-resolve-event', this.changeResolveLoading)
    this.$eventHub.$on('change-loading-read-event', this.changeReadLoading)
    try {
      await this.getUnresolvedEvents()
    } catch (error) {
      console.error('fail get unresolved events', error)
    }
  },

  beforeDestroy () {
    this.$eventHub.$off('refetch-unresolved-events')
    this.$eventHub.$off('change-loading-accept-event')
    this.$eventHub.$off('change-loading-resolve-event')
    this.$eventHub.$off('change-loading-read-event')
  },

  filters: {
    cutDigit (val) {
      let float = parseFloat(val)
      if (!isNaN(float)) {
        return val.toFixed(3)
      }
      return '-'
    }
  },

  methods: {
    changeReadLoading (e) {
      this.loadingReadEvent = e
    },

    changeAcceptLoading (e) {
      this.loadingAccept = e
      if (!e) { // если загрузка закончилась, то закрыть диалог
        this.dialogAccept = false
      }
    },

    changeResolveLoading (e) {
      this.loadingResolve = e
      if (!e) { // если загрузка закончилась, то закрыть диалог
        this.dialogResolve = false
      }
    },

    dialogOpen(event, name) {
      this.dialogEvent = event;
      if (name === 'accept') {
        this.dialogAccept = true
      } else {
        this.dialogResolve = true
      }
    },
    acceptEvent (event) {
      this.$eventHub.$emit('accept-event', event)
    },

    resolveEvent (event) {
      this.$eventHub.$emit('resolve-event', event)
      // this.deleteEvent(eventId)
    },

    readEvent (event) {
      this.dialogEvent = event
      this.$eventHub.$emit('read-event', event)
    },

    deleteEvent (eventId) {
      let eventIndex = this.unresolvedEvents.findIndex(event => {
        return event.id === eventId
      })
      this.unresolvedEvents.splice(eventIndex, 1)
    },

    async getUnresolvedEvents () {
      this.loadingUnresolvedEvents = true
      if (!this.isAuthorized) {
        return
      }
      try {
        let _unresolvedEvents = await this.$apollo.query({
          query: GLNET_EVENT_UNRESOLVED,
          // variables: {
          //   user_id: this.userId
          // },
          loadingKey: 'loadingUnresolvedEvents',
          fetchPolicy: 'no-cache'
        })
        // console.log('_unresolvedEvents', _unresolvedEvents)
        this.unresolvedEvents = _unresolvedEvents.data.glnet_event
        this.countEventUnresolved = _unresolvedEvents.data.glnet_event_aggregate.aggregate.count
        this.loadingUnresolvedEvents = false
      } catch (error) {
        console.error('fail get unresolved events', error)
        this.unresolvedEvents = []
        this.loadingUnresolvedEvents = false
      }
    },

    testSome () {
      // this.$eventHub.$emit('refetch-unresolved-events')
      // this.$apollo.mutate({
      //   mutation: gql`
      //     mutation AddEvent {
      //       insert_glnet_event_one(object: {created_at: "2021-07-19T15:14:41.436982+00:00", category_id: 1, value_id: 1669268}) {
      //         id
      //       }
      //     }
      //   `
      // })

      this.$notify({
        group: 'foo',
        duration: 20000,
        title: 'Тест',
        text: 'Lorem ipsum dolor sit amet consectetur ',
        type: 'suc' // error warn success common
      })

      // this.$eventHub.$emit('refetch-unresolved-events')
    },
    getContentBadge(count){
      return count == 0 ? '' : String(count)
    },
    getColorEventBadge(count) {
      return count == 0 ? '' : 'error'
    },
    lastHundredEvents(eventLast) { 
      if (eventLast.length > 100) {
        return eventLast.slice(0, 100)
      }
      return eventLast
    }
  }
}
</script>

<style scoped lang="scss"> 
.background-white {
  background-color: white;
}
.logo {
  width: 290px !important;
  height: 48px;
  overflow: hidden;
  img {
    margin-top: calc(-65px);
    height: 170px;
    transition: height 0.3s, margin-top 0.3s;
  }
  span {
    font-weight: bold;
  }
  margin-bottom: 0;
}
.pagename {
  font-size: 18pt;
}
.glowing-animation {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    animation: glowing 1300ms infinite;
  }
}
@keyframes glowing {
  0% {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px rgb(255, 255, 255);
    transform: scale(0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.527);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.527);
    transform: scale(1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0);
    transform: scale(0);
  }
}
.left-border {
  width: 8px;
  height: 100%;
  position: absolute;
  left: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 0px!important;
  border-bottom-left-radius: 4px;
}
.v-menu__content {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  box-shadow: none!important;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateX(430px);
}
.event-title {
  width: 100%;
  word-break: normal;
}
.no-events {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-left: -20px;
  white-space: nowrap;
}
</style>
