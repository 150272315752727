<template>
  <div class="wrapper chart-table-wrapper">
    <!-- таблица -->
    <div class="" v-if="filterData.selectedChannelId !== null">
      <!-- :item-class="selectColor" -->
      <v-data-table
        dense
        item-key="id"
        class="elevation-1 table__chart"
        height="100%"
        width="100%"
        :headers="headers"
        :items="tableValues"
        :loading="loadingValues"
        :options.sync="options"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :server-items-length="totalValues"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100],
        }"
        @update:items-per-page="updateItemsPerPage($event)"
        @update:page="updatePage($event)"
        @update:sort-by="updateSortBy($event)"
        @update:sort-desc="updateSortDesc($event)"
      >
        <!-- eslint-disable-next-line -->
        <template #item.value="{ item }">
          {{ item.value | cutDigit }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.time="{ item }">
          {{ moment(item.time).format("DD MMM YYYY HH:mm:ss") }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.glnet_status="{ item }">
          <div class="chart-table-status" :style="{'background-color': getStatusColor(item.glnet_status)}">
            <p>
              {{ item.glnet_status.name }}
            </p>
          </div>
        </template>
      </v-data-table>
    </div>
    <p v-else class="display-2 font-weight-light text--disabled text-center pt-10">
      Выберите фильтры и нажмите "применить"
    </p>
  </div>
</template>

<script>
import appStore from "@/store/appStore";
const glnet_channelvalue = require("@/graphQL/channelvalue.gql");
import moment from "moment";
moment.locale("ru");

export default {
  name: "chart-table",
  props: {
    filterData: Object,
  },
  data() {
    return {
      headers: [
        { text: "ID", align: "start", value: "id", width: "10%" },
        { text: "Дата и время", value: "time", width: "20%" },
        { text: "Значение", value: "value", sortable: false },
        { text: "Статус", value: "glnet_status", sortable: false },
      ],
      tableValues: [],
      loadingValues: false,
      options: null,
      totalValues: 0,
      moment: moment,
      sortBy: "time",
      sortDesc: true,
      page: 1,
      itemsPerPage: 25,
    };
  },

  computed: {
    offset() {
      return (this.page - 1) * this.itemsPerPage;
    },

    isAuthorized() {
      return appStore.getters.VALUE("isAuthorized");
    },
  },

  filters: {
    cutDigit (val) {
      let float = parseFloat(val)
      if (!isNaN(float)) {
        return val.toFixed(3)
      }
      return '-'
    }
  },

  watch: {
    filterData: {
      deep: true,
      async handler(newVal) {
        this.tableValues = await this.getChannelValues(newVal);
      },
    },
    sortBy: {
      async handler() {
        this.tableValues = await this.getChannelValues(this.filterData);
      },
    },
    sortDesc: {
      async handler() {
        this.tableValues = await this.getChannelValues(this.filterData);
      },
    },
    page: {
      async handler() {
        this.tableValues = await this.getChannelValues(this.filterData);
      },
    },
    itemsPerPage: {
      async handler() {
        this.tableValues = await this.getChannelValues(this.filterData);
      },
    },
  },
  async mounted() {
    if (this.filterData.selectedChannelId !== null) {
      try {
        this.tableValues = await this.getChannelValues(this.filterData);
      } catch (e) {
        //pass
      }
    }
  },
  methods: {
    getStatusColor (status) {
      return this.getColorByStatusMix(status)
    },
    async getChannelValues(filterData) {
      if (!this.isAuthorized) return [];
      this.loadingValues = true;
      let variables = {
        limit: this.itemsPerPage !== "Все" ? this.itemsPerPage : this.totalLogs,
        offset: this.itemsPerPage !== "Все" ? this.offset : 0,
        datetime_from: filterData.dateTimeFrom,
        channel_id: filterData.selectedChannelId,
      };
      if (filterData.dateTimeTo != null) {
        variables.datetime_to = filterData.dateTimeTo
      }
      // сортировка
      if (this.sortBy !== "" && this.sortDesc !== "") {
        let _tmp = this.sortDesc === true ? "desc" : "asc";
        variables.order_by = {};
        variables.order_by[this.sortBy] = _tmp;
      }
      
      try {
        let response = await this.$apollo.query({
          query: glnet_channelvalue,
          variables: variables
        })
        let values = response.data.glnet_channelvalue;
        this.totalValues = response.data.glnet_channelvalue_aggregate.aggregate.count
        // 
        this.loadingValues = false;
        return values;
      } catch (error) {
        console.error("fail get channel values", error);
        this.loadingValues = false;
        return [];
      }
    },

    updateSortBy(e) {
      // console.log(e)
      if (e !== undefined) {
        this.sortBy = e;
      } else {
        this.sortBy = "";
      }
    },
    updateSortDesc(e) {
      
      if (e !== undefined) {
        this.sortDesc = e;
      } else {
        this.sortDesc = "";
      }
    },
    updatePage (e) {
      this.page = e
    },

    updateItemsPerPage (e) {
      this.itemsPerPage = e
    },
  },
};
</script>

<style scoped lang="scss">
.chart-table-wrapper {
  width: 100% !important;
  height: 100%;
}
.table__chart {
  height: 100%;
  width: 100%;
  right: 0;
  top: 64px;
  padding: 0;
  height: calc(100vh - 200px);
}
.chart-table-status {
  width: 100px;
  height: 100%;
  text-align: center;
  padding-top: 5px;
  border-radius: 4px;
  p {
    margin: 0;
    // color: white;
  }
}
</style>
