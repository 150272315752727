import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { createProvider } from './vue-apollo'
import Notifications from 'vue-notification'
import utilsMix from './mixins/utilsMix'
import authMix from './mixins/authMix'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
var VueDragula = require('vue-dragula');

Vue.use(VueDragula);
Vue.use(Notifications)
Vue.use(VueCookies)
Vue.mixin(utilsMix)
Vue.mixin(authMix)

// console.log('process.env.NODE_ENV', process.env)
if (process.env.NODE_ENV !== 'development') {
  VueCookies.remove("refresh_token_glnet")
  VueCookies.remove("access_token_glnet")
  VueCookies.remove("user-role_glnet")
}

// console.log(location)
// console.log(location.origin + location.pathname)

axios.get(location.origin + location.pathname + 'config.json').then(async response => {
  const httpLink = new HttpLink({
    uri: response.data.VUE_APP_HASURA_HOST
  })
  
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    let authHeaders = {} // TODO запрос токена перед запросом, а не обновление раз в 5 миунут
    if (VueCookies.get('user-role_glnet') === 'admin') {
      authHeaders['X-Hasura-Role'] = VueCookies.get('user-role_glnet')
    }
    if (VueCookies.get('access_token_glnet') !== null) {
      authHeaders.Authorization = 'Bearer ' + VueCookies.get('access_token_glnet')
    }
    return {
      headers: {
        ...headers,    
        ...authHeaders
      },
    }
  })
  Vue.prototype.$config = response.data
  Vue.config.productionTip = false
  
  Vue.prototype.$eventHub = new Vue(); // Global event bus
  
  new Vue({
    vuetify,
    render: h => h(App),
    apolloProvider: createProvider({link: authLink.concat(httpLink)}),
    router
  }).$mount('#app')
})
