<template>
  <v-dialog max-width="600">
    <template v-slot:activator="{ on, attrs }">
        <div class="delete-sensor-btn-wrap">
            <v-btn
        class="delete-sensor-btn"
        icon
        small
        color="white"
        v-bind="attrs"
        v-on="on"
        ><v-icon color="white">mdi-delete</v-icon></v-btn
      >
        </div>
    </template>
    <template v-slot:default="dialog">
      <v-card max-width="600">
        <v-toolbar color="primary" dark>Удаление датчика</v-toolbar>
        <v-card-text>
          <v-container>
            <v-col cols="12">
             <v-card-text>Вы уверены, что хотите удалить датчик   <span class="title">{{sensor.name}}</span>   из этой сцены?</v-card-text>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text :loading="loading" @click="deleteSceneSensor(dialog)">Удалить</v-btn>
          <v-btn text @click="dialog.value = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteSensor",
  props: {
    sceneSensorId: null,
    sensor: null,
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    deleteSceneSensor(dialog) {
      this.loading = true;
      /* eslint-disable no-unused-vars */
      if (!this.sceneSensorId) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/delete_scenesensor.gql"),
          variables: {
            id: this.sceneSensorId,
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Датчик удален",
            type: "suc" // error warn success common
          });
          setTimeout(()=>{
            this.loading = false
            this.$emit("refreshScenes");
            dialog.value = false;
          }, 1000)
        })
        .catch(error => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Датчик не удален",
            type: "err" // error warn success common
          });
          this.loading = false;
          dialog.value = false;
        });
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.delete-sensor-btn-wrap {
    position: absolute;
    top: 0;
    right: -35px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
}
</style>
