import { render, staticRenderFns } from "./AdminTableSensorVue.vue?vue&type=template&id=2e49b178&scoped=true&"
import script from "./AdminTableSensorVue.vue?vue&type=script&lang=js&"
export * from "./AdminTableSensorVue.vue?vue&type=script&lang=js&"
import style0 from "./AdminTableSensorVue.vue?vue&type=style&index=0&id=2e49b178&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e49b178",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAutocomplete,VBtn,VCard,VDataTable,VIcon,VSimpleTable})
