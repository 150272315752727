<template>
  <div>
    <v-row justify="center">
      <!-- модалки диалогов -->
      <v-dialog
        :retain-focus="false"
        v-for="(event, dialogIndex) in alerts.slice(-1)"
        :key="event.windowId"
        v-model="dialogs[dialogIndex]"
        persistent
        max-width="600"
      >
      <!-- :dark="getEventColorMix(event) === ''" -->
        <v-card>
          <div class="left-border" :style="{backgroundColor: getEventColorMix(event)}"></div>
          <v-card-title class="text-h5">
            <b class="pr-5">{{ (event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.glnet_sensor && event.glnet_channelvalue.glnet_channel.glnet_sensor.name) ? ('Изменение статуса датчика ' + event.glnet_channelvalue.glnet_channel.glnet_sensor.name) : 'GLnet 4' }}</b>
            <v-btn outlined elevation="0" icon @click="deleteAlert(event)" absolute right>
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <span class="alarm-popup-card-text" v-if="event && event.created_at">Время: {{ moment(event.created_at).format('DD MMM YYYY HH:mm:ss') }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event && event.glnet_channelvalue.value">Значение: {{ event.glnet_channelvalue.value | cutDigit }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.id">Номер события: {{ event.id }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.glnet_sensor && event.glnet_channelvalue.glnet_channel.glnet_sensor.name">Датчик: {{ event.glnet_channelvalue.glnet_channel.glnet_sensor.name }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.name">Канал: {{ event.glnet_channelvalue.glnet_channel.name }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.glnet_category && event.glnet_category.name">Категория: {{ event.glnet_category.name }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.description">Описание события: {{ getDescription(event.description) }}<br></span>
            <span class="alarm-popup-card-text" v-if="event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel && event.glnet_channelvalue.glnet_channel.description">Описание датчика: {{ event.glnet_channelvalue.glnet_channel.description }}</span>
            <!-- TODO принят тем-то во столько -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- если категория есть и она 1 или 2 (внимание и тревога) и ты не оператор -->
            <div v-if="event && event.glnet_category && (event.glnet_category.code === 1 || event.glnet_category.code === 2 || event.glnet_category.code === 3) && userRole !== 'operator'">
              <v-btn
                elevation="0"
                color="primary darken-1"
                v-if="event"
                :loading="loadingAccept"
                :disabled="!!event.accepted_at || !!event.user_id"
                @click="dialogOpen(event, 'accept')"
                outlined
                class="ml-2"
              >
                Принять
              </v-btn>
            
              <v-btn
                elevation="0"
                class="ml-5"
                color="primary darken-1"
                v-if="event"
                :loading="loadingResolve"
                @click="dialogOpen(event, 'resolve')"
                :disabled="!event.accepted_at"
                outlined
              >
                Решено
              </v-btn>
            </div>
            <!-- если не внимание и тревога и не оператор -->
            <div v-else-if="userRole !== 'operator'">
              <!-- @click.stop="deleteAlert(event)" -->
              <v-btn
                elevation="0"
                color="primary darken-1"
                v-if="event"
                :loading="loadingReadEvent && dialogEvent.id === event.id"
                @click.stop="readEvent(event)"
                outlined
                class="ml-2"
              >
                Прочитано
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                elevation="0"
                color="primary darken-1"
                v-if="event"
                @click.stop="deleteAlert(event)"
                outlined
                class="ml-2"
              >
                Закрыть
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- диалог принять -->
    <v-dialog
      v-model="dialogAccept"
      width="500"
      :retain-focus="false"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title class="justify-center py-10">Подтвердите выполнение операции</v-card-title>
        <v-card-subtitle class=" d-flex justify-center">Принять в работу событие {{ dialogEvent.id }}</v-card-subtitle>
        <v-card-actions class="d-flex justify-space-around pb-10">
          <v-btn outlined elevation="0" width="160px" @click.stop="acceptEvent(dialogEvent)" :loading="loadingAccept" color="primary">Подтверждаю</v-btn>
          <v-btn outlined width="160px" elevation="0" @click.stop="dialogAccept = false" >Отменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- диалог решить -->
    <v-dialog
      v-model="dialogResolve"
      width="500"
      :retain-focus="false"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title class="justify-center py-10">Подтвердите выполнение операции</v-card-title>
        <v-card-subtitle class=" d-flex justify-center">Решить событие {{ dialogEvent.id }}</v-card-subtitle>
        <v-card-actions class="d-flex justify-space-around pb-10">
          <v-btn outlined elevation="0" width="160px" @click.stop="resolveEvent(dialogEvent)" :loading="loadingResolve" color="primary">Подтверждаю</v-btn>
          <v-btn outlined width="160px" elevation="0" @click="dialogResolve = false" >Отменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appStore from '../store/appStore'
import moment from 'moment'
// const GLNET_EVENT = require('@/graphQL/glnet_event.gql')
const GLNET_EVENT_UNRESOLVED = require('@/graphQL/event_unresolved.gql')
// const GLNET_EVENT_SUBSCRIPTION = require('@/graphQL/event_subscription.gql')
const ACCEPT_EVENT = require('@/graphQL/accept_event.gql')
const RESOLVE_EVENT = require('@/graphQL/resolve_event.gql')

export default {
  name: 'alert-component',
  data: () => ({
    moment: moment,
    loadingAccept: false,
    loadingResolve: false,
    loadingReadEvent: false,
    alerts: [],
    dialogs: [],
    event: null,
    glnetEvent: null,
    glnet_event: null,
    realPrev: null, // предыдущие данные
    dialogAccept: false,
    dialogResolve: false,
    dialogEvent: {}
  }),

  computed: {
    isAuthorized () {
      return appStore.getters.VALUE('isAuthorized')
    },

    userId () {
      return appStore.getters.VALUE('userId')
    },

    userRole () {
      return appStore.getters.VALUE('userRole')
    }
  },

  filters: {
    cutDigit (val) {
      let float = parseFloat(val)
      if (!isNaN(float)) {
        return val.toFixed(3)
      }
      return '-'
    }
  },

  watch: {
    loadingAccept (newVal) {
      this.$eventHub.$emit('change-loading-accept-event', newVal)
    },
    loadingResolve (newVal) {
      this.$eventHub.$emit('change-loading-resolve-event', newVal)
    },
    loadingReadEvent (newVal) {
      this.$eventHub.$emit('change-loading-read-event', newVal)
    }
  },

  apollo: {
    glnetEvent: {
      query: GLNET_EVENT_UNRESOLVED,
      fetchPolicy: 'no-cache',
      pollInterval: 2000, // ZZZZZ
      // variables () {
      //   return {
      //     user_id: this.userId
      //   }
      // },
      update (data) {
        /*
          заворачиаю в объект {data: данные} для того чтобы не переписывать 
          функцию(она была скопирована из subscribeToMore)
        */
        return this.parceAlerts({data})
        // return data.glnet_event
      },
      skip () { return !this.isAuthorized }
    }
  },

  mounted () {
    this.$eventHub.$on('accept-event', this.acceptEvent)
    this.$eventHub.$on('resolve-event', this.resolveEvent)
    this.$eventHub.$on('read-event', this.readEvent)
  },

  beforeDestroy () {
    this.$eventHub.$off('accept-event')
    this.$eventHub.$off('resolve-event')
  },

  methods: {
    parceAlerts (subscriptionData) {
      /*
        previosResult работает очень криво. По какой-то из причин при добавлении эвента предыдущий результат
        не корректен. При изменениии эвента предыдущий результат не корректен. Не использую previosResult вместо этого переопределяю ствою переменную realPrev
      */
      if (this.realPrev === null) {
        this.realPrev = subscriptionData.data
        return subscriptionData.data
      }
      // console.log('subscriptionData', JSON.parse(JSON.stringify(subscriptionData.data.glnet_event)))
      // console.log('realPrev', JSON.parse(JSON.stringify(this.realPrev)))
      if (subscriptionData.data.glnet_event_aggregate.aggregate.count > this.realPrev.glnet_event_aggregate.aggregate.count) { // если добавилось новое(ые) событие(я)
        let newEventCount = subscriptionData.data.glnet_event_aggregate.aggregate.count - this.realPrev.glnet_event_aggregate.aggregate.count
        // console.log('new event count', newEventCount)
        let newEvents = subscriptionData.data.glnet_event.slice(0, newEventCount)
        // console.log('ADD event')
        this.createAlerts(newEvents)
        this.realPrev = subscriptionData.data
        return subscriptionData.data
      } else if (subscriptionData.data.glnet_event_aggregate.aggregate.count < this.realPrev.glnet_event_aggregate.aggregate.count) { // если эвент исчез
        let deletedEvent = null
        if (subscriptionData.data.glnet_event_aggregate.aggregate.count !== 0) {
          for (let prevEvent of this.realPrev.glnet_event) { // ищем удаленный эвент
            let currentItem = subscriptionData.data.glnet_event.find(subscrEvent => {
              deletedEvent = prevEvent // присваиваем эвент из прошлой даты
              return subscrEvent.id === prevEvent.id
            })
            if (currentItem === undefined) { // если элемент не нашелся, то это удаленный
              break // выходим из цикла for
            }
          }
        } else {
          deletedEvent = this.realPrev.glnet_event[0]
        }
        // добавить условие. Если у удаляемого эвента пользователь, который взял на себя проблему не ты
        // console.log('deletedEvent', deletedEvent)
        if (deletedEvent !== null && deletedEvent !== undefined) { // если нашли удаленный эвет, то закрываем уведомление, связанное с ним
          // console.log('DELETE event')
          this.deleteAlert(deletedEvent, true)
        }
        this.realPrev = subscriptionData.data
        return subscriptionData.data
      } else { // если что-то изменилось, но не добавилось и не удалилось событие событие, то ищем что изменилось
        let changedEvent = null
        /* 
          Обяснение if ниже.
          Основная причина описана выше previosResult работает очень криво ...
          Если изначальное значние эвета имеет состояние 1, и мы его меняем на состояние 2, то все работает отлично и можно использовать
          данные, которые предоставляет vue-apollo(т.е. переменную previosResult). НО если мы меняем эвент обратно в состояние 1,
          то по какой-то причине previosResult и subscriptionData одинаковы и оба имеют состояние 1, что не позволяет отследить что же изменилось.
          Создание typePolicies в inMemoryCacheOptions не привело ни к каким изменениям. Отключение кеша помогает отслеживать изменение любого состояния КРОМЕ
          перехода из состояния n в состояние 1.
          По это причине я сохраняю предыдущий результат самостоятельно и использую его в случае, когда количество эвентов не изменилось, но что-то из данных поменялось.
        */
        for (let prevIndex = 0; prevIndex < this.realPrev.glnet_event.length; prevIndex++) { // ищем что изменилось
          for (let subscrIndex = prevIndex; subscrIndex < subscriptionData.data.glnet_event.length; subscrIndex++) {
            let currentPrevEvent = this.realPrev.glnet_event[prevIndex]
            let currentSubscrEvent = subscriptionData.data.glnet_event[prevIndex]
            if (JSON.stringify(currentPrevEvent) !== JSON.stringify(currentSubscrEvent)) {
              changedEvent = currentSubscrEvent
              break
            }
          }
        }
        // console.log('changedEvent', JSON.stringify(changedEvent))
        // console.log('CHANGE event')
        this.changeEvent(changedEvent)
        this.realPrev = subscriptionData.data
        return subscriptionData.data
      }
    },

    checkDeleteAlert (event) {
      return event && event.accepted_at
    },

    changeEvent (newEvent) { // изменение эвента
      if (newEvent === null || newEvent === undefined) { // если не нашли эвент - ничего не делать
        return
      }
      let oldEventIndex = this.alerts.findIndex(item => { // находим старый эвент
        return item.id === newEvent.id
      })
      let oldEvent = this.alerts[oldEventIndex]
      if (oldEvent === undefined) { // если нет окна с этим эвентом(решили его из выпадающего списка в хедере)
        this.$eventHub.$emit('refetch-unresolved-events') // обновляем эвенты в хедере
        return
      }
      // если эвент нашелся, то делаем манипуляции с окном
      // console.log('oldEvent', oldEvent)
      // console.log('newEvent', newEvent)
      if (oldEvent.accepted_at !== newEvent.accepted_at) { // если эвент приняли
        // console.log('newEvent.user_id', newEvent.user_id)
        // console.log('this.userId', this.userId)
        if (newEvent.accepted_user_id === this.userId) { // если принял тот же пользователь
          // console.log('update event')
          this.$set(this.alerts, oldEventIndex, newEvent) // заменяем эвент на новый
          return
        } else { // если принял другой пользователь
          this.deleteAlert(newEvent.id, true) // удалить эвент
          return
        }
      }
      if (oldEvent.resolved_at !== newEvent.resolved_at) { // если конфликт разрешили(в теории это условие никогда не срабатывает т.к. удаление срабатывает из подписки)
        this.deleteAlert(newEvent.id, true) // удалить эвент
      }
    },

    async readEvent (event) {
      this.loadingReadEvent = true
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphQL/read_event.gql'),
          variables: {
            id: event.id,
            user_id: this.userId,
            accepted_at: new Date().toISOString(),
            resolved_at: new Date().toISOString()
          }
        })
        setTimeout(() => {
          this.loadingReadEvent = false
        }, 1000)
      } catch (error) {
        this.loadingReadEvent = false
        console.error('fail read event', error)
      }
    },

    async acceptEvent (event) {
      // console.log('acceptEvent', event)
      try {
        this.loadingAccept = true
        await this.$apollo.mutate({
          mutation: ACCEPT_EVENT,
          variables: {
            id: event.id,
            accepted_at: new Date().toISOString(),
            user_id: this.userId
          }
        })
        setInterval(() => {
          this.$eventHub.$emit('refetch-unresolved-events')
          this.loadingAccept = false
          this.dialogAccept = false
        }, 1000)
        // this.deleteAlert(event, true)
      } catch (error) {
        console.error('fail to accept event', error)
        this.$notify({
          group: 'foo',
          title: 'Внутренняя ошибка',
          text: 'Не удалось принять событие\n' + JSON.stringify(error),
          type: 'err' // error warn success common
        })
        this.loadingAccept = false
        this.dialogAccept = false
      }
    },

    async resolveEvent (event) {
      try {
        this.loadingResolve = true
        await this.$apollo.mutate({
          mutation: RESOLVE_EVENT,
          variables: {
            id: event.id,
            user_id: this.userId,
            resolved_at: new Date().toISOString()
          }
        })
        // console.log('delete from RESOLVE')
        // this.deleteAlert(event, true)
        setTimeout(() => {
          this.loadingResolve = false
          this.dialogResolve = false
        }, 1000)
      } catch (error) {
        console.error('fail resolve event', error)
        this.$notify({
          group: 'foo',
          title: 'Внутренняя ошибка',
          text: 'Не удалось решить конфликт.\n' + JSON.stringify(error),
          type: 'err' // error warn success common
        })
        this.loadingResolve = false
        this.dialogResolve = false
      }
    },

    deleteAlert (event, refetchInHeader) {
      if (refetchInHeader) {
        this.$eventHub.$emit('refetch-unresolved-events')
      }
      let alertIndex =  this.alerts.findIndex(_event => {
        return _event === event.id
      })
      this.$set(this.dialogs, alertIndex, false)
      setTimeout(() => { // задержка на анимацию закрытия окна
        this.alerts.splice(alertIndex, 1)
        this.dialogs.splice(alertIndex, 1)
      }, 300)

    },

    createAlerts (eventsArr) {
      eventsArr.forEach(event => {
        this.createAlert(event)
      })
    },

    createAlert (event) {
      if (event.accepted_at === null || event.accepted_user_id === this.userId) { // если никто не принял или принял, но id принявшего совпадает с id пользователя
        this.$eventHub.$emit('refetch-unresolved-events')
        // console.log('event', event)
        // if (event.glnet_category.code === 0) {
        //   return
        // }
        this.alerts.push({
          ...event,
          windowId: event.id + '_' + Math.floor(Math.random() * 999)
        })
        this.dialogs.push(true)
        let _title = event && event.glnet_channelvalue && event.glnet_channelvalue.glnet_channel ? ('Смена статуса: ' + event.glnet_channelvalue.glnet_channel.name) : 'GLnet 4'
        let type = 'error'
        if (event && event.glnet_category) {
          switch (event.glnet_category.code) {
            case 0:
              type = ''
              break

            case 1:
              type = 'warn'
              break

            case 2:
              type = 'error'
              break

            case 3:
              type = 'common'
              break
          
            default:
              type = 'error'
              break
          }
        }
        this.$notify({
          group: 'foo',
          title: _title,
          text: 'Категория ' + event.glnet_category.name,
          type: type.substring(0,3) // error warn success common
        })
      }
    },

    dialogOpen(event, name) {
      this.dialogEvent = event;
      if (name === 'accept') {
        this.dialogAccept = true
      } else {
        this.dialogResolve = true
      }
    }
  }
}
</script>

<style lang="scss">
.left-border {
  width: 8px;
  height: 100%;
  position: absolute;
  left: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 0px!important;
  border-bottom-left-radius: 4px;
}
.v-card__text {
  .alarm-popup-card-text {
    font-size: 16px;
  }
}
</style>
