<template>
  <v-card class="card px-8" elevation="0">
    <!-- выбор датчика -->
    <v-row>
      <v-col>
        <v-select
          dense
          :items="sensors"
          item-text="aggregateName"
          item-value="id"
          v-model="selectedSensorId"
          @change="updateSelectedSensorId($event)"
          :loading="loadingSensers"
          label="Выберите датчик"
          class="mt-10"
        >
          <template #no-data> Датчики не найдены </template>
        </v-select>
      </v-col>
    </v-row>
    <!-- доп инфа о датчике -->
    <v-row v-if="selectedSensor !== null" class="primary-text-color">
      <v-col>
        <!-- TODO сделать запрос типов -->
        <p>Тип: {{ this.selectedSensor.glnet_sensortype.name }}</p>
        <p>Серийный номер: {{ this.selectedSensor.serial_number }}</p>
        <p>Описание: {{ this.selectedSensor.description }}</p>
      </v-col>
    </v-row>
    <v-row v-if="selectedSensor !== null">
      <v-col>
        <!-- выбор канала -->
        <v-select
          dense
          :items="channels"
          item-text="name"
          item-value="gid"
          v-model="selectedChannelId"
          :loading="loadingSensers"
          label="Выберите канал"
        >
          <template #no-data> Каналы не найдены </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedChannel !== null" class="primary-text-color">
      <v-col>
        <p class="display-1">{{ this.selectedChannel.name }}</p>
        <p>Единица измерения: {{ this.selectedChannel.unit }}</p>
        <p>Описание: {{ this.selectedChannel.description }}</p>
      </v-col>
    </v-row>
    <v-row v-if="selectedChannel !== null">
      <v-col>
        <!-- дата от -->
        <v-menu
          ref="dateFromRef"
          v-model="isDateFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="dateFrom"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            locale="ru"
            @click:date="$refs.dateFromRef.save(dateFrom)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <!-- время от -->
        <v-menu
          ref="timeFromRef"
          v-model="isTimeFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="timeFrom"
              prepend-icon="mdi-clock"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-model="timeFrom"
            @click:minute="$refs.timeFromRef.save(timeFrom)"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <!-- Дата и время до -->
    <v-row v-if="selectedChannelId !== null" class="primary-text-color">
      <v-col>
        <!-- дата от -->
        <v-menu
          ref="dateToRef"
          v-model="isDateTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            locale="ru"
            @click:date="$refs.dateToRef.save(dateTo)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <!-- время до -->
        <v-menu
          ref="timeToRef"
          v-model="isTimeTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="timeTo"
              prepend-icon="mdi-clock"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            format="24hr"
            v-model="timeTo"
            @click:minute="$refs.timeToRef.save(timeTo)"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="selectedChannelId !== null" class="primary-text-color">
      <v-col>
        <v-btn
          elevation="0"
          class="float-left mt-5 px-7 py-3 filter__button"
          @click="acceptFilter"
          color="primary"
          :disabled="dateFrom === null"
          outlined
        >
          Применить
        </v-btn>
        <v-btn
          elevation="0"
          class="float-left mt-5 ml-5 px-7 py-3 filter__button"
          @click="clearDateFilter"
          outlined
          :disabled="
            !(
              dateFrom !== null ||
              dateTo !== null ||
              timeFrom !== null ||
              timeTo !== null
            )
          "
        >
          Сбросить
        </v-btn>
        <v-btn
          elevation="0"
          v-if="userRole !== 'operator' && selectedChannelId !== null && acceptedFilters"
          class="filter__button btn-exp-alone ml-8"
          :class="{'btn-exp-alone': activeTab === 'table', 'btn-exp-not-alone': activeTab === 'chart'}"
          @click="exportData"
          color="primary"
          :loading="loadingCreateReportArchive"
          outlined
        >Экспорт .csv</v-btn
        >

        <v-btn
          elevation="0"
          v-if="userRole !== 'operator' && selectedChannelId !== null && acceptedFilters && activeTab === 'chart'"
          class="filter__button ml-5 btn-exp-chart"
          @click="exportChart"
          color="primary"
          outlined
        >Экспорт .png</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
const GLNET_SENSOR = require("@/graphQL/sensor.gql");
const GLNET_CHANNEL_BY_SENSOR_ID = require("@/graphQL/channel_by_sensor_id.gql");
const glnet_channelvalue = require("@/graphQL/channelvalue.gql");
import appStore from "@/store/appStore";
import moment from "moment";
moment.locale("ru");

export default {
  name: "channel-selector",
  data: () => ({
    sensors: [],
    selectedSensorId: null,
    loadingSensers: false,
    channels: [],
    selectedChannelId: null,
    loadingChannels: false,
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
    isDateFrom: false,
    isDateTo: false,
    isTimeFrom: false,
    isTimeTo: false,
    filter: {
      dateTimeFrom: null,
      dateTimeTo: null,
      selectedChannelId: null
    },
    acceptedFilters: false,
    activeTab: 'chart'
  }),

  computed: {
    selectedSensor() {
      // находит датчик по id и возвращает его
      if (!this.sensors || !this.selectedSensorId) {
        return null;
      }
      return this.sensors.find(item => {
        return item.id === this.selectedSensorId;
      });
    },

    selectedChannel() {
      if (!this.channels || !this.selectedChannelId) {
        return null;
      }
      return this.channels.find(channel => {
        return channel.gid === this.selectedChannelId;
      });
    },

    loadingCreateReportArchive () {
      return appStore.getters.VALUE('loadingCreateReportArchive')
    },

    userRole () {
      return appStore.getters.VALUE('userRole')
    }
  },

  watch: {
    // запрос каналов при смене датчика
    selectedSensorId(newVal) {
      this.getChannelsBySensorId(newVal);
    },
    selectedChannelId(newVal) {
      appStore.dispatch("SET_VALUE", {
        key: "archiveChannelSelectedId",
        value: newVal
      });
    },
    dateFrom(newVal) {
      appStore.dispatch("SET_VALUE", {
        key: "archiveDateFrom",
        value: newVal
      });
    },
    dateTo(newVal) {
      appStore.dispatch("SET_VALUE", {
        key: "archiveDateTo",
        value: newVal
      });
    },
    timeFrom(newVal) {
      appStore.dispatch("SET_VALUE", {
        key: "archiveTimeFrom",
        value: newVal
      });
    },
    timeTo(newVal) {
      appStore.dispatch("SET_VALUE", {
        key: "archiveTimeTo",
        value: newVal
      });
    },
  },

  mounted() {
    this.$eventHub.$on('change-active-tab', this.changeActiveTab)
    this.getSensors();
    this.setFiltersFromStore();
  },

  beforeDestroy () {
    this.$eventHub.$off('change-active-tab')
  },

  methods: {
    exportChart () {
      this.$eventHub.$emit('export-chart')
    },
    changeActiveTab (e) {
      this.activeTab = e
    },
    updateSelectedSensorId(e) {
      this.selectedChannelId = null;
      appStore.dispatch("SET_VALUE", {
        key: "archiveSensorSelectedId",
        value: e
      });
    },
    setFiltersFromStore() {
      this.loadingFromStore = true
      let _sensorId = appStore.getters.VALUE("archiveSensorSelectedId");
      let _channelId = appStore.getters.VALUE("archiveChannelSelectedId");
      let _dateFrom = appStore.getters.VALUE("archiveDateFrom");
      let _dateTo = appStore.getters.VALUE("archiveDateTo");
      let _timeFrom = appStore.getters.VALUE("archiveTimeFrom");
      let _timeTo = appStore.getters.VALUE("archiveTimeTo");
      if (_sensorId !== null) {
        this.selectedSensorId = _sensorId;
      } else {
        return;
      }
      if (_channelId !== null) {
        this.selectedChannelId = _channelId;
      } else {
        return;
      }
      this.dateFrom = _dateFrom;
      this.dateTo = _dateTo;
      this.timeFrom = _timeFrom;
      this.timeTo = _timeTo;
      if (_dateFrom !== null) {
        this.acceptFilter()
      }
    },
    exportData() {
      // this.loadingCreateReport = true;
      appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportArchive', value: true})
      setTimeout(async () => {
        // задержка на то чтобы
        console.time("generate report");
        let req = [];
        let timeoutsArr = []
        let _variables = {
          datetime_from: this.filter.dateTimeFrom,
          datetime_to: this.filter.dateTimeTo == null ? "now()" : this.filter.dateTimeTo,
          channel_id: this.filter.selectedChannelId,
          order_by: { time: "desc" }
        };

        let count = await this.$apollo.query({
          query: glnet_channelvalue,
          variables: {
            ..._variables,
            limit: 1,
            offset: 0
          }
        })
        count = count.data.glnet_channelvalue_aggregate.aggregate.count; 

        for (let i = 0; i * 300 < count; i++) {
          req.push(
            new Promise((resolve, reject) => { // обертка для таймаута(задержка между запросами)
              let timer = setTimeout(() => {
                if (appStore.getters.VALUE('breakReportArchive')) {
                  appStore.dispatch('SET_VALUE', {key: 'breakReportArchive', value: false})
                  appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportArchive', value: false})
                  timeoutsArr.forEach(item => {
                    clearTimeout(item)
                  })
                  throw new Error('break create report, cause logout')
                }
                this.$apollo.query({
                  query: glnet_channelvalue,
                  variables: {
                    ..._variables,
                    limit: 300,
                    offset: i * 300
                  }
                }).then(response => {
                  resolve(response)
                }).catch(requerstErr => {
                  reject(requerstErr)
                })
              }, i * 2 * 1000) // промеждуток между запросами
              timeoutsArr.push(timer)
            })
          );
        }
        Promise.all(req).then(resps => {
          let preparedData = [];
          resps.forEach(resp => {
            preparedData.push(
              resp.data.glnet_channelvalue.map(item => {
                return {
                  ID: item.id,
                  "Дата и время": moment(item.time).format("DD-MM-YYYY_hh:mm"),
                  Значение: item.value
                };
              })
            );
          });
          this.createCsvMix(
            preparedData,
            "archive" + moment().format("DD-MM-YYYY_hh:mm"),
            'loadingCreateReportArchive'
          );
          // this.loadingCreateReport = false;
          appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportArchive', value: false})
        }).catch(err => {
          console.error('fail generate report', err)
          appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportArchive', value: false})
          this.$notify({
            group: 'foo',
            title: 'Ошибка экспорта',
            text: 'Не удалось создать отчет\n' + JSON.stringify(err),
            type: 'err' // error warn success common
          })
          // this.loadingCreateReport = false
        })
      }, 100);
    },
    getSensors() {
      this.loadingSensers = true;
      this.$apollo
        .query({
          query: GLNET_SENSOR
        })
        .then(response => {
          let _sensors = response.data.glnet_sensor;
          if (_sensors.length) {
            this.sensors = _sensors.map(item => {
              return {
                ...item,
                aggregateName: item.name + (item.gid ? " " + item.gid : "")
              };
            });
          }
        })
        .finally(() => {
          this.loadingSensers = false;
        });
    },

    getChannelsBySensorId(sensorId) {
      this.loadingChannels = true;
      this.$apollo
        .query({
          query: GLNET_CHANNEL_BY_SENSOR_ID,
          variables: {
            id: sensorId
          }
        })
        .then(response => {
          this.channels = response.data.glnet_channel;
        })
        .finally(() => {
          this.loadingChannels = false;
        });
    },

    clearDateFilter() {
      this.dateFrom = null;
      this.dateTo = null;
      this.timeFrom = null;
      this.timeTo = null;
      this.filter.dateTimeFrom = null;
      this.filter.dateTimeTo = null;
      appStore.dispatch("SET_VALUE", {
        key: "archiveDateTimeFrom",
        value: null
      });
      appStore.dispatch("SET_VALUE", {
        key: "archiveDateTimeTo",
        value: null
      });
      this.acceptedFilters = false
    },

    acceptFilter() {
      this.filter.selectedChannelId = this.selectedChannelId;
      if (this.dateFrom !== null) {
        let _dateTimeFromString = this.dateFrom;
        if (this.timeFrom !== null) {
          _dateTimeFromString += "T" + this.timeFrom;
        }
        this.filter.dateTimeFrom = moment(_dateTimeFromString)
          .utc()
          .format();
        appStore.dispatch("SET_VALUE", {
          key: "archiveDateTimeFrom",
          value: this.filter.dateTimeFrom
        });
      }
      if (this.dateTo !== null) {
        let _dateTimeToString = this.dateTo;
        if (this.timeTo !== null) {
          _dateTimeToString += "T" + this.timeTo;
        }
        this.filter.dateTimeTo = moment(_dateTimeToString)
          .utc()
          .format();
        appStore.dispatch("SET_VALUE", {
          key: "archiveDateTimeTo",
          value: this.filter.dateTimeTo
        });
      }
      this.$emit("acceptFilters", {...this.filter});
      this.acceptedFilters = true;
    }
  }
};
</script>
<style scoped>
.card {
  margin: 0;
  width: 400px;
  position: absolute;
  left: 56px;
  top: 48px;
  height: calc(100vh - 48px);
}
.filter__button {
  border-radius: 5px;
  transition: 0.3s;
}
.btn-exp-alone {
  position: absolute;
  left: 0;
  bottom: 17px;
  width: 84%;
}
.btn-exp-not-alone {
  position: absolute;
  left: 0;
  bottom: 17px;
  width: 38%;
}
.btn-exp-chart {
  position: absolute;
  left: 175px;
  bottom: 17px;
  width: 38%;
}
</style>
