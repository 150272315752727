<template>
  <div class="">
    <!-- фильтры -->
    <div class="filter">
      <v-card class="filter__card pt-10 px-6" elevation="0" height="100%">
        <!-- датчик -->
        <v-select
          :items="sensors"
          item-text="name"
          item-value="id"
          v-model="selectedSensorId"
          :loading="loadingSensers"
          label="Выберите датчик"
          clearable
          @click:clear="clearSelectedSensor"
        >
          <template #no-data>
            Датчики не найдены
          </template>
        </v-select>

        <!-- канал -->
        <v-select
          :items="selectedSensorId === null ? [] : channels"
          item-text="name"
          item-value="gid"
          v-model="selectedChannelId"
          :loading="loadingSensers"
          :disabled="selectedSensorId == null"
          label="Выберите канал"
          clearable
          @click:clear="filter.selectedChannelId = null"
        >
          <template #no-data>
            {{
              selectedSensorId === null
                ? "Выберите датчик"
                : "Каналы не найдены"
            }}
          </template>
        </v-select>
        <v-select
          :items="categories"
          item-text="name"
          item-value="code"
          v-model="selectedCategoryId"
          :loading="loadingCategories"
          label="Выберите категорию"
          clearable
          @click:clear="filter.selectedCategoryId = null"
        >
          <template #no-data>
            Категории не найдены
          </template>
        </v-select>
        <!-- Дата и время от -->
        <v-row>
          <v-col>
            <!-- дата от -->
            <v-menu
              ref="dateFromRef"
              v-model="isDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="От"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                locale="ru"
                @click:date="$refs.dateFromRef.save(dateFrom)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <!-- время от -->
            <v-menu
              ref="timeFromRef"
              v-model="isTimeFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeFrom"
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="timeFrom"
                @click:minute="$refs.timeFromRef.save(timeFrom)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Дата и время до -->
        <v-row>
          <v-col>
            <!-- дата от -->
            <v-menu
              ref="dateToRef"
              v-model="isDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="До"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                locale="ru"
                @click:date="$refs.dateToRef.save(dateTo)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <!-- время от -->
            <v-menu
              ref="timeToRef"
              v-model="isTimeTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeTo"
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="timeTo"
                @click:minute="$refs.timeToRef.save(timeTo)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div class="filter-radio mt-5 primary-text-color">
          <h5>Принято</h5>
          <div class="input-wrap">
            <label>
              <input
                name="isAccepted"
                type="radio"
                hidden
                :value="true"
                v-model="isAccepted"
              />
              <span>Да</span>
            </label>
            <label>
              <input
                name="isAccepted"
                type="radio"
                hidden
                :value="false"
                v-model="isAccepted"
              />
              <span>Нет</span>
            </label>
            <label>
              <input
                name="isAccepted"
                type="radio"
                hidden
                :value="null"
                v-model="isAccepted"
              />
              <span>Не важно</span>
            </label>
          </div>
        </div>

        <div class="filter-radio mt-5 primary-text-color">
          <h5>Решено</h5>
          <div class="input-wrap">
            <label>
              <input
                name="isResolved"
                type="radio"
                hidden
                :value="true"
                v-model="isResolved"
              />
              <span>Да</span>
            </label>
            <label>
              <input
                name="isResolved"
                type="radio"
                hidden
                :value="false"
                v-model="isResolved"
              />
              <span>Нет</span>
            </label>
            <label>
              <input
                name="isResolved"
                type="radio"
                hidden
                :value="null"
                v-model="isResolved"
              />
              <span>Не важно</span>
            </label>
          </div>
        </div>

        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 px-7 py-3 filter__button "
          @click="acceptFilter"
          color="primary"
          :disabled="!(
            dateFrom !== null ||
            dateTo !== null ||
            prevFilter.isAccepted !== isAccepted ||
            prevFilter.isResolved !== isResolved ||
            prevFilter.selectedSensorId !== selectedSensorId ||
            prevFilter.selectedChannelId !== selectedChannelId ||
            prevFilter.selectedCategoryId !== selectedCategoryId
          )"
        >
          Применить
        </v-btn>
        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 ml-5 px-7 py-3 filter__button"
          @click="clearAll"
        >
          Сбросить
        </v-btn>
        <v-btn
          elevation="0"
          outlined
          class=" filter__btn btn-exp ml-6"
          color="primary"
          @click="exportData"
          :loading="loadingCreateReportEventLog"
          v-if="userRole !== 'operator'"
          >Экспорт</v-btn
        >
      </v-card>
    </div>
    <!-- таблица -->
    <div class="wrapper">
      <v-data-table
        dense
        id="table"
        item-key="id"
        class="table__event"
        height="100%"
        :headers="headers"
        :items="events"
        :loading="!!loadingEvents"
        :options.sync="options"
        :server-items-length="totalEvents"
        no-data-text="-"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100]
        }"
        @update:items-per-page="updateItemsPerPage($event)"
        @update:page="updatePage($event)"
        @update:sort-by="updateSortBy($event)"
        @update:sort-desc="updateSortDesc($event)"
      >
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <div class="mt-10 pt-10">
            <h1>Нет данных</h1>
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.glnet_channelvalue="{ item }">
          {{ (item && item.glnet_channelvalue && item.glnet_channelvalue.value) ? item.glnet_channelvalue.value : '' | cutDigit }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.description="{ item }">
          {{  getDescription(item.description) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format("DD MMM YYYY HH:mm:ss") }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.glnet_category.name="{ item }">
          <div class="event-log-category" :style="{'background-color': getCategoryColor(item.glnet_category)}">
            <p :style="{color: (item.glnet_category.code === 2) ? 'white': 'black'}">
              {{ item.glnet_category.name }}
            </p>
          </div>
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.accepted_at="{ item }">
          {{
            item.accepted_at !== null
              ? moment(item.accepted_at).format("DD MMM YYYY HH:mm:ss")
              : "Не принято"
          }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.resolved_at="{ item }">
          {{
            item.resolved_at !== null
              ? moment(item.resolved_at).format("DD MMM YYYY HH:mm:ss")
              : "Не решено"
          }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import appStore from "@/store/appStore";
import moment from "moment";

const GLNET_SENSOR = require("@/graphQL/sensor.gql");
const GLNET_CHANNEL_BY_SENSOR_ID = require("@/graphQL/channel_by_sensor_id.gql");
const GLNET_EVENT_TABLE = require("@/graphQL/event_table.gql");
const GLNET_CATEGORY = require("@/graphQL/category.gql");
// const GLNET_STATUS = require('@/graphQL/glnet_status.gql')

export default {
  data: () => ({
    moment: moment,
    // фильтры
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
    isDateFrom: false,
    isDateTo: false,
    isTimeFrom: false,
    isTimeTo: false,
    isAccepted: null,
    isResolved: null,
    prevFilter: {
      dateTimeFrom: null,
      dateTimeTo: null,
      isAccepted: null,
      isResolved: null,
      // selectedStatusId: null,
      selectedCategoryId: null,
      selectedSensorId: null,
      selectedChannelId: null
    },
    filter: {
      dateTimeFrom: null,
      dateTimeTo: null,
      isAccepted: null,
      isResolved: null,
      // selectedStatusId: null,
      selectedCategoryId: null,
      selectedSensorId: null,
      selectedChannelId: null
    },
    // статусы
    // statuses: [],
    // selectedStatusId: null,
    // loadingStatuses: false,
    // категории
    categories: [],
    selectedCategoryId: null,
    loadingCategories: false,
    // датчики
    sensors: [],
    selectedSensorId: null,
    loadingSensers: false,
    // каналы
    channels: [],
    selectedChannelId: null,
    loadingChannels: false,
    // таблица
    itemsPerPage: 25,
    page: 1,
    loadingEvents: 0,
    options: {
      sortBy: ["created_at"],
      sortDesc: [true]
    },
    totalEvents: 0,
    events: [],
    sortDesc: true,
    sortBy: "created_at",
    headers: [
      { text: "ID", align: "start", value: "id" },
      {
        text: "Датчик",
        value: "glnet_channelvalue.glnet_channel.glnet_sensor.name",
        sortable: false
      },
      {
        text: "Канал",
        value: "glnet_channelvalue.glnet_channel.name",
        sortable: false
      },
      { text: "Значение", value: "glnet_channelvalue", sortable: false },
      {
        text: "Ед. изм.",
        value: "glnet_channelvalue.glnet_channel.unit",
        sortable: false
      },
      { text: "Категория", value: "glnet_category.name", sortable: false },
      { text: "Описание", value: "description", sortable: false },
      { text: "Создано", value: "created_at" },
      { text: "Принято", value: "accepted_at" },
      { text: "Решено", value: "resolved_at" }
    ]
  }),

  computed: {
    selectedSensor() {
      // находит датчик по id и возвращает его
      if (!this.sensors || !this.selectedSensorId) {
        return null;
      }
      return this.sensors.find(item => {
        return item.id === this.selectedSensorId;
      });
    },

    selectedChannel() {
      if (!this.channels || !this.selectedChannelId) {
        return null;
      }
      return this.channels.find(channel => {
        return channel.gid === this.selectedChannelId;
      });
    },

    offset() {
      return (this.page - 1) * this.itemsPerPage;
    },

    isAuthorized() {
      return appStore.getters.VALUE("isAuthorized");
    },

    loadingCreateReportEventLog () {
      return appStore.getters.VALUE("loadingCreateReportEventLog");
    },

    userRole () {
      return appStore.getters.VALUE('userRole')
    }
  },

  filters: {
    cutDigit (val) {
      let float = parseFloat(val)
      if (!isNaN(float)) {
        return val.toFixed(3)
      }
      return '-'
    }
  },

  watch: {
    // запрос каналов при смене датчика
    selectedSensorId(newVal) {
      this.getChannelsBySensorId(newVal);
    }
  },

  apollo: {
    events: {
      query: GLNET_EVENT_TABLE,
      fetchPolicy: 'no-cache',
      loadingKey: "loadingEvents",
      skip() {
        return !this.isAuthorized;
      },
      variables() {
        let _variables = {
          limit:
            this.itemsPerPage !== "Все" ? this.itemsPerPage : this.totalEvents,
          offset: this.itemsPerPage !== "Все" ? this.offset : 0
        };
        // сортировка
        if (this.sortBy !== "" && this.sortDesc !== "") {
          let _tmp = this.sortDesc === true ? "desc" : "asc";
          _variables.order_by = {};
          _variables.order_by[this.sortBy] = _tmp;
        }

        _variables.where = {};
        // даты
        if (
          this.filter.dateTimeFrom !== null ||
          this.filter.dateTimeTo !== null
        ) {
          // если какая-то из дат есть
          _variables.where.created_at = {}; // создаем объект
          if (this.filter.dateTimeFrom !== null) {
            // добавляем первую дату
            _variables.where.created_at._gte = this.filter.dateTimeFrom;
          }
          if (this.filter.dateTimeTo !== null) {
            // добавляем вторую дату
            _variables.where.created_at._lte = this.filter.dateTimeTo;
          }
        }

        // датчик, канал
        if (this.filter.selectedSensorId !== null) {
          _variables.where.glnet_channelvalue = {
            glnet_channel: { sensor_id: { _eq: this.filter.selectedSensorId } }
          }; // датчик по ID
          if (this.filter.selectedChannelId !== null) {
            _variables.where.glnet_channelvalue = {
              glnet_channel: { gid: { _eq: this.filter.selectedChannelId } }
            }; // канал на ID
          }
        }

        // категория
        if (this.filter.selectedCategoryId !== null) {
          _variables.where.category_id = {
            _eq: this.filter.selectedCategoryId
          };
        }

        // принято решено
        if (this.filter.isAccepted !== null) {
          _variables.where.accepted_at = { _is_null: !this.filter.isAccepted };
        }
        if (this.filter.isResolved !== null) {
          _variables.where.resolved_at = { _is_null: !this.filter.isResolved };
        }

        // console.log('_variables', _variables)
        return _variables;
      },
      update(data) {
        this.totalEvents = data.glnet_event_aggregate.aggregate.count;
        // console.log(JSON.parse(JSON.stringify(data.glnet_event)))
        return data.glnet_event;
      }
    }
  },

  mounted() {
    this.getSensors();
    // this.getStatuses()
    this.getCategories();
  },

  methods: {
    clearSelectedSensor () {
      this.selectedSensorId = null
      this.selectedChannelId = null
      this.filter.selectedSensorId = null
      this.filter.selectedChannelId = null
    },

    getCategoryColor (category) {
      return this.getEventColorMix({glnet_category: category})
    },

    exportData() {
      // this.loadingCreateReport = true;
      appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportEventLog', value: true})
      setTimeout(async () => {
        // задержка на то чтобы
        console.time("generate report");
        let req = [];
        let timeoutsArr = []
        let _variables = {};
        // сортировка
        if (this.sortBy !== "" && this.sortDesc !== "") {
          let _tmp = this.sortDesc === true ? "desc" : "asc";
          _variables.order_by = {};
          _variables.order_by[this.sortBy] = _tmp;
        }

        _variables.where = {};
        // даты
        if (
          this.filter.dateTimeFrom !== null ||
          this.filter.dateTimeTo !== null
        ) {
          // если какая-то из дат есть
          _variables.where.created_at = {}; // создаем объект
          if (this.filter.dateTimeFrom !== null) {
            // добавляем первую дату
            _variables.where.created_at._gte = this.filter.dateTimeFrom;
          }
          if (this.filter.dateTimeTo !== null) {
            // добавляем вторую дату
            _variables.where.created_at._lte = this.filter.dateTimeTo;
          }
        }

        // датчик, канал
        if (this.filter.selectedSensorId !== null) {
          _variables.where.glnet_channelvalue = {
            glnet_channel: { sensor_id: { _eq: this.filter.selectedSensorId } }
          }; // датчик по ID
          if (this.filter.selectedChannelId !== null) {
            _variables.where.glnet_channelvalue = {
              glnet_channel: { gid: { _eq: this.filter.selectedChannelId } }
            }; // канал на ID
          }
        }

        // категория
        if (this.filter.selectedCategoryId !== null) {
          _variables.where.category_id = {
            _eq: this.filter.selectedCategoryId
          };
        }

        // принято решено
        if (this.filter.isAccepted !== null) {
          _variables.where.accepted_at = { _is_null: !this.filter.isAccepted };
        }
        if (this.filter.isResolved !== null) {
          _variables.where.resolved_at = { _is_null: !this.filter.isResolved };
        }
        
        let count = this.totalEvents
        let countPerTime = 300

        for (let i = 0; i * countPerTime < count; i++) {
          req.push(
            new Promise((resolve, reject) => { // обертка для таймаута(задержка между запросами)
              let timer = setTimeout(() => {
                if (appStore.getters.VALUE('breakReportEventLog')) {
                  appStore.dispatch('SET_VALUE', {key: 'breakReportEventLog', value: false})
                  appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportEventLog', value: false})
                  timeoutsArr.forEach(item => {
                    clearTimeout(item)
                  })
                  throw new Error('break create report, cause logout')
                }
                this.$apollo.query({
                  query: GLNET_EVENT_TABLE,
                  variables: {
                    ..._variables,
                    limit: countPerTime,
                    offset: i * countPerTime
                  }
                }).then(response => {
                  resolve(response)
                }).catch(requerstErr => {
                  reject(requerstErr)
                })
              }, i * 3 * 1000) // промеждуток между запросами 3сек
              timeoutsArr.push(timer)
            })
          );
        }
        Promise.all(req).then(resps => {
          let preparedData = [];
          resps.forEach(resp => {
            preparedData.push(
              resp.data.glnet_event.map(item => {
                return {
                  ID: item.id,
                  Датчик:
                    item.glnet_channelvalue &&
                    item.glnet_channelvalue.glnet_channel &&
                    item.glnet_channelvalue.glnet_channel.glnet_sensor &&
                    item.glnet_channelvalue.glnet_channel.glnet_sensor.name
                      ? item.glnet_channelvalue.glnet_channel.glnet_sensor.name
                      : "-",
                  Канал:
                    item.glnet_channelvalue &&
                    item.glnet_channelvalue.channel_id
                      ? item.glnet_channelvalue.channel_id
                      : "-",
                  Значение:
                    item.glnet_channelvalue && item.glnet_channelvalue.value
                      ? item.glnet_channelvalue.value
                      : "-",
                  "Ед.Измер":
                    item.glnet_channelvalue &&
                    item.glnet_channelvalue.glnet_channel &&
                    item.glnet_channelvalue.glnet_channel.unit
                      ? item.glnet_channelvalue.glnet_channel.unit
                      : "-",
                  Категория:
                    item.glnet_category && item.glnet_category.name
                      ? item.glnet_category.name
                      : "-",
                  Описание: item.description,
                  Создано: moment(item.created_at).isValid()
                    ? moment(item.created_at).format("DD.MM.YYYY HH:mm:ss")
                    : '-',
                  Принято: moment(item.accepted_at).isValid()
                    ? moment(item.accepted_at).format("DD.MM.YYYY HH:mm:ss")
                    : '-',
                  Решено: moment(item.resolved_at).isValid()
                    ? moment(item.resolved_at).format("DD.MM.YYYY HH:mm:ss")
                    : '-'
                };
              })
            );
          });
          this.createCsvMix(
            preparedData,
            "alerts-event" + moment().format("DD-MM-YYYY_hh:mm"),
            'loadingCreateReportEventLog'
          );
          // this.loadingCreateReport = false;
        }).catch(err => {
          console.error('fail generate report', err)
          this.$notify({
            group: 'foo',
            title: 'Ошибка экспорта',
            text: 'Не удалось создать отчет\n' + JSON.stringify(err),
            type: 'err' // error warn success common
          })
          // this.loadingCreateReport = false
          appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportEventLog', value: false})
        })
      }, 100);
    },

    acceptFilter() {
      this.filter.selectedSensorId = this.selectedSensorId;
      this.filter.selectedChannelId = this.selectedChannelId;
      // this.filter.selectedStatusId = this.selectedStatusId
      this.filter.selectedCategoryId = this.selectedCategoryId;
      this.filter.isAccepted = this.isAccepted; // делаем копию, чтобы не связывать значения
      this.filter.isResolved = this.isResolved;
      // даты
      if (this.dateFrom !== null) {
        let _dateTimeFromString = this.dateFrom;
        if (this.timeFrom !== null) {
          _dateTimeFromString += "T" + this.timeFrom;
        }
        this.filter.dateTimeFrom = moment(_dateTimeFromString)
          .utc()
          .format();
      }
      if (this.dateTo !== null) {
        let _dateTimeToString = this.dateTo;
        if (this.timeTo !== null) {
          _dateTimeToString += "T" + this.timeTo;
        }
        this.filter.dateTimeTo = moment(_dateTimeToString)
          .utc()
          .format();
      }
      this.prevFilter = {...this.filter}
    },

    async getCategories() {
      this.loadingCategories = true;
      this.$apollo
        .query({
          query: GLNET_CATEGORY
        })
        .then(response => {
          this.categories = response.data.glnet_category;
        })
        .catch(error => {
          console.error("fail get categories", error);
          this.categories = [];
        })
        .finally(() => {
          {
            this.loadingCategories = false;
          }
        });
    },

    // TODO вынести в миксины запрос сенсеров и каналов
    getSensors() {
      this.loadingSensers = true;
      this.$apollo
        .query({
          // запрос датчиков
          query: GLNET_SENSOR
        })
        .then(response => {
          this.sensors = response.data.glnet_sensor;
        })
        .finally(() => {
          this.loadingSensers = false;
        });
    },

    getChannelsBySensorId(sensorId) {
      if (sensorId === null) {
        return;
      }
      this.loadingChannels = true;
      this.$apollo
        .query({
          query: GLNET_CHANNEL_BY_SENSOR_ID,
          variables: {
            id: sensorId
          }
        })
        .then(response => {
          this.channels = response.data.glnet_channel.map(item => {
            return {
              ...item,
              aggregateName:
                item.gid + (item.description ? " " + item.description : "")
            };
          });
        })
        .finally(() => {
          this.loadingChannels = false;
        });
    },

    updatePage(e) {
      this.page = e;
    },

    updateItemsPerPage(e) {
      this.itemsPerPage = e;
    },

    updateSortBy (e) {
      if (e[0] !== undefined) {
        this.sortBy = e[0];
      } else {
        this.sortBy = "";
      }
    },

    updateSortDesc(e) {
      
      if (e[0] !== undefined) {
        this.sortDesc = e[0];
      } else {
        this.sortDesc = "";
      }
    },
    clearAll() {
      this.dateFrom = null;
      this.dateTo = null;
      this.timeFrom = null;
      this.timeTo = null;
      this.isResolved = null;
      this.isAccepted = null;
      this.selectedSensorId = null;
      this.selectedChannelId = null;
      this.selectedCategoryId = null;
      this.filter.dateTimeFrom = null;
      this.filter.dateTimeTo = null;
      this.filter.isResolved = null;
      this.filter.isAccepted = null;
      this.filter.selectedSensorId = null;
      this.filter.selectedChannelId = null;
      this.filter.selectedCategoryId = null;
      this.prevFilter = {...this.filter}
    }
  }
};
</script>

<style scoped lang="scss">
.filter {
  width: 400px;
  height: calc(100vh - 48px);
  position: absolute;
  left: 56px;
  top: 48px;
  z-index: 3;
}
.btn-exp {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 352px;
}
.table__event {
  height: calc(100vh - 120px);
  width: calc(100vw - 400px - 64px);
  right: 0;
  position: absolute;
}
.filter-radio {
  label {
    display: inline-block;
    user-select: none;
    cursor: pointer;
    margin-bottom: 8px;
    padding-left: 25px;
    margin-right: 10px;
  }
  span {
    position: relative;
    font-size: 12px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: -25px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: #d9d9d9;
      transition: 0.3s;
    }
    &:after {
      position: absolute;
      content: "";
      top: 4px;
      left: -21px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #fff;
      transform: scale(0);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
    }
  }
  input:checked + span:after {
    transform: scale(1);
  }
  input:checked + span:before {
    background-color: #1976d2;
  }
}
.event-log-category {
  width: 100%;
  height: calc(100% - 2px);
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: white;
  }
}
</style>
