import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  theme: {
    themes: {
      light: {
        primary: '#1591D8',
        secondary: '#239ac2',
        accent: '#FF6161',
        error: '#FF6161',
        info: '#e2f5f8',
        success: '#CAFFCA',
        warning: '#FFE64D',
      },
    },
  },
}); 
