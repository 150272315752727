<template>
  <div>
    <div class="filter__sensor">
      <v-card height="100%" class="pt-6 px-3" elevation="0">
        <v-autocomplete
          :search-input.sync="searchChannel"
          v-model="selectedChannelId"
          :items="channelList"
          :loading="loadingSearchChannel"
          :item-text="'concatName'"
          item-value="gid"
          label="ID или название канала"
          text
          clearable
          @click:clear="clearSelectedChannel"
          @change="stopSearchChannels"
        ></v-autocomplete>

        <v-autocomplete
          v-model="selectedStatusId"
          :items="statusArr"
          :loading="loadingStatuses"
          label="Статус"
          editable
          item-text="name"
          item-value="code"
          text
          clearable
          @click:clear="clearselectedStatusId"
        ></v-autocomplete>

        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 px-7 py-3 filter__button"
          color="primary"
          :disabled="
            !(
              selectedChannelId !== null ||
              selectedStatusId !== null
            )
          "
          @click="acceptFilter"
        >
          Применить
        </v-btn>
        <v-btn
          @click="clearFilter"
          elevation="0"
          outlined
          class="float-left mt-5 ml-5 px-7 py-3 filter__button"
          :disabled="
            !(
              selectedChannelId !== null ||
              selectedStatusId !== null
            )
          "
        >
          Сбросить
        </v-btn>

      </v-card>
    </div>
     <div class="wrapper">
      <v-data-table
        dense
        height="100%"
        :headers="headers"
        :items="glnet_channel"
        :items-per-page="itemsPerPage"
        :loading="!!loadingAdminChannels"
        class="table__user"
        :item-class="selectColor"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100]
        }"
        :options.sync="options"
        :server-items-length="totalChannels"
        @update:items-per-page="updateItemsPerPage($event)"
        @update:page="updatePage($event)"
        @update:sort-by="updateSortBy($event)"
        @update:sort-desc="updateSortDesc($event)"
      >
        <!-- eslint-disable-next-line -->
        <!-- <template #no-data>
          <div class="mt-10 pt-10">
            <h1>Нет данных</h1>
          </div>
        </template> -->
        <!-- eslint-disable-next-line -->
        <template #item.glnet_channelvalues="{item}">
          {{ item.glnet_channelvalues[0].value | cutDigit }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #item.status="{item}">
          {{ getStatusNameByCode(item.status_id) }}
        </template>
      </v-data-table>
    </div>
    <v-dialog max-width="500" v-model="deleteDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="refresh-channels-list ml-3" right elevation="0" text outlined v-bind="attrs" v-on="on">
          <v-icon small class="mr-2">
            mdi-refresh
          </v-icon>
          Обновить
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Обновление списка каналов
        </v-card-title>
        <v-card-text
          >Вы уверены, что хотите обновить список каналов?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">
            отмена
          </v-btn>
          <v-btn color="primary" text @click="updateChannels()">
            обновить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { baseUrl } from "../../../config";
import axios from "axios";
const GLNET_CHANNEL_TABLE = require("@/graphQL/channel_table.gql");
export default {
  data() {
    return {
      glnet_channel: [],
      // датчик
      searchChannel: null,
      selectedChannelId: null,
      channelList: [],
      loadingSearchChannel: false,
      searchDelay: null,
      canSearchChannels: true,
      // статус
      statusArr: [],
      selectedStatusId: null,
      loadingStatuses: false,
      // фильтры
      filter: {
        selectedChannelId: null,
        selectedStatusId: null
      },
      // таблица
      totalChannels: 0,
      options: {
        sortBy: ['name'],
        sortDesc: [true]
      },
      skipQuery: false,
      page: 1,
      itemsPerPage: 25,
      sortBy: "name",
      sortDesc: true,
      loadingAdminChannels: 0,
      deleteDialog: null,
      headers: [
        { text: "Обозначение", value: "name" },
        { text: "ID", value: "gid" },
        { text: "Значение", value: "glnet_channelvalues" },
        { text: "Ед. изм.", value: "unit" },
        { text: "Статус", value: "status" },
        { text: "Тревога -", value: "alarm_minus" },
        { text: "Внимание -", value: "attention_minus" },
        { text: "Внимание +", value: "attention_plus" },
        { text: "Тревога +", value: "alarm_plus" },
      ]
    };
  },

  filters: {
    cutDigit(val) {
      let float = parseFloat(val);
      if (!isNaN(float)) {
        return val.toFixed(3);
      }
      return "-";
    }
  },

  computed: {
    offset() {
      return (this.page - 1) * this.itemsPerPage;
    }
  },

  watch: {
    searchChannel (newVal) {
      if (!this.canSearchChannels) {
        return
      }
      if (this.searchDelay !== null) {
        clearTimeout(this.searchDelay)
      }
      this.loadingSearchChannel = true
      this.searchDelay = setTimeout(async () => {
        let _res = await this.$apollo.query({
          query: require('@/graphQL/search_channel.gql'),
          variables: {
            searchString: newVal !== null ? newVal : ''
          }
        })
        this.channelList = _res.data.glnet_channel.map(item => {
          return {
            ...item,
            concatName: `ID: ${item.gid}, название: ${item.name}`
          }
        })
        this.loadingSearchChannel = false
      }, 1000)
    }
  },

  apollo: {
    glnet_channel: {
      query: GLNET_CHANNEL_TABLE,
      loadingKey: 'loadingAdminChannels',
      pollInterval() {
        return this.polling;
      },
      variables () {
        let _variables = {
          limit: this.itemsPerPage !== "Все" ? this.itemsPerPage : this.totalChannels,
          offset: this.itemsPerPage !== "Все" ? this.offset : 0
        };
        // сортировка
        if (this.sortBy !== "" && this.sortDesc !== "") {
          let _tmp = this.sortDesc === true ? "desc" : "asc";
          _variables.order_by = {};
          _variables.order_by[this.sortBy] = _tmp;
        }
        _variables.where = {};
        if (this.filter.selectedChannelId !== null) {
          _variables.where.gid = { _eq: this.filter.selectedChannelId };
        }
        if (this.filter.selectedStatusId !== null) {
          _variables.where.status_id = { _eq: this.filter.selectedStatusId };
        }
        return _variables;
      },
      update(data) {
        this.totalChannels = data.glnet_channel_aggregate.aggregate.count
        return data.glnet_channel
      }
    }
  },

  async mounted () {
    this.loadingStatuses = true
    let _status = await this.$apollo.query({
      query: require('@/graphQL/status.gql')
    })
    this.searchChannel = '' // инициация поиска
    this.statusArr = _status.data.glnet_status
    this.loadingStatuses = false
  },

  methods: {
    stopSearchChannels () {
      this.canSearchChannels = false
      setTimeout(() => {
        this.canSearchChannels = true
      }, 300);
    },

    acceptFilter () {
      this.filter.selectedChannelId = this.selectedChannelId
      this.filter.selectedStatusId = this.selectedStatusId
    },

    clearFilter () {
      this.clearSelectedChannel()
      this.clearselectedStatusId()
    },

    clearSelectedChannel () {
      this.selectedChannelId = null
      this.filter.selectedChannelId = null
    },

    clearselectedStatusId () {
      this.selectedStatusId = null
      this.filter.selectedStatusId = null
    },

    getStatusNameByCode (status_id) {
      let status = this.statusArr.find(item => {
        return item.code === status_id
      })
      return status !== undefined ? status.name : '-'
    },

    updateChannels() {
      let url = `${this.$config.VUE_APP_API_BASE_URL}api/v1/glnet/channels/settings/`;
      axios({
        method: "post",
        url: url,
        headers: {
          Authorization: `Bearer ${this.$cookies.get("access_token_glnet")}`
        }
      })
        .then(() => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Запрос отправлен",
            type: "suc" // error warn success common
          });
          this.deleteDialog = false
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Запрос не отправлен",
            type: "err" // error warn success common
          });
          this.deleteDialog = false
        });
    },
    selectColor(item) {
      return "status-" + item.status_id;
      // return 'status-' + item.glnet_channelvalues[0].glnet_status.code
    },

    updatePage(e) {
      this.page = e;
    },

    updateItemsPerPage(e) {
      this.itemsPerPage = e;
    },

    updateSortBy(e) {
      
      if (e[0] !== undefined) {
        this.sortBy = e[0];
      } else {
        this.sortBy = "";
      }
    },

    updateSortDesc(e) {
      
      if (e[0] !== undefined) {
        this.sortDesc = e[0];
      } else {
        this.sortDesc = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  right: 0;
  width: calc(100% - 720px);
}
.table__user {
  height: calc(100vh - 120px);
}
.filter__sensor {
  margin: 0;
  width: 400px;
  position: absolute;
  left: 310px;
  top: 64px;
  height: calc(100vh - 64px);
}
.refresh-channels-list {
  width: 375px;
  position: absolute;
  left: 310px;
  bottom: 20px;
}
</style>
