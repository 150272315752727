<template>
  <v-dialog max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="add-btn add-sensor-btn"
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        ><v-icon left>mdi-plus </v-icon>Добавить датчик</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card max-width="400">
        <v-toolbar color="primary" dark>Добавление датчика</v-toolbar>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedSensor"
                :items="sensors"
                return-object
                text
                :clearable="true"
                :item-text="getItemText"
                :search-input.sync="searchSensorText"
                :loading="loadingSensors"
                dense
                label="Выберите датчик"
              />
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="insertSceneSensor(dialog)">Сохранить</v-btn>
          <v-btn text @click="dialog.value = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "addSensor",
  props: {
    sceneId: null
  },
  data() {
    return {
      loading: false,
      coordinates: [50, 50],
      sensors: [],
      selectedSensor: null,
      searchSensorText: '',
      searchDelay: null,
      loadingSensors: false
    };
  },

  watch: {
    searchSensorText (val) {
      if (this.searchDelay !== null) {
        clearTimeout(this.searchDelay)
      }
      this.loadingSensors = true
      this.searchDelay = setTimeout(() => {
        this.getSensors(val)
      }, 2000)
    }
  },

  mounted() {
    this.getSensors();
  },

  methods: {
    getItemText(item) {
      return `${item.name} (${item.serial_number})`;
    },
    getSensors (search) {
      this.loadingSensors = true
      let _varSearch = ''
      if (search === undefined) {
        _varSearch = search
      } else {
        _varSearch = search
      }
      this.$apollo
        .query({
          query: require("@/graphQL/sensor_all.gql"),
          variables: {
            search_string: _varSearch
          }
        })
        .then(resp => {
          this.sensors = resp.data.glnet_sensor
          this.loadingSensors = false
        }).catch(err => {
          console.error('fail get sensor list', err)
          this.loadingSensors = false
        })
    },
    insertSceneSensor(dialog) {
      this.loading = true;
      
      
      /* eslint-disable no-unused-vars */
      if (!this.selectedSensor || !this.sceneId) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/create_scenesensor.gql"),
          variables: {
            scene_id: this.sceneId,
            sensor_id: this.selectedSensor.id,
            coordinates: this.coordinates
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Датчик добавлен",
            type: "suc" // error warn success common
          });
          setTimeout(() => {
            this.loading = false;
            this.$emit("refreshScenes");
            dialog.value = false;
          }, 1000);
        })
        .catch(error => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Датчик не добавлен",
            type: "err" // error warn success common
          });
          this.loading = false;
          dialog.value = false;
        });
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.add-sensor-btn {
  float: left;
  margin-right: 20px;
}
</style>
