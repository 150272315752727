<template>
  <v-dialog
    v-model="editDialogIsActive"
    @click:outside="close()"
    max-width="600"
  >
    <template>
      <v-card max-width="600">
        <v-toolbar elevation="0" color="primary" dark
          >Редактирование датчика</v-toolbar
        >
        <v-container>
          <v-col cols="12">
            <v-text-field
              class="mb-2"
              v-model="activeItem.name"
              label="Название"
              clearable
            ></v-text-field>
            <v-textarea
              v-model="activeItem.location_desc"
              name="input-7-4"
              label="Местоположение"
              rows="1"
              auto-grow
              class="mb-6 mt-1"
            ></v-textarea>
            <v-autocomplete
              v-model="activeItem.type"
              item-text="name"
              item-value="id"
              :items="sensorTypes"
              dense
              label="Выберите тип"
            ></v-autocomplete>
            <v-text-field
              v-model="activeItem.serial_number"
              label="Введите серийный номер"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="activeItem.model"
              label="Введите модель"
              clearable
            ></v-text-field>
            <v-autocomplete
              v-model="activeItem.channels"
              :items="freeChannels"
              :item-text="getChannelText"
              label="Каналы"
              multiple
              :loading="isChannelsLoading"
              return-object
              small-chips
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-container>
        <v-card-actions>
          <v-btn color="primary" elevation="0" text @click="updateSensor()">
            Сохранить
          </v-btn>
          <v-btn text elevation="0" @click="close()">
            Отмена
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog max-width="500" v-model="deleteDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                right
                elevation="0"
                text
                color="accent"
                v-bind="attrs"
                v-on="on"
              >
                Удалить
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Удаление датчика
              </v-card-title>
              <v-card-text>Вы уверены, что хотите удалить датчик?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="deleteDialog = false">
                  отмена
                </v-btn>
                <v-btn color="accent" text @click="deleteSensor()">
                  удалить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
const UPDATE_SENSOR = require("@/graphQL/update_sensor_mutation.gql");
const FREE_CHANNELS = require("@/graphQL/free_channels.gql");
export default {
  name: "editSensor",
  props: {
    editDialogIsActive: null,
    sensor: null,
    sensorTypes: []
  },
  data() {
    return {
      loading: false,
      activeItem: {
        name: "",
        location_desc: "",
        serial_number: "",
        type: null,
        model: "",
        channels: []
      },
      isChannelsLoading: false,
      freeChannels: [],
      deleteDialog: null
    };
  },
  mounted() {},
  watch: {
    editDialogIsActive(newVal) {
      if (newVal && this.sensor !== null) {
        this.activeItem = {
          name: "",
          location_desc: "",
          serial_number: "",
          type: null,
          channels: []
        };
        this.setActive();
      }
    }
  },
  methods: {
    deleteSensor() {
      if (this.sensor) {
        this.$apollo
          .mutate({
            mutation: require("@/graphQL/delete_sensor.gql"),
            variables: {
              id: this.sensor.id
            }
          })
          .then(() => {
            this.$notify({
              group: "foo",
              title: "Успешно",
              text: "Датчик удален",
              type: "suc" // error warn success common
            });
            this.$emit("sensorRefetch");
            this.close();
          })
          .catch(() => {
            
            this.$notify({
              group: "foo",
              title: "Ошибка",
              text: "Датчик не удален",
              type: "err" // error warn success common
            });
            this.close();
          });
      }
      this.dialog = false;
    },
    remove(data) {
      this.activeItem.channels = this.activeItem.channels.filter(obj => {
        return obj.gid !== data.item.gid;
      });
    },
    close() {
      this.$emit("closeDialog");
    },
    async updateSensor() {
      this.$apollo
        .mutate({
          mutation: UPDATE_SENSOR,
          variables: {
            id: this.activeItem.id,
            name: this.activeItem.name,
            type_id: this.activeItem.type,
            location_desc: this.activeItem.location_desc,
            serial_number: this.activeItem.serial_number,
            model: this.activeItem.model,
            channel_ids: this.activeItem.channels.map(it => it.gid)
          }
        })
        .then(() => {
          this.$notify({
            group: "foo",
            text: "Успешно сохранено",
            type: "suc"
          });
          this.$emit("sensorRefetch");
          this.close();
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            text: "Ошибка сохранения",
            type: "err"
          });
          this.close();
        });
    },
    getChannelText(channel) {
      return channel.name
        ? `${channel.name}: ${channel.gid}`
        : `Без названия: ${channel.gid}`;
    },
    setActive() {
      this.isChannelsLoading = true;
      
      this.activeItem = {
        ...this.sensor,
        type: this.sensor.glnet_sensortype.id,
        serial_number: this.sensor.serial_number,
        channels: this.sensor.glnet_channels.map(it => ({
          gid: it.gid,
          name: it.name
        }))
      };
      console.log('GET')
      this.$apollo
        .query({ query: FREE_CHANNELS, fetchPolicy: 'no-cache', variables: { id: this.sensor.id } })
        .then(data => {
          this.freeChannels = data.data.glnet_channel;
          this.isChannelsLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.edit-scene {
  float: left;
  margin-right: 20px;
}
</style>
