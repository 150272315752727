<template>
  <div>
    <!-- фильтры -->
    <div class="filter">
      <v-card
        class="pt-10 px-6 filter__card"
        height="100%"
        width=""
        elevation="0"
      >
        <!-- Дата и время от -->
        <v-row>
          <v-col>
            <!-- дата от -->
            <v-menu
              ref="dateFromRef"
              v-model="isDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="От"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                locale="ru"
                @click:date="$refs.dateFromRef.save(dateFrom)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <!-- время от -->
            <v-menu
              ref="timeFromRef"
              v-model="isTimeFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeFrom"
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="timeFrom"
                @click:minute="$refs.timeFromRef.save(timeFrom)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Дата и время до -->
        <v-row>
          <v-col>
            <!-- дата от -->
            <v-menu
              ref="dateToRef"
              v-model="isDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="До"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                locale="ru"
                @click:date="$refs.dateToRef.save(dateTo)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <!-- время от -->
            <v-menu
              ref="timeToRef"
              v-model="isTimeTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeTo"
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                format="24hr"
                v-model="timeTo"
                @click:minute="$refs.timeToRef.save(timeTo)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 px-7 py-3 filter__button"
          @click="acceptFilter"
          color="primary"
          :disabled="dateFrom === null && dateTo === null"
        >
          Применить
        </v-btn>
        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 px-7 ml-5 py-3 filter__button"
          @click="clearDateFilter"
          :disabled="!(dateFrom !== null || dateTo !== null || timeFrom !== null || timeTo !== null)"
        >
          Сбросить
        </v-btn>
        <v-btn
          elevation="0"
          outlined
          class="filter__button btn-exp ml-6"
          @click="exportData"
          :loading="loadingCreateReportLogs"
          color="primary"
          v-if="userRole !== 'operator'"
        >
          Экспорт
        </v-btn>
      </v-card>
    </div>
    <!-- таблица -->
    <div class="wrapper">
      <v-data-table
        dense
        item-key="id"
        class="table__log"
        height="100%"
        :headers="headers"
        :items="logEvent"
        :loading="loadingLogs"
        :options.sync="options"
        :server-items-length="totalLogs"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100]
        }"
        @update:items-per-page="updateItemsPerPage($event)"
        @update:page="updatePage($event)"
        @update:sort-by="updateSortBy($event)"
        @update:sort-desc="updateSortDesc($event)"
      >

        <!-- eslint-disable-next-line -->
        <template #no-data>
          <div class="mt-10 pt-10">
            <h1>Нет данных</h1>
          </div>
        </template>
        
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('DD MMM YYYY HH:mm:ss') }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.description="{ item }">
          {{ item.description | cutDigitLogs }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import appStore from '@/store/appStore'
import moment from 'moment'
moment.locale('ru')
const GLNET_LOG = require('../graphQL/log.gql')
const reg = /\s\d+.\d+/gm

export default {
  data: () => ({
    moment: moment,
    dateFrom: null,
    dateTo: null,
    timeFrom: null,
    timeTo: null,
    isDateFrom: false,
    isDateTo: false,
    isTimeFrom: false,
    isTimeTo: false,
    page: 1,
    itemsPerPage: 25,
    options: null,
    totalLogs: 0,
    sortBy: '',
    sortDesc: '',
    menuDown: false,
    logEvent: [],
    loadingLogs: false,
    headers: [
      { text: 'ID', align: 'start', value: 'id', width: '10%' },
      { text: 'Дата и время', value: 'created_at', width: '20%' },
      { text: 'Событие', value: 'description', sortable: false }
    ],
    filter: {
      dateTimeFrom: null,
      dateTimeTo: null
    }
  }),

  computed: {
    offset () {
      return (this.page - 1) * this.itemsPerPage
    },

    isAuthorized () {
      return appStore.getters.VALUE('isAuthorized')
    },

    loadingCreateReportLogs () {
      return appStore.getters.VALUE('loadingCreateReportLogs')
    },

    userRole () {
      return appStore.getters.VALUE('userRole')
    }
  },

  filters: {
    cutDigitLogs (val) {
      let newValue = val.match(reg) // ищем подстроку с значением
      if (newValue === null) { // если не найдено
        return val // ничего не делаем
      } else { // если найдено
        newValue = parseFloat(newValue).toFixed(3) // обрезаем до 3 знаков
        return val.replace(reg, (' ' + newValue)) // заменяем эту же часть на укороченную
      }
    }
  },

  apollo: {
    logEvent: {
      query: GLNET_LOG,
      fetchPolicy: 'no-cache',
      debounce: 100,
      skip () {return !this.isAuthorized },
      variables () {
        this.loadingLogs = true
        let _variables = {
          limit: this.itemsPerPage !== 'Все' ? this.itemsPerPage : this.totalLogs,
          offset: this.itemsPerPage !== 'Все' ? this.offset : 0
        }
        // сортировка
        if (this.sortBy !== '' && this.sortDesc !== '') {
          let _tmp = this.sortDesc === true ? 'desc' : 'asc'
          _variables.order_by = {}
          _variables.order_by[this.sortBy] = _tmp
        }
        // даты
        if (this.filter.dateTimeFrom !== null) { // если даты нет, то будет использовано значение по умолчанию(прописано в query GLNET_LOG)
          _variables.date_time_from = this.filter.dateTimeFrom
        }
        if (this.filter.dateTimeTo !== null) {
          _variables.date_time_to = this.filter.dateTimeTo
        }
        // console.log('_variables', _variables)
        return _variables
      },
      update (data) {
        this.totalLogs = data.glnet_log_aggregate.aggregate.count
        this.loadingLogs = false
        return data.glnet_log
      }
    }
  },

  methods: {
    exportData () {
      // this.loadingCreateReport = true
      appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportLogs', value: true})
      setTimeout(async () => { // задержка на то чтобы 
        console.time('generate report')
        let req = []
        let timeoutsArr = []
        let _variables = {
          limit: this.itemsPerPage !== 'Все' ? this.itemsPerPage : this.totalLogs,
          offset: this.itemsPerPage !== 'Все' ? this.offset : 0
        }
        // сортировка
        if (this.sortBy !== '' && this.sortDesc !== '') {
          let _tmp = this.sortDesc === true ? 'desc' : 'asc'
          _variables.order_by = {}
          _variables.order_by[this.sortBy] = _tmp
        }
        // даты
        if (this.filter.dateTimeFrom !== null) { // если даты нет, то будет использовано значение по умолчанию(прописано в query GLNET_LOG)
          _variables.date_time_from = this.filter.dateTimeFrom
        }
        if (this.filter.dateTimeTo !== null) {
          _variables.date_time_to = this.filter.dateTimeTo
        }

        let count = await this.$apollo.query({
          query: GLNET_LOG,
          variables: _variables
        })

        count = count.data.glnet_log_aggregate.aggregate.count

        for (let i = 0; i * 300 < count; i++) {
          req.push(
            new Promise((resolve, reject) => { // обертка для таймаута(задержка между запросами)
              let timer = setTimeout(() => {
                if (appStore.getters.VALUE('breakReportLogs')) {
                  appStore.dispatch('SET_VALUE', {key: 'breakReportLogs', value: false})
                  appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportLogs', value: false})
                  timeoutsArr.forEach(item => {
                    clearTimeout(item)
                  })
                  throw new Error('break create report, cause logout')
                }
                this.$apollo.query({
                  query: GLNET_LOG,
                  variables: {
                    ..._variables,
                    limit: 300,
                    offset: i * 300
                  }
                }).then(response => {
                  resolve(response)
                }).catch(requerstErr => {
                  // throw new Error(reject(requerstErr))
                  reject(requerstErr)
                })
              }, i * 3 * 1000) // промеждуток между запросами 3сек
              timeoutsArr.push(timer)
            })
          )
        }
        Promise.all(req).then(resps => {
          let preparedData = []
          resps.forEach(resp => {
            preparedData.push(
              resp.data.glnet_log.map(item => {
                return {
                  'ID': item.id,
                  'Дата и время': moment(item.created_at).format('DD-MM-YYYY_hh:mm'),
                  'Событие': item.description
                }
              })
            )
          })
          this.createCsvMix(preparedData, 'logs' + moment().format('DD-MM-YYYY_hh:mm'), 'loadingCreateReportLogs')
          // this.loadingCreateReport = false
          appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportLogs', value: false})
        }).catch(err => {
          console.error('fail generate report', err)
          this.$notify({
            group: 'foo',
            title: 'Ошибка экспорта',
            text: 'Не удалось создать отчет\n' + JSON.stringify(err),
            type: 'err' // error warn success common
          })
          // this.loadingCreateReport = false
          appStore.dispatch('SET_VALUE', {key: 'loadingCreateReportLogs', value: false})
        })
      }, 100)
    },

    clearDateFilter () {
      this.dateFrom = null
      this.dateTo = null
      this.timeFrom = null
      this.timeTo = null
      this.filter.dateTimeFrom = null
      this.filter.dateTimeTo = null
    },
  
    acceptFilter () {
      // console.log('this.dateFrom', this.dateFrom)
      // console.log('this.timeFrom', this.timeFrom)
      if (this.dateFrom !== null) {
        let _dateTimeFromString = this.dateFrom
        if (this.timeFrom !== null) {
          _dateTimeFromString += 'T' + this.timeFrom
        }
        this.filter.dateTimeFrom = moment(_dateTimeFromString).utc().format()
      }
      if (this.dateTo !== null) {
        let _dateTimeToString = this.dateTo
        if (this.timeTo !== null) {
          _dateTimeToString += 'T' + this.timeTo
        }
        this.filter.dateTimeTo = moment(_dateTimeToString).utc().format()
      }
    },

    updatePage (e) {
      this.page = e
    },

    updateItemsPerPage (e) {
      this.itemsPerPage = e
    },

    updateSortBy (e) {
      // console.log('updateSortBy', e[0])
      if (e[0] !== undefined) {
        this.sortBy = e[0]
      } else {
        this.sortBy = ''
      }
    },

    updateSortDesc (e) {
      // console.log('updateSortDesc', e[0])
      if (e[0] !== undefined) {
        this.sortDesc = e[0]
      } else {
        this.sortDesc = ''
      }
    }

  }
}
</script>

<style scoped>
.wrapper{
  position: absolute;
  right: 0;
  width: calc(100% - 460px);
}
.filter{
  margin: 0;
  width: 400px;
  position: absolute;
  left: 56px;
  top: 48px;
  height: calc(100vh - 48px);
  z-index: 1;
}
.filter__button {
  border-radius: 5px;
}
.btn-exp {
  position: absolute;
  left: 0;
  bottom: 17px;
  width: 87%;
}
.table__log{
  height: calc(100vh - 130px);
}
</style>
