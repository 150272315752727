import vueRouter from 'vue-router'
import Vue from 'vue'
import SceneVue from '../components/Scene/SceneVue'
import ChartsVue from '../components/Charts/ChartsVue'
import LogsVue from '../components/LogsVue'
import LoginVue from '../components/LoginVue'
import EventLog from '../components/EventLogVue'
import Sensor from '../components/SensorVue'
import AdminSceneVue from '../components/Admin/AdminSceneVue'
import AdminTableVue from '../components/Admin/AdminTableVue'

Vue.use(vueRouter)

const router = new vueRouter({
  mode: 'hash',
  routes: [
    {
      name: 'Мониторинг',
      path: '/',
      component: SceneVue,
      params: {
        mode: 'view'
      }
    },
    {
      name: 'Архив',
      path: '/charts',
      component: ChartsVue
    },
    {
      name: 'Логи',
      path: '/logs',
      component: LogsVue
    },
    {
      name: 'Вход',
      path: '/login',
      component: LoginVue
    },
    {
      name: 'Журнал событий',
      path: '/event-log',
      component: EventLog
    },
    {
      name: 'Датчики',
      path: '/sensor',
      component: Sensor
    },
    {
      path: '/admin',
      redirect: '/admin/rodos'
    },
    {
      name: 'Администрирование',
      path: '/admin/:id',
      component: AdminTableVue,
      beforeEnter: (to, from, next) => {
        if (Vue.$cookies.get('user-role_glnet') == 'admin') {
          next();
        } else {
          next('/')
        }
      },
      children: [
        {
          name: 'Редактированое сцены',
          path: 'scenes',
          component: AdminSceneVue,
          params: {mode: 'edit'}
        }
      ]
    }
  ]
})

export default router