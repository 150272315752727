<template>
  <div class="wrapper">
    <v-col class="input__config">
      <v-text-field
        v-model="frequency"
        label="Частота запроса данных, сек"
        text
      ></v-text-field>
    </v-col>
    <v-col class="input__config">
      <v-text-field
        v-model="infoAddress"
        label="Адрес запроса сведений о каналах"
        text
      ></v-text-field>
    </v-col>
    <v-col class="input__config">
      <v-text-field
        v-model="dataAddress"
        label="Адрес запроса данных каналов"
        text
      ></v-text-field>
    </v-col>
    <v-col class="input__config">
      <v-text-field
        v-model="trafficData"
        label="Адрес TrafficData"
        text
      ></v-text-field>
    </v-col>
    <div class="btn-config">
      <v-btn
      class="mr-6"
      color="primary"
        @click="save"
        :disabled="!allowSave"
        outlined
      >Сохранить</v-btn>
      <v-btn  
        outlined
      >Отмена</v-btn>
    </div>
  </div>
</template>

<script>
const GLNET_STATUS_MUTATION = require('@/graphQL/status_mutation.gql');
const GLNET_CONFIG = require('@/graphQL/config.gql');

export default {
  data: () => ({
    frequency: '',
    infoAddress: '',
    dataAddress: '',
    allowSave: true,
    trafficData: ''
  }),

  apollo: {
    settings: {
      query: GLNET_CONFIG,
      fetchPolicy: 'no-cache',
      update (data) {
        this.frequency = data.settings.find(it => it.key === 'GL4_FR').value
        this.infoAddress = data.settings.find(it => it.key === 'GL4_CH_SETTINGS').value
        this.dataAddress = data.settings.find(it => it.key === 'GL4_CH_DATA').value
        this.trafficData = data.settings.find(it => it.key === 'TRAFFICDATA_URL').value
        return data.settings
      }
    }
  },
  methods: {
    async save () {
      this.allowSave = false;
      const data = {
        'GL4_FR': this.frequency,
        'GL4_CH_SETTINGS': this.infoAddress,
        'GL4_CH_DATA': this.dataAddress,
        'TRAFFICDATA_URL': this.trafficData
      }

      let arrPromises = [];

      for(let key of Object.keys(data)) {
        const promise = this.$apollo.mutate({
          mutation: GLNET_STATUS_MUTATION,
          variables: {
            settings_key: key,
            value: data[key]
          }
        })

        arrPromises.push(promise);
      }

      Promise.all(arrPromises)
      .then(() => {
        this.$notify({
          group: 'foo',
          title: 'Успешно',
          text: 'Успешно сохранено',
          type: 'suc'
        });
      })
      .catch(error => {
        
        this.$notify({
          group: 'foo',
          title: 'Внутренняя ошибка',
          text: 'Не удалось сохранить: ' + JSON.stringify(error),
          type: 'err'
        })
      })
      .finally(() => this.allowSave = true);
    },
  }
}
</script>

<style scoped>
  .wrapper{
    position: absolute;
    right: 0;
    width: calc(100% - 310px);
    height: calc(100vh - 48px);
    padding-left: 60px;
    padding-top: 20px;
  }
  .input__config{
    max-width: 600px;
  }
  .btn-config{
    position: absolute;
    left: 72px;
    bottom: 25px;
  }
</style>