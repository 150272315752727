<template>
  <v-dialog max-width="320" persistent v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="add-btn delete-scene"
        color="accent"
        outlined
        v-bind="attrs"
        v-on="on"
        ><v-icon
        left
      >mdi-delete
      </v-icon>Удалить сцену</v-btn
      >
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Удаление сцены
      </v-card-title>
      <v-card-text>Вы уверены, что хотите удалить сцену?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          отмена
        </v-btn>
        <v-btn color="accent" :loading="loading" text @click="deleteScene(dialog)">
          удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteScene",
  props: {
    sceneId: null
  },
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  methods: {
    deleteScene() {
      this.loading = true
      /* eslint-disable no-unused-vars */
      if (this.sceneId) {
        this.$apollo
          .mutate({
            mutation: require("@/graphQL/delete_scene.gql"),
            variables: {
              id: this.sceneId
            }
          })
          .then(_ => {
            this.$notify({
              group: "foo",
              title: "Успешно",
              text: "Cцена удалена",
              type: "suc" // error warn success common
            });
            setTimeout(() => {
              this.loading = false
              this.$emit('refreshScenes', -1)
              this.dialog = false;
            }, 1000)
          })
          .catch(error => {
            
            this.$notify({
              group: "foo",
              title: "Ошибка",
              text: "Сцена не удалена",
              type: "err" // error warn success common
            });
            this.loading = false
            this.dialog = false;
          });
      }
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.delete-scene {
  float: right;
}
</style>
