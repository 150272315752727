<template>
  <div class="wrapper">
    <template>
      <v-data-table
        height="100%"
        :headers="headers"
        :items="users"
        class="table__user"
        :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text':'Количество строк'
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <template>
            <div class="text-center">
              <v-dialog
                v-model="dialog"
                width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  elevation="1"
                    v-bind="attrs"
                    v-on="on"
                    color="accent"
                  >
                    Изменить{{item.actions}}
                  </v-btn>
                </template>
                <v-card class="px-11 pb-12">
                  <v-card-title class="text-h5 justify-center mb-11">
                    Редактирование пользователя
                  </v-card-title>
                    <v-text-field
                      v-model="usernameInput"
                      filled
                      label="Имя пользователя"
                      clearable
                    ></v-text-field>
                    <v-autocomplete
                      v-model="value"
                      :items="items"
                      dense
                      filled
                      label="Выберите роль"
                    ></v-autocomplete>
                    <v-text-field
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show3 ? 'text' : 'password'"
                      name="input-10-2"
                      label="Пароль"
                      hint="Не менее 8 символов"
                      value="111111111111"
                      class="input-group--focused"
                      @click:append="show3 = !show3"
                      filled
                    ></v-text-field>
                    <v-text-field
                      :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show4 ? 'text' : 'password'"
                      name="input-10-2"
                      label="Пароль"
                      hint="Не менее 8 символов"
                      value="111111111111"
                      class="input-group--focused mb-16"
                      @click:append="show4 = !show4"
                      filled
                    ></v-text-field>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    elevation="1"
                      class="mr-4 underline"
                      text
                      @click="dialog = false"
                      width="30%"
                    >
                      Удалить
                    </v-btn>
                    <v-btn
                    elevation="1"
                      class="mr-4"
                      @click="dialog = false"
                      width="30%"
                    >
                      Отмена
                    </v-btn>
                    <v-btn
                    elevation="1"
                      @click="dialog = false"
                      width="30%"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </template>
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <div class="mt-10 pt-10">
            <h1>Нет данных</h1>
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      usernameInput: '',
      items: ['foo', 'bar', 'fizz', 'buzz'],
      value: null,
      show3: false,
      show4: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Минимум 8 символов',
      },
      headers: [
        {
          text: '№',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Username', value: 'username' },
        { text: 'Роль', value: 'role' },
        { text: 'Пароль', value: 'password' },
        { text: 'Дата создания', value: 'date' },
        { text: '', value: 'actions', sortable: false },
      ],
      users: [
          {
            id: 1,
            username: 'akrtyeouyrgnbhbh',
            role: 'Оператор',
            password: '*******************',
            date: '14:12 07.06.2021',
          },
      ]    
    }),
    methods: {
      openEdit(){
        this.dialog = true
      }
    }
  }
</script>
<style scoped>
  .wrapper{
    position: absolute;
    right: 0;
    width: calc(100% - 310px);
  }
  .table__user{
    height: calc(100vh - 100px);
  }
  .dialog__user{
    z-index: 2;
  }
</style>