var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper chart-table-wrapper"},[(_vm.filterData.selectedChannelId !== null)?_c('div',{},[_c('v-data-table',{staticClass:"elevation-1 table__chart",attrs:{"dense":"","item-key":"id","height":"100%","width":"100%","headers":_vm.headers,"items":_vm.tableValues,"loading":_vm.loadingValues,"options":_vm.options,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalValues,"footer-props":{
        showFirstLastPage: true,
        'items-per-page-text': 'Количество строк',
        'items-per-page-options': [25, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":function($event){return _vm.updateItemsPerPage($event)},"update:page":function($event){return _vm.updatePage($event)},"update:sort-by":function($event){return _vm.updateSortBy($event)},"update:sort-desc":function($event){return _vm.updateSortDesc($event)}},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cutDigit")(item.value))+" ")]}},{key:"item.time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.time).format("DD MMM YYYY HH:mm:ss"))+" ")]}},{key:"item.glnet_status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"chart-table-status",style:({'background-color': _vm.getStatusColor(item.glnet_status)})},[_c('p',[_vm._v(" "+_vm._s(item.glnet_status.name)+" ")])])]}}],null,false,175235753)})],1):_c('p',{staticClass:"display-2 font-weight-light text--disabled text-center pt-10"},[_vm._v(" Выберите фильтры и нажмите \"применить\" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }