import { onLogout, onLogin } from '@/vue-apollo'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import appStore from '../store/appStore'
// import { baseUrl } from '../config'

export default {
  methods: {
    async refreshTokenMix () {
      // console.log('refresh')
      let url = `${this.$config.VUE_APP_API_BASE_URL}api/v1/auth/token/refresh/`
      let token = this.$cookies.get('refresh_token_glnet')
      if (token === null) {
        this.logoutMix()
        return
      }
      let data = { refresh: token }
      try {
        let response = await axios.post(url, data)
        await this.setTokensMix(response)
        appStore.dispatch('SET_VALUE', {key: 'isAuthorized', value: true})
      } catch (error) {
        console.error('fail update token', error)
        this.logoutMix()
      }
    },

    async logoutMix () {
      try {
        await onLogout(this.$apollo)
        appStore.dispatch('SET_VALUE', {key: 'isAuthorized', value: false})
        this.$cookies.remove('refresh_token_glnet')
        this.$cookies.remove('access_token_glnet')
        this.$eventHub.$emit('stop-refresh-timer')
        appStore.dispatch('SET_VALUE', {key: 'lastRouteBeforeLogout', value: this.$route.fullPath})
        appStore.dispatch('SET_VALUE', {key: 'userId', value: null})
        this.$router.push('/login')
      } catch (error) {
        console.error('fail to logout', error)
        this.$notify({
          group: 'foo',
          title: 'Внутренняя ошибка',
          text: 'Не удалось выйти из системы. ' + JSON.stringify(error),
          type: 'err' // error warn success common
        })
      }
    },

    async setTokensMix (response) {
      let refresh = response.data.refresh
      let access = response.data.access
      let decodedRefresh = jwt_decode(refresh)
      let decodedAccess = jwt_decode(access)

      // this.$cookies.set('user-role_glnet', decodedAccess., new Date(decodedRefresh.exp * 1000))
      this.$cookies.set('refresh_token_glnet', refresh, new Date(decodedRefresh.exp * 1000))
      this.$cookies.set('access_token_glnet', access, new Date(decodedAccess.exp * 1000))
      
      this.$cookies.set('user-role_glnet', decodedAccess['https://hasura.io/jwt/claims']['x-hasura-default-role'], new Date(decodedAccess.exp * 1000)) // для apollo. НЕ ИСПОЛЬЗОВАТЬ В КОМПОНЕНТАХ(не раективно)
      
      appStore.dispatch('SET_VALUE', {key: 'userId', value: decodedAccess.user_id})
      appStore.dispatch('SET_VALUE', {key: 'userName', value: decodedAccess.user_name})
      appStore.dispatch('SET_VALUE', {key: 'userRole', value: decodedAccess['https://hasura.io/jwt/claims']['x-hasura-default-role']}) // для использования в компонентах
      await onLogin(this.$apollo, access)
    }
  }
}