import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthorized: false,
    userId: null,
    userName: '',
    userRole: '',
    lastRouteBeforeLogout: '',
    //archive data
    archiveSensorSelectedId: null,
    archiveChannelSelectedId: null,
    archiveDateFrom: null,
    archiveDateTo: null,
    archiveTimeFrom: null,
    archiveTimeTo: null,
    loadingCreateReportEventLog: false,
    loadingCreateReportArchive: false,
    loadingCreateReportLogs: false,
    breakReportEventLog: false,
    breakReportArchive: false,
    breakReportLogs: false
  },

  mutations: {
    SET_VALUE (state, keyVal) {
      state[keyVal.key] = keyVal.value
    }
  },

  actions: {
    SET_VALUE ({commit}, keyVal) {
      commit('SET_VALUE', keyVal)
    }
  },

  getters: {
    VALUE: state => key => {
      return state[key]
    }
  }
})