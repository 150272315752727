<template>
    <div class="card px-2">
      <v-list>
        <v-list-item-group v-model="activeSceneItem">
          <v-list-item class="admin-menu-list-item" link :to="'/admin/rodos'" color="primary">
            <v-list-item-icon>
              <v-icon medium color="primary">
                mdi-alarm-light-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="scene-list-item-title">
              <v-list-item-title>Rodos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item class="admin-menu-list-item" link :to="'/admin/sensor'" color="primary">
            <v-list-item-icon>
              <v-icon color="primary" medium>
                mdi-select-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="scene-list-item-title">
              <v-list-item-title>Датчики</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item class="admin-menu-list-item" link :to="'/admin/canals'" color="primary">
            <v-list-item-icon>
              <v-icon color="primary" medium>
                mdi-cable-data
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="scene-list-item-title">
              <v-list-item-title>Каналы</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item  class="admin-menu-list-item" link :to="'/admin/scenes'" color="primary">
            <v-list-item-icon>
              <v-icon color="primary" medium>
                mdi-image-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="scene-list-item-title">
              <v-list-item-title>Мониторинг</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item class="admin-menu-list-item" link :to="'/admin/config'" color="primary">
            <v-list-item-icon>
              <v-icon color="primary" medium>
                mdi-cog
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="scene-list-item-title">
              <v-list-item-title>Конфигурации</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
</template>

<script>
  export default {
    data: () => ({
      activeSceneItem: null
    }),
    computed: {
      searchRoute () {
        return this.$route.path
      }
    },
    methods: {
      checkAll(e) {
        this.$emit("check-all", e);
      }
    },
    mounted () {

    }
  }
</script>

<style scoped>
  .card{
    margin: 0;
    width: 250px;
    position: absolute;
    left: 56px;
    top: 64px;
    height: calc(100vh - 48px);
  }
  .btn-other__check{
    width: 226px;
    position: absolute;
    bottom: 86px;
  }
  .btn-other__bottom{
    width: 226px;
    position: absolute;
    bottom: 17px;
  }
  .gl-net-btn{
    font-size: 11px;
  }
  .admin-menu-list-item {
  padding-left: 20px;
  border-radius: 4px !important;
}
.admin-menu-list-item::before {
  border-radius: 4px !important;
}
</style>