<template>
  <v-app>
    <v-app-bar dense app color="primary" elevation="0" v-if="loginVisible">
      <HeaderVue />
    </v-app-bar>
    <div class="d-flex mt-12 app-content">
      <MenuVue v-if="loginVisible" />
      <v-container fluid class="pl-14">
        <transition name="fade" mode="out-in"> <router-view /></transition>
      </v-container>
    </div>

    <AlertVue />
    <notifications group="foo" classes="notify" />
  </v-app>
</template>

<script>
import AlertVue from "./components/AlertVue.vue";
import HeaderVue from "./components/Commons/HeaderVue.vue";
import MenuVue from "./components/Commons/MenuVue.vue";

export default {
  components: { HeaderVue, MenuVue, AlertVue },
  name: "App",

  // data: () => ({
  // }),

  computed: {
    loginVisible() {
      return this.$route.path !== "/login";
    }
  },

  async created() {
    this.$eventHub.$on("start-refresh-timer", this.startTimer);
    this.$eventHub.$on("stop-refresh-timer", this.stopTimer);
    let refresh = this.$cookies.get("refresh_token_glnet");
    if (refresh !== null) {
      await this.refreshTokenMix();
      this.startTimer();
      this.$eventHub.$emit("refetch-unresolved-events"); // запрос эвентов для хедера
    } else {
      this.logoutMix();
    }
  },

  methods: {
    startTimer() {
      
      this.refreshInterval = setInterval(() => {
        this.refreshTokenMix();
      }, 4 * 60 * 1000);
    },

    stopTimer() {
      
      clearInterval(this.refreshInterval);
    }
  }
};
</script>

<style lang="scss">
body::-webkit-scrollbar {
  display: none;
}
body {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app-content {
  height: 100%;
}
.primary-text-color {
  color: #212121;
}
.default-primary-color {
  background: #1591D8;
}

.vue-notification-template {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: black;
  background: white;
  border-radius: 4px;
  &.err {
    background: white !important;
    border-left: 7px solid #ff5252;
  }
  &.suc {
    background-color: white !important;
    border-left: 7px solid #CAFFCA;
  }
  &.war {
    background-color: white !important;
    border-left: 7px solid #FFE64D;
  }
  &.com{
    background: white !important; // grey lighten-2
    border-left: 7px solid #1591D8; // grey darken-1
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 223, 223);
}
.v-data-table-header {
  th {
    span {
      font-size: 14px;
    }
  }
}
.v-data-table__wrapper {
  th {
    font-size: 14px!important;
  }
}
.table__user {
  .v-data-table__wrapper > table > tbody > tr > td, .v-data-table__wrapper > table > tbody > tr > th, .v-data-table__wrapper > table > thead > tr > td, .v-data-table__wrapper > table > thead > tr > th, .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 8px!important;
  }
}
</style>
