<template>
  <v-dialog transition="dialog-bottom-transition" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="add-btn create-scene"
        color="primary"
        outlined
        v-bind="attrs"
        v-on="on"
        ><v-icon left>mdi-plus </v-icon>Добавить сцену</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card max-width="400">
        <v-toolbar color="primary" dark>Создание сцены</v-toolbar>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-text-field
                label="Название"
                v-model="newScene.name"
                placeholder="Введите название сцены"
              ></v-text-field>
              <v-file-input
                accept="image/*"
                prepend-icon="mdi-image"
                label="Выберите изображение"
                v-model="imageSource"
                @change="onImageChange"
              ></v-file-input>
              <v-img v-if="imageSource" :src="imagePreviewURL"></v-img>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text :loading="loading" @click="insertScene(dialog)"
            >Сохранить</v-btn
          >
          <v-btn text @click="dialog.value = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
// import { baseUrl } from "../../config";
import axios from "axios";
export default {
  name: "createScene",
  props: {
    mainScene: null
  },
  data() {
    return {
      loading: false,
      imageSource: null,
      newScene: {
        name: "",
        coordinates: [50, 50],
        objectId: 1,
        image: ""
      },
      imagePreviewURL: ""
    };
  },

  methods: {
    onImageChange() {
      if (this.imageSource) {
        this.imagePreviewURL = URL.createObjectURL(this.imageSource);
      }
    },
    async uploadImage() {
      let fd = new FormData();
      if (this.imageSource) {
        let url = `${this.$config.VUE_APP_API_BASE_URL}api/v1/glnet/scenes/upload/`;
        fd.append("image", this.imageSource);
        
        return axios({
          method: "post",
          url: url,
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$cookies.get("access_token_glnet")}`
          }
        })
          .then(resp => {
            
            this.newScene.image = resp.data.url;
            return true;
          })
          .catch(() => {
            
            return false;
          });
      }
    },
    async insertScene(dialog) {
      this.loading = true;
      let imageUploaded = await this.uploadImage();
      if (this.mainScene === null || !imageUploaded) {
        
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Новая сцена не создана",
          type: "err" // error warn success common
        });
        return;
      }
      
      this.newScene.objectId = this.mainScene.id;
      /* eslint-disable no-unused-vars */
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/create_scene.gql"),
          variables: {
            name: this.newScene.name,
            label_position: this.newScene.labelPosition,
            coordinates: this.newScene.coordinates,
            object_id: this.newScene.objectId,
            image: this.newScene.image,
            index: this.mainScene.glnet_scenes.length + 1
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Новая сцена создана",
            type: "suc" // error warn success common
          });
          setTimeout(() => {
            this.loading = false;
            this.$emit("refreshScenes", -1);
            dialog.value = false;
          }, 1000);
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Новая сцена не создана",
            type: "err" // error warn success common
          });
          this.loading = false;
          dialog.value = false;
        });
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.create-scene {
  float: left;
  margin-right: 20px;
}
</style>
