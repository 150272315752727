<template>
  <div>
    <div class="filter__sensor">
      <v-card height="100%" class="pt-6 px-3" elevation="0">
        <v-autocomplete
          :search-input.sync="searchSensor"
          v-model="selectedSensorId"
          :items="sensorList"
          :loading="loadingSearchSensor"
          :item-text="'name'"
          item-value="id"
          label="Наименование"
          text
          clearable
          @click:clear="clearSelectedSensor"
        ></v-autocomplete>

        <v-autocomplete
          v-model="selectedStatus"
          :items="sensorStatus"
          :loading="loadingStatuses"
          label="Статус"
          editable
          item-text="name"
          item-value="code"
          text
          clearable
          @click:clear="clearSelectedStatus"
        ></v-autocomplete>

        <v-autocomplete
          v-model="selectedSensorTypeId"
          item-text="name"
          item-value="id"
          :items="sensorTypes"
          text
          label="Тип датчика"
          clearable
          @click:clear="clearSelectedSensorTypeId"
        ></v-autocomplete>
        <v-btn
          elevation="0"
          outlined
          class="float-left mt-5 px-7 py-3 filter__button"
          color="primary"
          :disabled="
            selectedSensorId === null &&
              selectedStatus === null &&
              selectedSensorTypeId === null
          "
          @click="acceptFilter"
        >
          Применить
        </v-btn>
        <v-btn
          @click="clearFilter()"
          elevation="0"
          outlined
          class="float-left mt-5 ml-5 px-7 py-3 filter__button"
          :disabled="
            !(
              selectedSensorId !== null ||
              selectedStatus !== null ||
              selectedSensorTypeId !== null
            )
          "
        >
          Сбросить
        </v-btn>
        <v-btn class="btn-create-sensor" elevation="0" outlined @click="openCreateDialog()"
          ><v-icon left>mdi-plus </v-icon>создать датчик</v-btn
        >
      </v-card>
    </div>

    <div class="wrapper">
      <template>
        <v-data-table
          height="100%"
          :headers="headers"
          :items="sensors"
          item-key="id"
          :loading="loadingTable"
          show-expand
          class="table__user"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': 'Количество строк',
            'items-per-page-options': [25, 50, 100, 'Все']
          }"
          :item-class="selectColor"
          :options.sync="options"
          :server-items-length="totalSensors"
          @update:items-per-page="updateItemsPerPage($event)"
          @update:page="updatePage($event)"
          @update:sort-by="updateSortBy($event)"
          @update:sort-desc="updateSortDesc($event)"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pr-0">
              <v-simple-table class="mt-1 mb-6 elevation-1 subtable-sensor">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <!-- <th class="text-left">
                        ID
                      </th> -->
                      <th class="text-left">
                        Канал
                      </th>
                      <th class="text-left">
                        Значение
                      </th>
                      <th class="text-left">
                        Ед. изм.
                      </th>
                      <th class="text-left">
                        Описание
                      </th>
                      <th class="text-left">
                        Статус
                      </th>
                      <th class="text-left">
                        Дата
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="channel in item.channels"
                      :key="channel.id"
                      :style="{'background-color': getColorByStatusMix(channel.status.code), 'color': (channel.status.code === 2) ? 'white' : 'black'}"
                    >
                      <td>{{ channel.name }}</td>
                      <td>{{ channel.value }}</td>
                      <td>{{ channel.unit }}</td>
                      <td>{{ channel.description }}</td>
                      <td>{{ channel.status.name }}</td>
                      <td>{{ channel.time }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.status.name ="{ item }">
            {{ getStatusNameByCode(item.status_id) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :color="(item.status_id === 5 || item.status_id === 4 || item.status_id === 3 || item.status_id === 1 || item.status_id === null) ? 'black' : 'white'" outlined @click="openEditDialog(item)"
              ><v-icon left>mdi-pencil </v-icon>Редактировать</v-btn
            >
          </template>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <div class="mt-10 pt-10">
              <h1>Нет данных</h1>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <edit-sensor
      :editDialogIsActive="editDialogIsActive"
      @closeDialog="editDialogIsActive = false"
      @sensorRefetch="sensorRefetch"
      :sensor="editingSensor"
      :sensorTypes="sensorTypes"
    ></edit-sensor>
    <create-sensor
      :createDialogIsActive="createDialogIsActive"
      @closeDialog="createDialogIsActive = false"
      @sensorRefetch="sensorRefetch"
      :sensorTypes="sensorTypes"
    ></create-sensor>
  </div>
</template>

<script>
const GLNET_SENSORTYPE = require("@/graphQL/sensortype.gql");
const GLNET_SENSOR_TABLE = require("@/graphQL/sensor_table.gql");
const GLNET_SENSOR_SEARCH = require("@/graphQL/sensor_search.gql");
const GLNET_STATUS = require("@/graphQL/status.gql");

import moment from "moment";
import EditSensor from "./EditSensor.vue";
import CreateSensor from "./CreateSensor.vue";

export default {
  components: {
    EditSensor,
    CreateSensor,
  },
  data: () => ({
    sensorList: [],
    selectedSensorTypeId: null,
    searchSensor: "",
    loadingSearchSensor: false,
    selectedSensorId: null,
    selectedStatus: null,
    loadingStatuses: false,
    dialog: false,
    nameInput: "",
    value: null,
    canalVisible: 0,
    count: 0,
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    totalSensors: 0,
    loadingTable: false,
    itemsPerPage: 25,
    page: 1,
    editDialogIsActive: false,
    editingSensor: null,
    createDialogIsActive: false,
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'ID', align: 'start', value: 'id', width: '6%'},
      { text: 'Обозначение', value: 'name', width: '11%' },
      { text: 'Тип', value: 'type_name' },
      { text: 'Модель', value: 'model', sortable: false },
      { text: 'Серийный №', value: 'serial_number', width: '11%' },
      { text: 'Местоположение', value: 'location_desc', width: '15%' },
      { text: 'Статус', value: 'status.name', width: '6%' },
      { text: 'Дата, Время', value: 'time', sortable: false, width: '13%' },
      { text: "", value: "actions", sortable: false, width: '150px' }
    ],
    sensorTypes: [],
    freeChannels: [],
    isChannelsLoaded: true,
    sensorStatus: [],
    filterObj: {
      selectedStatus: null,
      selectedSensorId: null,
      selectedSensorTypeId: null
    },
    sortBy: "id",
    sortDesc: true
  }),

  computed: {
    offset() {
      return (this.page - 1) * this.itemsPerPage;
    }
  },

  watch: {
    searchSensor(newVal) {
      this.loadingSearchSensor = true;
      if (this.inputDelay !== null) {
        clearTimeout(this.inputDelay);
      }
      this.inputDelay = setTimeout(async () => {
        // console.log('search with "' + (newVal !== null ? newVal : '') + '"')
        try {
          let res = await this.$apollo.query({
            query: GLNET_SENSOR_SEARCH,
            update(data) {
              return data.glnet_sensor;
            },
            variables() {
              return {
                search: newVal !== null ? newVal : ""
              };
            }
          });
          this.sensorList = res.data.glnet_sensor;
          this.loadingSearchSensor = false;
        } catch (error) {
          this.sensorList = [];
          this.loadingSearchSensor = false;
        }
      }, 3000);
    }
  },

  apollo: {
    sensorTypes: {
      query: GLNET_SENSORTYPE,
      update: data => data.glnet_sensortype
    },
    sensors: {
      query: GLNET_SENSOR_TABLE,
      fetchPolicy: "no-cache",
      throttle: 50,
      pollInterval: 2000,
      update(data) {
        this.loadingTable = false;
        this.totalSensors = data.glnet_sensor_aggregate.aggregate.count;
        return this.parseSensors(data.glnet_sensor);
        // return data.glnet_sensor
      },
      variables() {
        this.loadingTable = true;
        let _variables = {
          limit:
            this.itemsPerPage !== "Все" ? this.itemsPerPage : this.totalSensors,
          offset: this.itemsPerPage !== "Все" ? this.offset : 0
        };
        // сортировка
        if (this.sortBy !== '' && this.sortDesc !== '') {
          let _tmp = this.sortDesc === true ? 'desc' : 'asc'
          _variables.order_by = {}

          let _sortBy = ''
          if (this.sortBy === 'status.name') {
            _sortBy = 'status_id'
          } else {
            _sortBy = this.sortBy
          }
          if (this.sortBy === 'glnet_sensortype.name') {
            _sortBy = 'type_id'
          }
          _variables.order_by[_sortBy] = _tmp
        }
        _variables.where = {};
        if (this.filterObj.selectedSensorId !== null) {
          _variables.where.id = { _eq: this.filterObj.selectedSensorId };
        }
        if (this.filterObj.selectedStatus !== null) {
          _variables.where.status_id = { _eq: this.filterObj.selectedStatus };
        }
        if (this.filterObj.selectedSensorTypeId !== null) {
          _variables.where.glnet_sensortype = {
            id: { _eq: this.filterObj.selectedSensorTypeId }
          };
        }
        return _variables;
      }
    }
  },

  async mounted() {
    this.loadingStatuses = true;
    let res = await this.$apollo.query({
      query: GLNET_STATUS,
      loadingKey: "loadingStatuses",
      update: data => data.glnet_status
    });
    this.sensorStatus = res.data.glnet_status;
    this.loadingStatuses = false;
  },

  methods: {
    getStatusNameByCode (status_id) {
      let status = this.sensorStatus.find(item => {
        return item.code === status_id
      })
      return status !== undefined ? status.name : '-'
    },

    openCreateDialog() {
      this.createDialogIsActive = true;
    },
    openEditDialog(item) {
      this.editingSensor = item;
      this.editDialogIsActive = true;
    },
    sensorRefetch() {
      this.loadingTable = true;
      this.sensors = [];
      this.$apollo.queries.sensors.refetch();
    },
    parseSensors (data) {
      data.forEach(sensor => {
        sensor.channels = [] // поле для таблицы в таблице
        // let channelStatuses = []
        let channelDates = []
        sensor.glnet_channels.forEach(channel => {
          let channelForTable = {}
          channelForTable.name = channel.name
          let _val = channel.glnet_channelvalues[0].value
          _val = parseFloat(_val)
          if (!isNaN(_val)) {
            channelForTable.value = _val.toFixed(3)
          } else {
            channelForTable.value = ''
          }
          channelForTable.time = moment(channel.glnet_channelvalues[0].time).isValid() ? moment(channel.glnet_channelvalues[0].time).format('DD MMM YYYY HH:mm:ss') : channel.glnet_channelvalues[0].time
          channelForTable.description = channel.description
          channelForTable.unit = channel.unit
          channelForTable.status = {
            name: this.getStatusNameByCode(channel.status_id),
            code: channel.status_id
          }

          // channelStatuses.push(channel.glnet_channelvalues[0].glnet_status)
          channelDates.push(channel.glnet_channelvalues[0].time)
          sensor.channels.push(channelForTable)
        })
        if (channelDates.length > 0) {
          sensor.time = moment(this.compateDates(channelDates)).format('DD MMM YYYY HH:mm:ss')
        } else {
          sensor.time = '-'
        }
        // sensor.status = this.compareStatusesMix(channelStatuses)
        sensor.type_name = this.getTypeNameById(sensor.type_id).name
      })
      // console.log('new data', data)
      return data
    },

    getTypeNameById(typeId) {
      if (this.sensorTypes.length) {
        return this.sensorTypes.find(item => {
          return item.id === typeId;
        });
      }
      return "";
    },

    compateDates(datesArr) {
      let maxdate = null;
      datesArr.forEach(item => {
        if (!maxdate) {
          maxdate = item;
        }
        if (moment(item).isAfter(moment(maxdate))) {
          maxdate = item;
        }
      });
      return maxdate;
    },

    acceptFilter() {
      this.filterObj.selectedSensorId = this.selectedSensorId;
      this.filterObj.selectedStatus = this.selectedStatus;
      this.filterObj.selectedSensorTypeId = this.selectedSensorTypeId;
    },

    clearFilter() {
      this.clearSelectedSensor();
      this.clearSelectedStatus();
      this.clearSelectedSensorTypeId();
    },

    clearSelectedSensor() {
      this.selectedSensorId = null;
      this.$set(this.filterObj, 'selectedSensorId', null)
      // this.filterObj.selectedSensorId = null;
    },

    clearSelectedStatus() {
      this.selectedStatus = null;
      this.filterObj.selectedStatus = null;
    },

    clearSelectedSensorTypeId() {
      this.selectedSensorTypeId = null;
      this.filterObj.selectedSensorTypeId = null;
    },

    updatePage(e) {
      this.page = e;
    },

    updateItemsPerPage(e) {
      this.itemsPerPage = e;
    },

    updateSortBy(e) {
      
      if (e[0] !== undefined) {
        this.sortBy = e[0];
      } else {
        this.sortBy = "";
      }
    },

    updateSortDesc(e) {
      
      if (e[0] !== undefined) {
        this.sortDesc = e[0];
      } else {
        this.sortDesc = "";
      }
    },

    selectColor(item) {
      return "status-" + item.status_id;
    },
    selectChannelColor(item) {
      return "status-" + item.code;
    },
    getChannelText(channel) {
      return channel.name
        ? `${channel.name}: ${channel.gid}`
        : `Без названия: ${channel.gid}`;
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  right: 0;
  width: calc(100% - 720px);
}
.table__user {
  height: calc(100vh - 120px);
}
.filter__sensor {
  margin: 0;
  width: 400px;
  position: absolute;
  left: 310px;
  top: 64px;
  height: calc(100vh - 64px);
}
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-data-footer {
  border-top: 0 !important;
}
.v-data-table__expand-icon {
  color: white !important;
}
// .status-1 { эти стили в sensorVue
// }
.btn-create-sensor {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  left: 20px;
}
</style>
