<template>
  <div class="wrapper">
    <template>
      <!-- таблица и редактирование -->
      <v-data-table
        dense
        height="100%"
        :headers="headers"
        :items="settings"
        class="table__user"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100]
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <template>
            <div class="text-center">
              <v-btn
                outlined
                :disabled="!item.is_active"
                text
                @click="sendRequest(item.value)"
              >
                Проверить
              </v-btn>
              <v-btn
                elevation="0"
                class="my-1 ml-4"
                @click="setActive(item, 'edit')"
                outlined
              >
                <v-icon left>mdi-pencil</v-icon>
                Редактировать
              </v-btn>
            </div>
          </template>
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <div
            :style="{ backgroundColor: getColor(item.is_active), 'color': item.is_active ? 'black': 'white' }"
            class="isActiveCol"
          >
            <p>{{ getIsActive(item.is_active) }}</p>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <div class="mt-10 pt-10">
            <h1>Нет данных</h1>
          </div>
        </template>
      </v-data-table>
      <!-- кнопка создать -->
      <v-btn
        elevation="0"
        outlined
        @click="setActive({}, 'create')"
        width="400px"
      >
        <v-icon left>mdi-plus </v-icon>
        создать Rodos
      </v-btn>

      <!-- редактирование и создание -->
      <v-dialog v-model="dialog" width="600" :retain-focus="false">
        <v-card class="d-flex flex-column" max-width="600">
          <v-toolbar elevation="0" color="primary" dark>
            {{ mode === 'edit' ? 'Редактирование' : 'Создание' }} RODOS
          </v-toolbar>
          <v-container>
            <!-- URL -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                class="mb-2"
                v-model="activeItem.value"
                label="URL-адрес"
                clearable
              ></v-text-field>
            </v-col>

            <!-- Описание -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                class="mb-2"
                v-model="activeItem.description"
                label="Описание"
                clearable
              ></v-text-field>
            </v-col>

            <!-- Активен -->
            <v-col cols="12" class="pt-0">
              <div class="filter-radio primary-text-color">
                <div class="create-sensor-title">Активен</div>
                <div class="input-wrap">
                  <label>
                    <input
                      name="isActive"
                      type="radio"
                      hidden
                      :value="true"
                      v-model="activeItem.is_active"
                    />
                    <span>Да</span>
                  </label>
                  <label>
                    <input
                      name="isActive"
                      type="radio"
                      hidden
                      :value="false"
                      v-model="activeItem.is_active"
                    />
                    <span>Нет</span>
                  </label>
                </div>
              </div>
            </v-col>

            <!-- Проверить -->
            <v-col>
              <div class="create-sensor-title">Проверить</div>
              <v-btn
                :disabled="activeItem.value.length === 0"
                elevation="0"
                outlined
                class="float-left mt-1 px-7 py-3 filter__button"
                color="primary"
                @click.stop="sendRequest(activeItem.value)"
              >
                Проверить
              </v-btn>
            </v-col>
          </v-container>

          <v-spacer></v-spacer>
          <v-card-actions class="mt-2">
            <v-btn color="primary" text elevation="0" @click="save()">
              {{ mode === 'edit' ? 'Сохранить' : 'Создать' }}
            </v-btn>
            <v-btn text elevation="0" @click="dialog = false">
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="mode === 'edit'"
              color="error"
              elevation="0"
              text
              @click="deleteSettings"
              :loading="loadingDeleteSettings"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    mode: 'edit',
    dialog: false,
    loadingDeleteSettings: false,
    usernameInput: "",
    items: ["foo", "bar", "fizz", "buzz"],
    value: null,
    show3: false,
    show4: false,
    activeItem: {
      id: null,
      value: "",
      is_active: false,
      description: ""
    },
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Минимум 8 символов"
    },
    // таблица
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "URL-адрес", value: "value" },
      { text: "Активен", value: "is_active" },
      { text: "Описание", value: "description" },
      { text: "", value: "actions", sortable: false },
    ]
  }),
  props: {
    settings: Array
  },

  methods: {
    getColor(isActive) {
      return isActive ? "#CAFFCA" : "#FF6161";
    },
    getIsActive(isActive) {
      return isActive ? "Да" : "Нет";
    },
    sendRequest(url) {
      axios
        .get(url)
        .then(() => {
          this.$notify({
            group: "foo",
            text: "Успешно отправлено",
            type: "suc"
          });
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Не удалось отправить запрос",
            type: "err"
          });
        });
    },

    setActive(item, mode) {
      this.mode = mode
      if (mode === 'edit') {
        this.activeItem = { ...item };
        if (this.activeItem.is_active === null) {
          this.activeItem.is_active = false
        }
      } else {
        this.activeItem = {
          id: null,
          value: "",
          is_active: false
        }
      }
      this.dialog = true
    },

    async save() {
      if (this.mode === 'edit') {
        this.dialog = false;
        this.$emit("save-item", this.activeItem);
      } else {
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphQL/create_rodos.gql'),
            variables: {
              settings_value: this.activeItem.value,
              settings_description: this.activeItem.description,
              settings_is_active: this.activeItem.is_active
            }
          })
          this.$emit('refetch-rodos')
          this.dialog = false;
        } catch (error) {
          console.error('fail create', error)
          this.dialog = false;
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Не удалось отправить создать \n" + JSON.stringify(error),
            type: "err"
          });
        }
      }
    },

    async deleteSettings () {
      this.loadingDeleteSettings = true
      try {
        this.$apollo.mutate({
          mutation: require('@/graphQL/delete_rodos.gql'),
          variables: {
            settings_id: this.activeItem.id
          }
        })
        this.$emit('refetch-rodos')
        this.dialog = false
      } catch (error) {
        console.error('fail delete settings', error)
      } finally {
        this.loadingDeleteSettings = false
      }
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  right: 0;
  width: calc(100% - 310px);
}
.table__user {
  height: calc(100vh - 120px);
}
.tabs__rodos {
  width: 300px;
  border: 3px solid gray;
  border-radius: 5px;
}
.tab__rodos {
  width: 50%;
}
.background {
  background: #828282 !important;
  color: #ffffff !important;
}
.underline {
  text-decoration: underline;
}
.btn-check {
  max-width: 150px;
}
.isActiveCol {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10px;
  border-radius: 4px;
  p {
    margin: 0;
    // color: white;
  }
}
.filter-radio {
  label {
    display: inline-block;
    user-select: none;
    cursor: pointer;
    margin-bottom: 8px;
    padding-left: 25px;
    margin-right: 10px;
  }
  span {
    position: relative;
    font-size: 12px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: -25px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: #d9d9d9;
      transition: 0.3s;
    }
    &:after {
      position: absolute;
      content: "";
      top: 4px;
      left: -21px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #fff;
      transform: scale(0);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
    }
  }
  input:checked + span:after {
    transform: scale(1);
  }
  input:checked + span:before {
    background-color: #1976d2;
  }
}
.create-sensor-title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
</style>
