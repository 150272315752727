<template>
  <SceneVue mode="edit" />
</template>

<script>
import SceneVue from '../Scene/SceneVue.vue'
export default {
  components: {
    SceneVue
  }
}
</script>

<style>

</style>