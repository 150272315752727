<template>
  <div>
    <div
      class="dot"
      @click="openCloseCard"
      :id="hotspot.id"
      :style="hotspotDotStyle"
    >
    </div>
    <div :style="hotspotLabelStyle" class="dot-lable-background" :id="hotspot.id + 'label'">
      <p class="dot-label">{{ hotspot.inputData.sceneName }} <span><v-icon small class="dot-label-icon">mdi-drag-vertical</v-icon></span></p></div>
    <div class="card" :id="hotspot.id + 'card'" :style="hotspotCardStyle">
      <v-card width="230" elevation="1">
        <div class="card-drag-icon-wrap">
          <v-icon clas="card-drag-icon">
            mdi-drag
          </v-icon>
        </div>
        <div class="card-status-color" :style="getStatusColorBG"></div>
        <v-img
          class="card-image"
          height="110"
          :src="getHotspotCardImage()"
        ></v-img>
        <v-card-title class="card-text">{{
          hotspot.inputData.sceneName
        }}</v-card-title>
      </v-card>
    </div>
    <svg
      width="3000"
      height="3000"
      :id="hotspot.id + 'svg'"
      :style="hotspotSVGStyle"
    >
      <line
        :x1="dotLeft + 15 + 1000"
        :y1="dotTop + 15 + 1000"
        :x2="cardLeft + 25 + 1000"
        :y2="cardTop + 25 + 1000"
        stroke="grey"
        stroke-width="1"
        stroke-opacity="1"
      />
    </svg>
  </div>
</template>
<script>
// import { domainUrl } from "../../config";
let Draggable = require("draggable");
export default {
  name: "hotspotScene",
  props: {
    sceneWidth: null,
    sceneHeight: null,
    hotspot: {
      type: Object
    },
    mode: {
      type: String,
      default: "view"
    }
  },
  data() {
    return {
      dotTop: 0,
      dotLeft: 0,
      cardTop: 0,
      cardLeft: 0,
      labelTop: 0,
      labelLeft: 0,
      isCardOpened: false
    };
  },
  computed: {
    hotspotDotStyle() {
      return {
        backgroundColor: this.getColorByStatusMix(this.hotspot.status),
        top: this.dotTop + "px",
        left: this.dotLeft + "px"
      };
    },
    hotspotSVGStyle() {
      let styles = {};
      if (this.mode === "edit") {
        styles.visibility = "visible";
        styles.opacity = "1";
      }
      return styles;
    },
    hotspotCardStyle() {
      let styles = {
        top: this.cardTop + "px",
        left: this.cardLeft + "px"
      };
      if (this.mode === "edit") {
        styles.visibility = "visible";
        styles.opacity = "1";
      }
      return styles;
    },
    hotspotLabelStyle() {
      let styles = {
        top: this.labelTop + "px",
        left: this.labelLeft + "px"
      };
      return styles;
    },
    getStatusColorBG() {
      return {
        backgroundColor: this.getColorByStatusMix(this.hotspot.status)
      };
    }
  },
  mounted() {
    // console.log("DOT", this.hotspot)
    this.mount();
  },
  methods: {
    openCloseCard() {
      if (this.mode !== "edit") {
        this.isCardOpened = !this.isCardOpened;
        localStorage["cardVisible" + this.hotspot.id] = this.isCardOpened;
        this.changeVisibleCard();
      }
    },
    changeVisibleCard() {
      let elem = document.getElementById(String(this.hotspot.id + "card"));
      let elemSvg = document.getElementById(String(this.hotspot.id + "svg"));
      if (this.isCardOpened) {
        elem.style.visibility = "visible";
        elem.style.opacity = "1";
        setTimeout(() => {
          elemSvg.style.visibility = "visible";
          elemSvg.style.opacity = "1";
        }, 150);
      } else {
        elem.style.visibility = "hidden";
        elem.style.opacity = "0";
        elemSvg.style.visibility = "hidden";
        elemSvg.style.opacity = "0";
      }
    },
    mount() {
      // console.log('MOUNT')
      this.dotTop = this.persentInPixels(this.hotspot.coordinates.top, "y");
      this.dotLeft = this.persentInPixels(this.hotspot.coordinates.left, "x");
      this.cardTop = localStorage[
        this.hotspot.id + "hotspotMainSceneCardPositionTop"
      ]
        ? parseInt(
            localStorage[this.hotspot.id + "hotspotMainSceneCardPositionTop"]
          )
        : Math.max(this.dotTop - 250, 0);
      this.cardLeft = localStorage[
        this.hotspot.id + "hotspotMainSceneCardPositionLeft"
      ]
        ? parseInt(
            localStorage[this.hotspot.id + "hotspotMainSceneCardPositionLeft"]
          )
        : Math.max(this.dotLeft - 250, 0);
      this.labelTop = localStorage[
        this.hotspot.id + "hotspotMainSceneLabelPositionTop"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.id +
                "hotspotMainSceneLabelPositionTop"
            ]
          )
        : Math.max(this.dotTop + 40, 0);
      if (Math.abs(this.labelTop - this.dotTop) > 50) {
        this.labelTop = this.dotTop + 50
      }
      this.labelLeft = localStorage[
        this.hotspot.id + "hotspotMainSceneLabelPositionLeft"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.id +
                "hotspotMainSceneLabelPositionLeft"
            ]
          )
        : Math.max(this.dotLeft - 50, 0);
      if (Math.abs(this.labelLeft - this.dotLeft) > 130) {
        this.labelLeft = this.dotLeft - 130
      }
      if (this.mode === "edit") {
        this.setDraggableElem("");
      }
      this.setDraggableElem("card");
      this.setDraggableElem("label");
      if (this.mode !== "edit") {
        setTimeout(() => {
          this.isCardOpened =
            localStorage["cardVisible" + this.hotspot.id] !== undefined
              ? JSON.parse(localStorage["cardVisible" + this.hotspot.id])
              : false;
          this.changeVisibleCard();
        }, 500);
      }
    },

    getHotspotCardImage() {
      return this.$config.VUE_APP_API_DOMAIN + this.hotspot.inputData.image;
    },
    setDraggableElem(type) {
      let elem = document.getElementById(String(this.hotspot.id + type));
      this.draggableElem = new Draggable(elem, {
        grid: 0,
        setCursor: true,
        limit:
          type == ""
            ? {
                x: [0, this.sceneWidth - 15],
                y: [0, this.sceneHeight - 15]
              }
            : type == "card"
            ? {
                x: [0, this.sceneWidth - 150],
                y: [0, this.sceneHeight - 150]
              }
            : {
                x: [this.dotLeft - 130, this.dotLeft + 50],
                y: [this.dotTop - 50, this.dotTop + 50]
              },
        onDrag:
          type == ""
            ? this.onDragDot
            : type == "card"
            ? this.onDragCard
            : this.onDragLabel,
        onDragEnd:
          type == ""
            ? this.onDragDotEnd
            : type == "card"
            ? this.onDragCardEnd
            : this.onDragLabelEnd,
        setPosition: true
      });
      let oldElem = document.getElementById(String(this.hotspot.id + type));
      let parent = elem.parentElement;
      oldElem.remove();
      setTimeout(() => {
        parent.appendChild(elem);
      }, 100);
    },
    onDragDot(elem, x, y) {
      this.dotTop = y;
      this.dotLeft = x;
    },
    onDragCard(elem, x, y) {
      this.cardTop = y;
      this.cardLeft = x;
    },
    onDragLabel(elem, x, y) {
      this.labelTop = y;
      this.labelLeft = x;
    },
    onDragDotEnd(elem, x, y) {
      this.dotTop = y;
      this.dotLeft = x;
      this.updateDotPosition();
      this.onDragLabelEnd(null, x-50, y+40)
    },
    onDragCardEnd(elem, x, y) {
      this.cardTop = y;
      this.cardLeft = x;
      localStorage[this.hotspot.id + "hotspotMainSceneCardPositionTop"] = y;
      localStorage[this.hotspot.id + "hotspotMainSceneCardPositionLeft"] = x;
    },
    onDragLabelEnd(elem, x, y) {
      this.labelTop = y;
      this.labelLeft = x;
      localStorage[
        this.hotspot.id + "hotspotMainSceneLabelPositionTop"
      ] = y;
      localStorage[
        this.hotspot.id + "hotspotMainSceneLabelPositionLeft"
      ] = x;
      this.setDraggableElem('label')
    },
    pixelsInPersent(pixels, axis) {
      // пиксели в %
      if (axis === "x") {
        return (pixels / this.sceneWidth) * 100;
      } else {
        return (pixels / this.sceneHeight) * 100;
      }
    },
    persentInPixels(persent, axis) {
      // % в px
      if (axis === "x") {
        return (this.sceneWidth / 100) * persent;
      } else {
        return (this.sceneHeight / 100) * persent;
      }
    },
    updateDotPosition() {
      /* eslint-disable no-unused-vars */
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/update_scene_coordinates.gql"),
          variables: {
            id: parseInt(this.hotspot.id),
            coordinates: [
              this.pixelsInPersent(this.dotTop, "y"),
              this.pixelsInPersent(this.dotLeft, "x")
            ]
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Координаты сцены сохранены",
            type: "suc" // error warn success common
          });
        })
        .catch(error => {
          
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Координаты сцены не сохранены",
            type: "err" // error warn success common
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.dot {
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  border: 4px solid rgba(255, 255, 255, 0.4);
  z-index: 2;
  cursor: pointer;
}
.card {
  top: 50px;
  left: 50px;
  position: absolute;
  z-index: 3;
  text-align: left;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  background-color: white;
}
.card-image {
  border-radius: 4px 4px 0 0;
}
.card-text {
  padding: 10px;
  font-weight: normal;
}
.card-status-color {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 4px 4px;
}
.card-drag-icon-wrap {
  position: absolute;
  border-radius: 4px;
  top: 7px;
  right: 7px;
  z-index: 2;
  background-color: rgba(255, 255, 255, $alpha: 0.8);
}
.dot-drag-icon {
  display: block;
  margin-top: 3px;
}
.dot-label {
  display: inline-block;
  width: auto;
  font-size: 14pt;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 1px 1px 0 rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 300px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 0 10px 0 10px;
  max-width: 140px;
}
.dot-lable-background {
  width: 140px;
  text-align: center;
  height: 20px;
  position: absolute;
  left: -50px;
  top: 30px;
}
svg {
  position: absolute;
  z-index: 1;
  top: -1000px;
  left: -1000px;
  pointer-events: none;
  visibility: hidden;
}
</style>
