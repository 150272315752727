var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter__sensor"},[_c('v-card',{staticClass:"pt-6 px-3",attrs:{"height":"100%","elevation":"0"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.searchSensor,"items":_vm.sensorList,"loading":_vm.loadingSearchSensor,"item-text":'name',"item-value":"id","label":"Наименование","text":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchSensor=$event},"update:search-input":function($event){_vm.searchSensor=$event},"click:clear":_vm.clearSelectedSensor},model:{value:(_vm.selectedSensorId),callback:function ($$v) {_vm.selectedSensorId=$$v},expression:"selectedSensorId"}}),_c('v-autocomplete',{attrs:{"items":_vm.sensorStatus,"loading":_vm.loadingStatuses,"label":"Статус","editable":"","item-text":"name","item-value":"code","text":"","clearable":""},on:{"click:clear":_vm.clearSelectedStatus},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","items":_vm.sensorTypes,"text":"","label":"Тип датчика","clearable":""},on:{"click:clear":_vm.clearSelectedSensorTypeId},model:{value:(_vm.selectedSensorTypeId),callback:function ($$v) {_vm.selectedSensorTypeId=$$v},expression:"selectedSensorTypeId"}}),_c('v-btn',{staticClass:"float-left mt-5 px-7 py-3 filter__button",attrs:{"elevation":"0","outlined":"","color":"primary","disabled":_vm.selectedSensorId === null &&
            _vm.selectedStatus === null &&
            _vm.selectedSensorTypeId === null},on:{"click":_vm.acceptFilter}},[_vm._v(" Применить ")]),_c('v-btn',{staticClass:"float-left mt-5 ml-5 px-7 py-3 filter__button",attrs:{"elevation":"0","outlined":"","disabled":!(
            _vm.selectedSensorId !== null ||
            _vm.selectedStatus !== null ||
            _vm.selectedSensorTypeId !== null
          )},on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v(" Сбросить ")]),_c('v-btn',{staticClass:"btn-create-sensor",attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.openCreateDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus ")]),_vm._v("создать датчик")],1)],1)],1),_c('div',{staticClass:"wrapper"},[[_c('v-data-table',{staticClass:"table__user",attrs:{"height":"100%","headers":_vm.headers,"items":_vm.sensors,"item-key":"id","loading":_vm.loadingTable,"show-expand":"","footer-props":{
          showFirstLastPage: true,
          'items-per-page-text': 'Количество строк',
          'items-per-page-options': [25, 50, 100, 'Все']
        },"item-class":_vm.selectColor,"options":_vm.options,"server-items-length":_vm.totalSensors},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":function($event){return _vm.updateItemsPerPage($event)},"update:page":function($event){return _vm.updatePage($event)},"update:sort-by":function($event){return _vm.updateSortBy($event)},"update:sort-desc":function($event){return _vm.updateSortDesc($event)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"pr-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"mt-1 mb-6 elevation-1 subtable-sensor",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Канал ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Значение ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Ед. изм. ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Описание ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Статус ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Дата ")])])]),_c('tbody',_vm._l((item.channels),function(channel){return _c('tr',{key:channel.id,style:({'background-color': _vm.getColorByStatusMix(channel.status.code), 'color': (channel.status.code === 2) ? 'white' : 'black'})},[_c('td',[_vm._v(_vm._s(channel.name))]),_c('td',[_vm._v(_vm._s(channel.value))]),_c('td',[_vm._v(_vm._s(channel.unit))]),_c('td',[_vm._v(_vm._s(channel.description))]),_c('td',[_vm._v(_vm._s(channel.status.name))]),_c('td',[_vm._v(_vm._s(channel.time))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.status.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusNameByCode(item.status_id))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":(item.status_id === 5 || item.status_id === 4 || item.status_id === 3 || item.status_id === 1 || item.status_id === null) ? 'black' : 'white',"outlined":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil ")]),_vm._v("Редактировать")],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-10 pt-10"},[_c('h1',[_vm._v("Нет данных")])])]},proxy:true}],null,true)})]],2),_c('edit-sensor',{attrs:{"editDialogIsActive":_vm.editDialogIsActive,"sensor":_vm.editingSensor,"sensorTypes":_vm.sensorTypes},on:{"closeDialog":function($event){_vm.editDialogIsActive = false},"sensorRefetch":_vm.sensorRefetch}}),_c('create-sensor',{attrs:{"createDialogIsActive":_vm.createDialogIsActive,"sensorTypes":_vm.sensorTypes},on:{"closeDialog":function($event){_vm.createDialogIsActive = false},"sensorRefetch":_vm.sensorRefetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }