<template>
  <div class="center__cadr">
      <img src="@/assets/img/monsol-logo.png" alt="" class="monsol-logo">
    <v-card
      class="mx-auto pa-10 login-card"
      max-width="500px"
    >
      <v-card-title>
        <div class="glnet-logo__wrapper">
          <img src="@/assets/img/logo-dark.svg" alt="" class="glnet-logo">
        </div>
      </v-card-title>
      <v-form
        v-model="valid"
        class="pa-5"
      >
        <v-text-field
          v-model="login"
          counter
          :rules="loginRules"
          label="Логин"
          class="mx-auto mb-10"
          required
          @keypress.enter="loginUser"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Пароль"
          class="mx-auto mb-10"
          counter
          @click:append="show1 = !show1"
          @keypress.enter="loginUser"
        ></v-text-field>
        <v-btn
          outlined
          elevation="0"
          @click="loginUser"
          :disabled="!valid"
          :loading="loadingLogin"
          color="primary"
          class="mr-4"
          width="100%"
          height="50px"
        >Войти
        </v-btn>
      </v-form>
      <v-card-actions>
        <div class="company-description">
          <span>&#171;ООО МОНСОЛ РУС&#187;. 2021 Все права защищены</span>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import appStore from '../store/appStore'
import axios from 'axios'
// import { baseUrl } from '../config'

export default {
  data () {
    return {
      loadingLogin: false,
      valid: true,
      login: '',
      loginRules: [
        v => !!v || 'Введите логин',
        v => (v && v.length >= 5) || 'Минимум 5 символов',
      ],
      show1: false,
      password: '',
      rules: {
        required: value => !!value || 'Введите пароль.',
        min: v => v.length >= 5 || 'Минимум 5 символов',
      },
    }
  },

  methods: {
    validate () {
      this.$refs.form.validate()
    },

    async loginUser () {
      if (!this.valid) {
        return
      }
      this.loadingLogin = true
      let url = `${this.$config.VUE_APP_API_BASE_URL}api/v1/auth/login/`
      let data = {
        username: this.login,
        password: this.password
      }
      axios.post(url, data).then(async (response) => {
        await this.setTokensMix(response)
        appStore.dispatch('SET_VALUE', {key: 'isAuthorized', value: true})
        this.$eventHub.$emit('start-refresh-timer')
        this.$eventHub.$emit('refetch-unresolved-events') // запрос эвентов для хедера
        let lastRouteBeforeLogout = appStore.getters.VALUE('lastRouteBeforeLogout')
        // console.log('lastRouteBeforeLogout', lastRouteBeforeLogout)
        if (lastRouteBeforeLogout !== '' && lastRouteBeforeLogout.indexOf('admin') === -1 && lastRouteBeforeLogout.indexOf('login') === -1) { // если последний песть сохранен и это не админский путь(позже добавить проверку прав)
          this.$router.push(lastRouteBeforeLogout)
        } else {
          this.$router.push('/')
        }
      }).catch(err => {
        console.error('fail login', JSON.parse(JSON.stringify(err)))
        appStore.dispatch('SET_VALUE', {key: 'isAuthorized', value: false})
        this.$notify({
          group: 'foo',
          title: 'Внутренняя ошибка',
          text: 'Не удалось войти в систему. ' + err.response.data.detail ? err.response.data.detail : '',
          type: 'err' // error warn success common
        })
      }).finally(() => {
        this.loadingLogin = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-card {
  position: relative;
  z-index: 5;
}
.center__cadr{
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  /* height: calc(100vh - 80px); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.monsol-logo {
  position: absolute;
  // margin-left: 3%;
  width: 90%;
}
.glnet-logo {
  width: 70px;
}
.glnet-logo__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.company-description {
  position: absolute;
  margin-left: -20px;
  margin-bottom: -40px;
  width: 290px;
  span {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }
}
</style>