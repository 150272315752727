<template>
  <v-dialog
    v-model="createDialogIsActive"
    @click:outside="close()"
    max-width="600"
  >
    <template>
      <v-card max-width="600">
        <v-toolbar elevation="0" color="primary" dark
          >Создание датчика</v-toolbar
        >
        <v-container>
          <v-col cols="12">
            <v-text-field
              class="mb-2"
              v-model="newItem.name"
              label="Название"
              clearable
            ></v-text-field>
            <v-textarea
              v-model="newItem.location_desc"
              name="input-7-4"
              label="Местоположение"
              rows="1"
              auto-grow
              class="mb-6 mt-1"
            ></v-textarea>
            <v-autocomplete
              v-model="newItem.type"
              item-text="name"
              item-value="id"
              :items="sensorTypes"
              dense
              label="Выберите тип"
            ></v-autocomplete>
            <v-text-field
              v-model="newItem.serial_number"
              label="Введите серийный номер"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="newItem.model"
              label="Введите модель"
              clearable
            ></v-text-field>
            <v-autocomplete
              v-model="newItem.channels"
              :items="freeChannels"
              :item-text="getChannelText"
              label="Каналы"
              multiple
              :loading="isChannelsLoading"
              return-object
              small-chips
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-container>
        <v-card-actions>
          <v-btn color="primary" elevation="0" text @click="createSensor()">
            Сохранить
          </v-btn>
          <v-btn text elevation="0" @click="close()">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
const CREATE_SENSOR = require("@/graphQL/create_sensor_mutation.gql");
const APPEND_CHANNELS_TO_SENSOR = require("@/graphQL/append_channels_to_sensor.gql");
const FREE_CHANNELS = require("@/graphQL/free_channels.gql");
export default {
  name: "createSensor",
  props: {
    createDialogIsActive: null,
    sensorTypes: []
  },
  data() {
    return {
      loading: false,
      newItem: {
        name: "",
        location_desc: "",
        serial_number: "",
        type: null,
        channels: []
      },
      isChannelsLoading: false,
      freeChannels: []
    };
  },
  mounted() {},
  watch: {
    createDialogIsActive(newVal) {
      if (newVal) {
        this.newItem = {
          name: "",
          location_desc: "",
          serial_number: "",
          type: null,
          channels: [],
          model: ""
        };
        this.getChannels();
      }
    }
  },
  methods: {
    remove(data) {
      this.newItem.channels = this.newItem.channels.filter(obj => {
        return obj.gid !== data.item.gid;
      });
    },
    close() {
      this.$emit("closeDialog");
    },
    async createSensor() {
      this.$apollo
        .mutate({
          mutation: CREATE_SENSOR,
          variables: {
            name: this.newItem.name,
            type_id: this.newItem.type,
            location_desc: this.newItem.location_desc,
            serial_number: this.newItem.serial_number,
            model: this.newItem.model
          }
        })
        .then(resp => {
          let sensorId = resp.data.insert_glnet_sensor_one.id;
          // console.log("CHANNELS", this.newItem.channels.map(it => it.gid))
          this.$apollo
            .mutate({
              mutation: APPEND_CHANNELS_TO_SENSOR,
              variables: {
                id: sensorId,
                channel_ids: this.newItem.channels.map(it => it.gid)
              }
            })
            .then(() => {
              this.$notify({
                group: "foo",
                text: "Успешно сохранено",
                type: "suc"
              });
              this.$emit("sensorRefetch");
              this.close();
            })
            .catch(() => {
              
              this.$notify({
                group: "foo",
                text: "Ошибка сохранения",
                type: "err"
              });
              this.close();
            });
        })
        .catch(() => {
          
          this.$notify({
            group: "foo",
            text: "Ошибка сохранения",
            type: "err"
          });
          this.close();
        });
    },
    getChannelText(channel) {
      return channel.name
        ? `${channel.name}: ${channel.gid}`
        : `Без названия: ${channel.gid}`;
    },
    getChannels() {
      this.isChannelsLoading = true;
      this.$apollo
        .query({ query: FREE_CHANNELS, fetchPolicy: 'no-cache', variables: { id: -1 } })
        .then(data => {
          this.freeChannels = data.data.glnet_channel;
          this.isChannelsLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.select-position {
  margin: auto;
}
.edit-scene {
  float: left;
  margin-right: 20px;
}
</style>
