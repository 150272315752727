var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter"},[_c('v-card',{staticClass:"pt-10 px-6 filter__card",attrs:{"height":"100%","width":"","elevation":"0"}},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"dateFromRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"От","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDateFrom),callback:function ($$v) {_vm.isDateFrom=$$v},expression:"isDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"ru"},on:{"click:date":function($event){return _vm.$refs.dateFromRef.save(_vm.dateFrom)}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"timeFromRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isTimeFrom),callback:function ($$v) {_vm.isTimeFrom=$$v},expression:"isTimeFrom"}},[_c('v-time-picker',{attrs:{"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.timeFromRef.save(_vm.timeFrom)}},model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"dateToRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"До","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDateTo),callback:function ($$v) {_vm.isDateTo=$$v},expression:"isDateTo"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"ru"},on:{"click:date":function($event){return _vm.$refs.dateToRef.save(_vm.dateTo)}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"timeToRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-clock","readonly":""},model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isTimeTo),callback:function ($$v) {_vm.isTimeTo=$$v},expression:"isTimeTo"}},[_c('v-time-picker',{attrs:{"format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.timeToRef.save(_vm.timeTo)}},model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}})],1)],1)],1),_c('v-btn',{staticClass:"float-left mt-5 px-7 py-3 filter__button",attrs:{"elevation":"0","outlined":"","color":"primary","disabled":_vm.dateFrom === null && _vm.dateTo === null},on:{"click":_vm.acceptFilter}},[_vm._v(" Применить ")]),_c('v-btn',{staticClass:"float-left mt-5 px-7 ml-5 py-3 filter__button",attrs:{"elevation":"0","outlined":"","disabled":!(_vm.dateFrom !== null || _vm.dateTo !== null || _vm.timeFrom !== null || _vm.timeTo !== null)},on:{"click":_vm.clearDateFilter}},[_vm._v(" Сбросить ")]),(_vm.userRole !== 'operator')?_c('v-btn',{staticClass:"filter__button btn-exp ml-6",attrs:{"elevation":"0","outlined":"","loading":_vm.loadingCreateReportLogs,"color":"primary"},on:{"click":_vm.exportData}},[_vm._v(" Экспорт ")]):_vm._e()],1)],1),_c('div',{staticClass:"wrapper"},[_c('v-data-table',{staticClass:"table__log",attrs:{"dense":"","item-key":"id","height":"100%","headers":_vm.headers,"items":_vm.logEvent,"loading":_vm.loadingLogs,"options":_vm.options,"server-items-length":_vm.totalLogs,"footer-props":{
        showFirstLastPage: true,
        'items-per-page-text': 'Количество строк',
        'items-per-page-options': [25, 50, 100]
      }},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":function($event){return _vm.updateItemsPerPage($event)},"update:page":function($event){return _vm.updatePage($event)},"update:sort-by":function($event){return _vm.updateSortBy($event)},"update:sort-desc":function($event){return _vm.updateSortDesc($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-10 pt-10"},[_c('h1',[_vm._v("Нет данных")])])]},proxy:true},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format('DD MMM YYYY HH:mm:ss'))+" ")]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cutDigitLogs")(item.description))+" ")]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }