<template>
  <v-container class="d-flex justify-space-between" fluid>
    <ChannelSelectorVue @acceptFilters="acceptFilters($event)" />
    <div class="inner">
      <v-row>
        <v-tabs filled class="d-flex justify-center my-5">
          <div class="d-flex tabs__inner">
            <v-tab  @click="isActivComponent = 'chart'">График</v-tab>
            <v-tab  @click="isActivComponent = 'table'">Таблица</v-tab>
          </div>
        </v-tabs>
      </v-row>
      <LineChartVue :filterData="filterData" v-if="isActivComponent == 'chart'"/>
      <ChartTableVue :filterData="filterData" v-if="isActivComponent == 'table'" />
    </div>
  </v-container>
</template>

<script>
import ChannelSelectorVue from './ChannelSelectorVue.vue'
import LineChartVue from './LineChartVue.vue'
import ChartTableVue from './ChartTableVue.vue'

export default {
  components: { LineChartVue, ChannelSelectorVue, ChartTableVue },
  data: () => ({
    filterData: {
      dateTimeFrom: null,
      dateTimeTo: null,
      selectedChannelId: null
    },
    isActivComponent: 'chart',
  }),

  watch: {
    isActivComponent (newVal) {
      this.$eventHub.$emit('change-active-tab', newVal)
    }
  },

  methods: {
    acceptFilters (e) {
      this.filterData = e
    }
  }
}
</script>

<style>
  .inner{
    position: absolute;
    right: 0;
    top:70px;
    height: calc(100% - 50px);
    bottom: 0;
    width: calc(100% - 460px);
    overflow: hidden;
  }
  .primary{
    color: #FFFFFF!important;
  }
</style>
