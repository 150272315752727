<template>
  <div>
    <div
      class="dot"
      @click="openCloseCard"
      :id="hotspot.inputData.scenesensorId"
      :style="hotspotDotStyle"
    ></div>
    <div
      :style="hotspotLabelStyle"
      class="dot-lable-background"
      :id="hotspot.inputData.scenesensorId + 'label'"
    >
      <p class="dot-label">{{ hotspot.inputData.sensor.name }} <span><v-icon small class="dot-label-icon">mdi-drag-vertical</v-icon></span></p>
    </div>
    <div
      class="card"
      :id="hotspot.inputData.scenesensorId + 'card'"
      :style="hotspotCardStyle"
    >
      <v-card width="300" elevation="1">
        <div class="card-drag-icon-wrap">
          <v-icon class="card-drag-icon">
            mdi-drag
          </v-icon>
        </div>
        <p v-if="hotspot.inputData.sensor.name" class="card-title">
          {{ hotspot.inputData.sensor.name }}
        </p>
        <v-card-text
          v-if="hotspot.inputData.sensor.glnet_sensortype"
          class="card-text"
          >{{ hotspot.inputData.sensor.glnet_sensortype.name }}</v-card-text
        >
        <v-card-text v-if="hotspot.inputData.sensor.model" class="card-text">{{
          hotspot.inputData.sensor.model
        }}</v-card-text>
        <div class="card-status-color" :style="getStatusColorBG"></div>
        <v-list disabled dense class="card-channel-list">
          <v-list-item-group>
            <v-list-item
              class="card-list-item pl-3"
              v-for="item in hotspot.inputData.sensorChannels"
              :key="item.id"
            >
              <v-list-item-icon>
                <v-icon :color="getStatusColor(item.status_id)">
                  mdi-circle-slice-8
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="getListItemTitle(item) + '' + getListItemValue(item)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <delete-sensor
          v-if="mode === 'edit'"
          @refreshScenes="refreshScenes"
          :sensor="hotspot.inputData.sensor"
          :sceneSensorId="hotspot.inputData.scenesensorId"
        ></delete-sensor>
      </v-card>
    </div>
    <svg
      width="3000"
      height="3000"
      class="svg"
      :id="hotspot.inputData.scenesensorId + 'svg'"
      :style="hotspotSVGStyle"
    >
      <line
        :x1="dotLeft + 15 + 1000"
        :y1="dotTop + 15 + 1000"
        :x2="cardLeft + 25 + 1000"
        :y2="cardTop + 25 + 1000"
        stroke="grey"
        stroke-width="1"
        stroke-opacity="1"
      />
    </svg>
  </div>
</template>
<script>
import DeleteSensor from "./DeleteSensor.vue";
let Draggable = require("draggable");
export default {
  name: "hotspotSensor",
  components: {
    DeleteSensor
  },
  props: {
    sceneWidth: null,
    sceneHeight: null,
    sceneId: null,
    hotspot: {
      type: Object
    },
    mode: {
      type: String,
      default: "view"
    }
  },
  data() {
    return {
      dotTop: 0,
      dotLeft: 0,
      cardTop: 0,
      cardLeft: 0,
      labelTop: 0,
      labelLeft: 0,
      isCardOpened: false
    };
  },
  computed: {
    hotspotDotStyle() {
      return {
        backgroundColor: this.getColorByStatusMix(this.hotspot.status),
        top: this.dotTop + "px",
        left: this.dotLeft + "px"
      };
    },
    hotspotSVGStyle() {
      let styles = {};
      if (this.mode === "edit") {
        styles.visibility = "visible";
        styles.opacity = "1";
      }
      return styles;
    },
    hotspotCardStyle() {
      let styles = {
        top: this.cardTop + "px",
        left: this.cardLeft + "px"
      };
      if (this.mode === "edit") {
        styles.visibility = "visible";
        styles.opacity = "1";
      }
      return styles;
    },
    hotspotLabelStyle() {
      let styles = {
        top: this.labelTop + "px",
        left: this.labelLeft + "px"
      };
      return styles;
    },
    getStatusColorBG() {
      return {
        backgroundColor: this.getColorByStatusMix(this.hotspot.status)
      };
    }
  },
  mounted() {
    this.mount();
  },
  methods: {
    
    openCloseCard() {
      if (this.mode !== "edit") {
        this.isCardOpened = !this.isCardOpened;
        localStorage[
          "cardVisible" + this.hotspot.inputData.scenesensorId
        ] = this.isCardOpened;
        this.changeVisibleCard();
      }
    },
    changeVisibleCard() {
      let elem = document.getElementById(
        String(this.hotspot.inputData.scenesensorId + "card")
      );
      let elemSvg = document.getElementById(
        String(this.hotspot.inputData.scenesensorId + "svg")
      );
      if (this.isCardOpened) {
        elem.style.visibility = "visible";
        elem.style.opacity = "1";
        setTimeout(() => {
          elemSvg.style.visibility = "visible";
          elemSvg.style.opacity = "1";
        }, 150);
      } else {
        elem.style.visibility = "hidden";
        elem.style.opacity = "0";
        elemSvg.style.visibility = "hidden";
        elemSvg.style.opacity = "0";
      }
    },
    getListItemTitle(item) {
      return `${item.name}\xa0\xa0\xa0\xa0`;
    },
    getListItemValue(item) {
      if (item.glnet_channelvalues[0].value) {
        return `    ${parseFloat(item.glnet_channelvalues[0].value).toFixed(
          3
        )} ${item.unit}`;
      } else {
        return ``;
      }
    },
    getStatusColor(status) {
      return this.getColorByStatusMix(status);
    },
    refreshScenes() {
      this.$emit("refreshScenes", this.sceneId);
    },
    mount() {
      this.dotTop = this.persentInPixels(this.hotspot.coordinates.top, "y");
      this.dotLeft = this.persentInPixels(this.hotspot.coordinates.left, "x");
      this.cardTop = localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneCardPositionTop"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.inputData.scenesensorId +
                "hotspotSceneCardPositionTop"
            ]
          )
        : Math.max(this.dotTop - 250, 0);
      this.cardLeft = localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneCardPositionLeft"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.inputData.scenesensorId +
                "hotspotSceneCardPositionLeft"
            ]
          )
        : Math.max(this.dotLeft - 250, 0);
      this.labelTop = localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneLabelPositionTop"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.inputData.scenesensorId +
                "hotspotSceneLabelPositionTop"
            ]
          )
        : Math.max(this.dotTop + 40, 0);
      if (Math.abs(this.labelTop - this.dotTop) > 50) {
        this.labelTop = this.dotTop + 50
      }
      this.labelLeft = localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneLabelPositionLeft"
      ]
        ? parseInt(
            localStorage[
              this.hotspot.inputData.scenesensorId +
                "hotspotSceneLabelPositionLeft"
            ]
          )
        : Math.max(this.dotLeft - 50, 0);
      if (Math.abs(this.labelLeft - this.dotLeft) > 130) {
        this.labelLeft = this.dotLeft - 130
      }
      if (this.mode === "edit") {
        this.setDraggableElem("");
      }
      this.setDraggableElem("card");
      this.setDraggableElem("label");
      if (this.mode !== "edit") {
        setTimeout(() => {
          this.isCardOpened =
            localStorage[
              "cardVisible" + this.hotspot.inputData.scenesensorId
            ] !== undefined
              ? JSON.parse(
                  localStorage[
                    "cardVisible" + this.hotspot.inputData.scenesensorId
                  ]
                )
              : false;
          this.changeVisibleCard();
        }, 500);
      }
    },
    setDraggableElem(type) {
      let elem = document.getElementById(
        String(this.hotspot.inputData.scenesensorId + type)
      );
      this.draggableElem = new Draggable(elem, {
        grid: 0,
        setCursor: true,
        limit:
          type == ""
            ? {
                x: [0, this.sceneWidth - 15],
                y: [0, this.sceneHeight - 15]
              }
            : type == "card"
            ? {
                x: [0, this.sceneWidth - 150],
                y: [0, this.sceneHeight - 150]
              }
            : {
                x: [this.dotLeft - 130, this.dotLeft + 50],
                y: [this.dotTop - 50, this.dotTop + 50]
              },
        onDrag:
          type == ""
            ? this.onDragDot
            : type == "card"
            ? this.onDragCard
            : this.onDragLabel,
        onDragEnd:
          type == ""
            ? this.onDragDotEnd
            : type == "card"
            ? this.onDragCardEnd
            : this.onDragLabelEnd,
        setPosition: true
      });
      let oldElem = document.getElementById(
        String(this.hotspot.inputData.scenesensorId + type)
      );
      let parent = elem.parentElement;
      oldElem.remove();
      setTimeout(() => {
        parent.appendChild(elem);
      }, 100);
    },
    onDragDot(elem, x, y) {
      this.dotTop = y;
      this.dotLeft = x;
    },
    onDragCard(elem, x, y) {
      this.cardTop = y;
      this.cardLeft = x;
    },
    onDragLabel(elem, x, y) {
      this.labelTop = y;
      this.labelLeft = x;
    },
    onDragDotEnd(elem, x, y) {
      this.dotTop = y;
      this.dotLeft = x;
      this.updateDotPosition();
      this.onDragLabelEnd(null, x-50, y+40)
    },
    onDragCardEnd(elem, x, y) {
      this.cardTop = y;
      this.cardLeft = x;
      localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneCardPositionTop"
      ] = y;
      localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneCardPositionLeft"
      ] = x;
    },
    onDragLabelEnd(elem, x, y) {
      this.labelTop = y;
      this.labelLeft = x;
      localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneLabelPositionTop"
      ] = y;
      localStorage[
        this.hotspot.inputData.scenesensorId + "hotspotSceneLabelPositionLeft"
      ] = x;
      this.setDraggableElem('label')
    },
    pixelsInPersent(pixels, axis) {
      // пиксели в %
      if (axis === "x") {
        return (pixels / this.sceneWidth) * 100;
      } else {
        return (pixels / this.sceneHeight) * 100;
      }
    },
    persentInPixels(persent, axis) {
      // % в px
      if (axis === "x") {
        return (this.sceneWidth / 100) * persent;
      } else {
        return (this.sceneHeight / 100) * persent;
      }
    },
    updateDotPosition() {
      /* eslint-disable no-unused-vars */
      this.$apollo
        .mutate({
          mutation: require("@/graphQL/update_scenesensor_coordinates.gql"),
          variables: {
            id: parseInt(this.hotspot.inputData.scenesensorId),
            coordinates: [
              this.pixelsInPersent(this.dotTop, "y"),
              this.pixelsInPersent(this.dotLeft, "x")
            ]
          }
        })
        .then(_ => {
          this.$notify({
            group: "foo",
            title: "Успешно",
            text: "Координаты датчика сохранены",
            type: "suc" // error warn success common
          });
        })
        .catch(error => {
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: "Координаты датчика не сохранены",
            type: "err" // error warn success common
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.dot {
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  border: 4px solid rgba(255, 255, 255, 0.4);
  z-index: 2;
  cursor: pointer;
}
.card {
  top: 50px;
  left: 50px;
  position: absolute;
  z-index: 7;
  text-align: left;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}
.card-title {
  width: calc(100% - 40px);
  padding: 5px 16px 5px 16px;
  margin: 0;
  font-size: 14pt;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-text {
  padding-top: 5px;
  padding-bottom: 0;
}
.card-status-color {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 4px 4px;
}
.card-drag-icon-wrap {
  position: absolute;
  border-radius: 4px;
  top: 7px;
  right: 7px;
  z-index: 2;
  background-color: rgba(255, 255, 255, $alpha: 0.8);
}
.dot-drag-icon {
  display: block;
  margin-top: 3px;
}
.dot-label {
  display: inline-block;
  width: auto;
  font-size: 14pt;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 1px 1px 0 rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 0 0px 0 10px;
  max-width: 140px;
}

.dot-lable-background {
  width: 140px;
  text-align: center;
  height: 20px;
  position: absolute;
  left: -50px;
  top: 30px;
}
svg {
  position: absolute;
  z-index: 1;
  top: -1000px;
  left: -1000px;
  pointer-events: none;
  visibility: hidden;
}
.card-channel-list {
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 0px 0px 4px 4px;
}
.card-list-item {
  padding-left: 0;
}
</style>
