<template>
  <div class="chart-main-wrap">
    <!-- <v-btn
      @click="downloadChart"
      v-if="chartPNGsrc !== null"
      class="mx-2 btn-print"
      elevation="0"
      dark
      fab
      color="primary"
      outlined
    >
      <v-icon color="primary" dark>
        mdi-download
      </v-icon>
    </v-btn> -->

    <div class="current-chart d-flex justify-center">
      <v-fade-transition>
        <div class="loading-chart" v-show="loadingChart">
          <v-progress-circular
            size="40"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-fade-transition>
      <div
        class="chart-wrapper"
        v-if="filterData.selectedChannelId !== null"
        :id="chartBlockId"
      ></div>
      <p v-else class="display-2 font-weight-light text--disabled pt-10">
        Выберите фильтры и нажмите "применить"
      </p>
    </div>
  </div>
</template>

<script>
const glnet_channelvalue_for_chart = require("@/graphQL/channelvalue_for_chart.gql");
const glnet_channel_limits = require("@/graphQL/channel_limits.gql");
const glnet_request_frequency = require("@/graphQL/request_frequency.gql");
import moment from "moment";
moment.locale("ru");

export default {
  name: "line-chart",
  props: {
    filterData: Object
  },
  data: () => ({
    chartBlockId: "chart_",
    chartValues: null,
    chartData: null,
    chart: null,
    chartPNGsrc: null,
    loadingChart: false
  }),

  watch: {
    filterData: {
      deep: true,
      async handler(newVal) {
        /* eslint-disable */
        google.charts.load("current", {
          packages: ["corechart"],
          language: "ru"
        });
        await this.getChannelValues(newVal);
        await this.getChannelLimits(newVal.selectedChannelId);
        google.charts.setOnLoadCallback(this.drawChart);
      }
    }
  },

  created() {
    this.$eventHub.$on('export-chart', this.downloadChart)
    this.chartBlockId = "chart_" + Math.floor(Math.random() * 999);
  },
  
  beforeDestroy () {
    this.$eventHub.$off('export-chart')
  },

  async mounted() {
    /* eslint-disable */
    google.charts.load("current", {
      packages: ["corechart"],
      language: "ru"
    });
    if (this.filterData.selectedChannelId !== null) {
      try {
        await this.getChannelValues(this.filterData);
        await this.getChannelLimits(this.filterData.selectedChannelId);
        google.charts.setOnLoadCallback(this.drawChart);
      } catch (error) {
        console.error("error", error);
      }
    }
  },

  methods: {
    async getChannelLimits(channelGid) {
      try {
        this.channelLimits = await this.$apollo.query({
          query: glnet_channel_limits,
          variables: {
            channel_id: channelGid
          }
        });
        this.channelLimits = this.channelLimits.data.glnet_channel[0];
        // 
        return this.channelLimits;
      } catch (error) {
        console.error("fail get channel limits", error);
        return {
          alarm_minus: null,
          alarm_plus: null,
          attention_minus: null,
          attention_plus: null
        };
      }
    },

    async getRequestFrequency() {
      try {
        let requestFrequency = await this.$apollo.query({
          query: glnet_request_frequency
        });
        let fr = requestFrequency.data.settings[0].value;
        return fr;
      } catch (error) {
        console.error("fail get request frequency", error);
        return 100;
      }
    },

    getDateTimeIntervals(dateTimeFrom, dateTimeTo, numberOfIntervals, fr) {
      let startDate = new Date(dateTimeFrom);
      let endDate = dateTimeTo == null ? new Date() : new Date(dateTimeTo);
      // 
      let diff = endDate.getTime() - startDate.getTime();
      let intervalLength = diff / (numberOfIntervals - 1);
      let intervals = {
        datetime_from1: new Date(startDate.getTime()).toISOString(),
        datetime_to1: new Date(startDate.getTime() + fr * 1000).toISOString()
      };
      for (let i = 1; i <= numberOfIntervals - 1; i++) {
        let endInterval = new Date(startDate.getTime() + i * intervalLength);
        let startInterval = new Date(
          startDate.getTime() + i * intervalLength - fr * 1000
        );
        intervals["datetime_from" + (i + 1)] = startInterval.toISOString();
        intervals["datetime_to" + (i + 1)] = endInterval.toISOString();
      }
      return intervals;
    },

    async getChannelValues(filterData) {
      // let fr = await this.getRequestFrequency();
      let fr = 300;
      this.loadingChart = true;
      let intervals = this.getDateTimeIntervals(
        filterData.dateTimeFrom,
        filterData.dateTimeTo,
        300,
        fr
      );
      let variables = {
        ...intervals,
        channel_id: filterData.selectedChannelId
      };
      // 
      try {
        this.chartValues = await this.$apollo.query({
          query: glnet_channelvalue_for_chart,
          variables: variables
        });
        this.chartValues = this.chartValues.data.glnet_channelvalue;
        // 
        this.loadingChart = false;
        return this.chartValues;
      } catch (error) {
        this.loadingChart = false;
        console.error("fail get channel values", error);
        return [];
      }
    },

    drawChart() {
      this.chartPNGsrc = null;
      var options = {
        title: this.filterData.selectedChannelId,
        legend: { position: "none" },
        chartArea: {
          width: "85%",
          height: "85%"
        },
        vAxis: {
          title: this.channelLimits.unit,
          titleTextStyle: {
            fontSize: 18,
            italic: false
          }
        },
        explorer: {
          axis: "horizontal",
          actions: ["dragToZoom", "rightClickToReset"],
          keepInBounds: true,
          interpolateNulls: false,
          maxZoomIn: 100.0
        },
        series: {
          0: {
            color: "green",
            lineWidth: 2,
            pointSize: 3
          },
          1: {
            color: "#ebc034"
          },
          2: {
            color: "#ebc034"
          },
          3: {
            color: "red"
          },
          4: {
            color: "red"
          }
        }
      };

      let _chartData = this.chartValues.map(item => {
        return [
          new Date(item.time), // дата
          item.value, // значение
          this.channelLimits.attention_plus, // Внимание +
          this.channelLimits.attention_minus, // Внимание -
          this.channelLimits.alarm_plus, // Тревога +
          this.channelLimits.alarm_minus // Тревога -
        ];
      });

      if (_chartData.length > 0) {
        _chartData.sort((a, b) => {
          // сортируем по возрастанию дат
          if (moment(a[0]).isAfter(moment(b[0]))) {
            return 1;
          }
          return -1;
        });
      }
      // for (let i = 0; i < _chartData.length - 1; i++) { // проверка на то как отсортированы даты
      //   let a = _chartData[i][0]
      //   let b = _chartData[i + 1][0]
      //   if (moment(a).isAfter(moment(b))) {
      //     console.log('WRONG ORDER', a, b)
      //   }
      // }

      this.chartData = google.visualization.arrayToDataTable(
        [
          [
            { label: "Дата", type: "datetime" },
            { label: "Значение", type: "number" },
            {
              label:
                "Внимание +" +
                "\n" +
                "(" +
                this.channelLimits.attention_plus +
                " " +
                this.channelLimits.unit +
                ")",
              type: "number"
            },
            {
              label:
                "Внимание -" +
                "\n" +
                "(" +
                this.channelLimits.attention_minus +
                " " +
                this.channelLimits.unit +
                ")",
              type: "number"
            },
            {
              label:
                "Тревога +" +
                "\n" +
                "(" +
                this.channelLimits.alarm_plus +
                " " +
                this.channelLimits.unit +
                ")",
              type: "number"
            },
            {
              label:
                "Тревога -" +
                "\n" +
                "(" +
                this.channelLimits.alarm_minus +
                " " +
                this.channelLimits.unit +
                ")",
              type: "number"
            }
          ],
          ..._chartData
        ],
        false
      );
      this.chart = new google.visualization.LineChart(
        document.getElementById(this.chartBlockId)
      );
      self = this;
      google.visualization.events.addListener(this.chart, "ready", function() {
        self.chartPNGsrc = self.chart.getImageURI();
      });
      this.chart.draw(this.chartData, options);
    },
    downloadChart() {
      const link = document.createElement("a");
      link.setAttribute("href", this.chartPNGsrc);
      link.setAttribute(
        "download",
        "chart" + moment().format("DD-MM-YYYY_hh:mm") + ".png"
      );
      link.click();
    }
  }
};
</script>

<style scoped>
.current-chart {
  width: 100%;
  height: calc(100vh - 200px);
}
.chart-wrapper {
  width: 100%;
  height: 100%;
}
.chart-main-wrap {
  position: relative;
}
.btn-print {
  position: absolute;
  right: 100px;
  top: 10px;
  z-index: 2 !important;
}
.btn-print img {
  box-sizing: inherit;
  object-fit: cover;
}
.loading-chart {
  width: 90%;
  height: calc(100vh - 200px);
  background-color: rgba(0, 0, 0, 0.247);
  position: absolute;
  z-index: 555;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
</style>
